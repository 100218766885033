(<template>
  <div class="invoice-info">
    <template v-if="invoiceLinesData.length">
      <p v-if="title"
         class="invoice-info__title"
         v-html="title"></p>
      <div :class="{'invoice-info__lines--chat-event': hideTips}"
           class="invoice-info__lines">
        <slot></slot>
        <div v-for="(line, index) in invoiceLines"
             :key="index"
             :class="line.classList"
             class="invoice-info__line">
          <p v-if="line.groupTitle">{{ line.groupTitle }}</p>
          <template v-else>
            <sk-tooltip v-if="!hideTips"
                        :item-text="getLineText(line.type).tooltip"
                        class="invoice-info__tooltip" />
            <p v-if="line.description"
               class="invoice-info__line-description"
               v-html="getLineText(line.type, line.description).description"></p>
            <p v-else
               class="invoice-info__line-description"
               v-html="getLineText(line.type).name"></p>
            <div class="invoice-info__price-cont">
              <p v-if="isQuantity(line)"
                 class="invoice-info__line-quantity"
                 v-html="getQuantityText(line)"></p>
              <p class="invoice-info__line-amount">{{ genPriceText(line.amount, line.units ? line.units : 'NOK') }}</p>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>)

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      invoice: {
        type: Object,
        default() {
          return {};
        }
      },
      travelCosts: {
        type: Object,
        default() {
          return {};
        }
      },
      hideTotal: Boolean,
      hideTips: Boolean,
      hideGroups: Boolean
    },
    computed: {
      travelCostsData() { return this.travelCosts || {}; },
      invoiceData() { return this.invoice || {}; },
      invoiceLinesData() { return this.invoiceData.lines || []; },
      totalPrice() { return this.invoiceData.amount; },
      bonusLines() { return this.getFilteredLines('bonus'); },
      jobLines() { return this.getFilteredLines('work'); },
      travelLines() { return this.getFilteredLines('travel'); },
      invoiceLines() {
        return [
          ...this.jobLines,
          ...this.bonusGroupTitle,
          ...this.bonusLines,
          ...this.travelGroupTitle,
          ...this.travelLines,
          ...this.totalPriceLine,
          ...this.ratesGroupTitle,
          ...this.travelRatesLines
        ].filter((line) => Number(line.amount) || line.groupTitle);
      },
      // Groups titles
      bonusGroupTitle() {
        return !this.hideGroups && this.bonusLines.length ? [{
          groupTitle: this.$gettext('Bonuses'),
          classList: 'invoice-info__line--group-title'
        }] : [];
      },
      travelGroupTitle() {
        return !this.hideGroups && this.travelLines.length ? [{
          groupTitle: this.$gettext('Travel costs'),
          classList: 'invoice-info__line--group-title'
        }] : [];
      },
      ratesGroupTitle() {
        return !this.hideGroups && this.travelRatesLines.length ? [{
          groupTitle: this.$gettext('Rates information'),
          classList: 'invoice-info__line--group-title invoice-info__line--group-title-rates'
        }] : [];
      },
      // Travel rates lines
      travelRatesLines() {
        const travelLines = [];
        if (this.travelCostsData.travelRatePerHour) {
          travelLines.push({
            type: 'travelRatePerHour',
            amount: this.travelCostsData.travelRatePerHour
          });
        }
        if (this.travelCostsData.travelDuration) {
          travelLines.push({
            type: 'travelDuration',
            amount: this.travelCostsData.travelDuration,
            units: 'hr(s)'
          });
        }
        if (this.travelCostsData.travelRatePerKm) {
          travelLines.push({
            type: 'travelRatePerKm',
            amount: this.travelCostsData.travelRatePerKm,
            classList: 'invoice-info__line--divider-top'
          });
        }
        if (this.travelCostsData.travelDistance) {
          travelLines.push({
            type: 'travelDistance',
            amount: this.travelCostsData.travelDistance,
            units: 'KM'
          });
        }
        return travelLines;
      },
      // Total price line
      totalPriceLine() {
        const totalLine = [];
        if (!this.hideTotal) {
          totalLine.push({
            type: 'total',
            amount: this.totalPrice,
            classList: 'invoice-info__line--total'
          });
        }
        return totalLine;
      }
    },
    methods: {
      genPriceText(total, units) { return this.$gettextInterpolate('%{total} %{units}', {total, units}); },
      getFilteredLines(filter) {
        return this.invoiceLinesData.filter((line) => line.group == filter && Number(line.amount));
      },
      isQuantity(line) {
        return line.unit && line.quantity && line.quantity > 0;
      },
      getQuantityText(line) {
        return `<span>${line.quantity}</span> <span>${this.getUnitText(line.unit)}</span>`;
      },
      getUnitText(unit) {
        switch (unit) {
          case 'hour':
            return this.$gettext('hour(s)');
          case 'km':
            return this.$gettext('km');
        }
      },
      getLineText(type, description) {
        switch (type) {
          case 'short_notice_fee':
            return {
              name: this.$gettext('Short notice fee'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that are booked and conducted in less than a couple of days. This amount is set aside for you.'),
              description: description
            };
          case 'short_notice_platform_fee':
            return {
              name: this.$gettext('Short notice platform fee'),
              tooltip: type,
              description: description
            };
          case 'on_demand_fee':
            return {
              name: this.$gettext('On demand fee'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that must start within a short amount of time. This amount is set aside for you.'),
              description: description
            };
          case 'interpretation_fee':
            return {
              name: this.$gettext('Interpretation fee'),
              tooltip: this.$gettext('This is the amount paid to you for your service as an interpreter.'),
              description: description
            };
          case 'overtime_fee':
            return {
              name: this.$gettext('Overtime fee'),
              tooltip: this.$gettext('This is the additional amount paid to you for assignments that are longer than originally booked.'),
              description: description
            };
          case 'cancellation_fee':
            return {
              name: this.$gettext('Cancellation fee'),
              tooltip: this.$gettext('This is the additional amount paid to you for assignments that are longer than originally booked.'),
              description: description
            };
          // TravelCosts pricing
          case 'travel_driving':
            return {
              name: this.$gettext('Travel driving'),
              tooltip: this.$gettext('You will receive 0.53kr per km for driving to your sessions. This amount is taxable accounting to state regulations.'),
              description: description
            };
          case 'travel_driving_refund':
            return {
              name: this.$gettext('Travel driving refund'),
              tooltip: this.$gettext('You will receive 3.5kr per km for driving to your sessions. This amount is not taxable accounting to state regulations.'),
              description: description
            };
          case 'travel_diet':
            return {
              name: this.$gettext('Travel diet'),
              tooltip: this.$gettext('You will receive a set amount of money for your dietary needs. This amount is taxable.'),
              description: description
            };
          case 'travel_diet_refund':
            return {
              name: this.$gettext('Travel diet refund'),
              tooltip: this.$gettext('You will receive a set amount of money for your dietary needs. This amount is not taxable.'),
              description: description
            };
          case 'travel_expenses_estimate':
            return {
              name: this.$gettext('Travel expenses (estimate)'),
              tooltip: this.$gettext('Based on your input, this is the amount that will be refunded for your travel costs. You can edit this in your feedback after the assignment. Please keep all receipts. Note that not all expenses will be refunded. Please review our travel guidelines to understand what can be expensed.'),
              description: description
            };
          case 'travel_expenses':
            return {
              name: this.$gettext('Travel expenses'),
              tooltip: this.$gettext('Based on your input, this is the amount that will be refunded for your travel costs. Note that not all expenses will be refunded. Please review our travel guidelines to understand what can be expensed.'),
              description: description
            };
          case 'travel_flat_amount':
            return {
              name: this.$gettext('Travel flat amount'),
              tooltip: this.$gettext('You will receive this set amount of money for traveling to and from the assignment.'),
              description: description
            };
          case 'travel_platform_fee':
            return {
              name: this.$gettext('Travel platform fee'),
              tooltip: type,
              description: description
            };
          case 'travel_duration_platform_fee':
            return {
              name: this.$gettext('Travel duration platform fee'),
              tooltip: type,
              description: description
            };
          // TravelCosts rates
          case 'travelRatePerHour':
            return {
              name: this.$gettext('Travel rate per hour'),
              tooltip: this.$gettext('This is the rate used to calculate the amount paid for your travel time'),
              description: description
            };
          case 'travelDuration':
          case 'travel_duration':
            return {
              name: this.$gettext('Travel time to and from'),
              tooltip: this.$gettext('You will receive this set amount of money for traveling to and from the assignment.'),
              description: description
            };
          case 'travelRatePerKm':
            return {
              name: this.$gettext('Driving rate per km'),
              tooltip: this.$gettext('This is the rate used to calculate the amount paid for your travel distance'),
              description: description
            };
          case 'travelDistance':
            return {
              name: this.$gettext('Distance to and from'),
              tooltip: this.$gettext('This is the set travel distance associated with this assignment. This cannot be changed. Please do not take this assignment if this is not acceptable.'),
              description: description
            };
          // Bonuses
          case 'evening_bonus':
            return {
              name: this.$gettext('Evening bonus'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that are conducted in the evening. This amount is set aside for you.'),
              description: description
            };
          case 'evening_bonus_flat_amount':
            return {
              name: this.$gettext('Evening bonus flat amount'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that are conducted during the weekends/holidays. This amount is set aside for you.'),
              description: description
            };
          case 'weekend_bonus':
            return {
              name: this.$gettext('Weekend bonus'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that are conducted during the weekends/holidays. This amount is set aside for you.'),
              description: description
            };
          case 'weekend_bonus_flat_amount':
            return {
              name: this.$gettext('Weekend bonus flat amount'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that are conducted during the weekends/holidays. This amount is set aside for you.'),
              description: description
            };
          case 'night_bonus':
            return {
              name: this.$gettext('Night bonus'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that are conducted in the night. This amount is set aside for you.'),
              description: description
            };
          case 'night_bonus_flat_amount':
            return {
              name: this.$gettext('Night bonus flat amount'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that are conducted in the night. This amount is set aside for you.'),
              description: description
            };
          case 'holiday_bonus':
            return {
              name: this.$gettext('Holiday bonus'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that are conducted during the weekends/holidays. This amount is set aside for you.'),
              description: description
            };
          case 'holiday_bonus_flat_amount':
            return {
              name: this.$gettext('Holiday bonus flat amount'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that are conducted during the weekends/holidays. This amount is set aside for you.'),
              description: description
            };
          // Demander only
          case 'manual_booking_fee':
            return {
              name: this.$gettext('Manual booking fee'),
              tooltip: this.$gettext('This fee is paid to us for registering or acting on bookings on your behalf.'),
              description: description
            };
          case 'booking_fee':
            return {
              name: this.$gettext('Booking fee'),
              tooltip: this.$gettext('This fee is paid to us for the use of our platform.'),
              description: description
            };
          case 'short_notice_fee_flat_amount':
            return {
              name: this.$gettext('Short notice fee flat amount'),
              tooltip: this.$gettext('Some customers pay an additional amount of money for assignments that are booked and conducted in less than a couple of days. This amount is set aside for you.'),
              description: description
            };
          case 'short_notice_booking_fee':
            return {
              name: this.$gettext('Short notice booking fee'),
              tooltip: this.$gettext('This fee is paid to us for providing an interpreter in a short period of time.'),
              description: description
            };
          case 'on_demand_booking_fee':
            return {
              name: this.$gettext('On demand booking fee'),
              tooltip: this.$gettext('This fee is paid to us for providing an interpreter immediately.'),
              description: description
            };
          case 'service_fee':
            return {
              name: this.$gettext('Service fee'),
              tooltip: this.$gettext('This fee is paid to us for our administrative services.'),
              description: description
            };
          case 'platform_fee':
            return {
              name: this.$gettext('Platform fee'),
              tooltip: type,
              description: description
            };
          case 'support_fee':
            return {
              name: this.$gettext('Support fee'),
              tooltip: type,
              description: description
            };
          case 'direct_booking_fee':
            return {
              name: this.$gettext('Direct booking fee'),
              tooltip: type,
              description: description
            };
          case 'gender_fee':
            return {
              name: this.$gettext('Gender fee'),
              tooltip: type,
              description: description
            };
          case 'qualification_fee':
            return {
              name: this.$gettext('Qualification fee'),
              tooltip: type,
              description: description
            };
          case 'service_fee_overtime':
            return {
              name: this.$gettext('Service fee overtime'),
              tooltip: this.$gettext('This fee is paid to us for our administrative services'),
              description: description
            };
          case 'service_fee_travel':
            return {
              name: this.$gettext('Service fee travel'),
              tooltip: this.$gettext('This fee is paid to us for our administrative services'),
              description: description
            };
          case 'correction':
            return {
              name: this.$gettext('Correction'),
              tooltip: type,
              description: description
            };
          // Total
          case 'total':
            return {
              name: this.$gettext('Total'),
              tooltip: this.$gettext('Total amount excl. VAT. If you are VAT-registered, then you should follow the <a class="tooltip-link" href="https://info.altinn.no/en/start-and-run-business/direct-and-indirect-taxes/indirect-taxes/exemptions-from-the-vat-obligation" target="_blank">exemptions from the VAT obligation described here</a>')
            };
          default:
            return {
              name: type,
              tooltip: type,
              description: type
            };
        }
      }
    }
  };
</script>

<style>
  .tooltip-link {
    text-decoration: underline;
  }

  .invoice-info__line .rest-description {
    word-break: break-word;
  }
</style>

<style scoped>
  .invoice-info {
    display: block;
  }

  .invoice-info__title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  .invoice-info__lines {
    position: relative;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .invoice-info__line {
    position: relative;
    display: flex;
    align-items: center;
    padding: 7px 0;
  }

  .invoice-info__line--group-title {
    padding: 12px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
  }

  .invoice-info__line--group-title-rates {
    padding-top: 30px;
    font-size: 18px;
  }

  .invoice-info__line-description {
    padding-right: 30px;
  }

  .invoice-info__price-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 38%;
    margin-left: auto;
  }

  .invoice-info__lines--chat-event .invoice-info__price-cont {
    width: auto;
  }

  .invoice-info__line-quantity {
    padding-right: 10px;
    white-space: nowrap;
  }

  .invoice-info__line-amount {
    margin-left: auto;
    white-space: nowrap;
  }

  .invoice-info__line--total.invoice-info__line--total {
    padding: 12px 0;
    border-top: 1px solid #ccc;
    font-weight: bold;
  }

  .invoice-info__line--divider-top.invoice-info__line--divider-top {
    border-top: 1px solid #ccc;
  }

  .invoice-info__tooltip {
    z-index: 1;
    flex-shrink: 0;
    align-self: flex-start;
    width: 15px;
    height: 15px;
    margin-top: 3px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 15px;
  }

  .invoice-info__tooltip::before {
    content: '?';
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    text-align: center;
  }

  @media (max-width: 767px) {
    .invoice-info__line-description {
      display: flex;
      flex-direction: column;
      padding-right: 15px;
      text-align: left;
    }

    .invoice-info__line,
    .invoice-info__line-amount {
      font-size: 12px;
    }
  }

  @media (max-width: 550px) {
    .invoice-info__price-cont {
      width: auto;
    }

    .invoice-info__line-quantity {
      margin-right: 20px;
    }

    .invoice-info__lines--chat-event .invoice-info__line-quantity {
      margin-right: auto;
      padding-right: 5px;
    }

    .invoice-info__lines--chat-event .invoice-info__line,
    .invoice-info__lines--chat-event .invoice-info__line-amount {
      font-size: 10px;
    }
  }
</style>
