(<template>
  <div class="post-form__section real-name-section">
    <div class="post-form__field post-form__field--half-width">{{ $gettext('You are using a shared account. Please provide your real name') }}</div>
    <sk-input :description="ownerRealNameText"
              :placeholder="ownerRealNameText"
              :preselected-value="ownerRealName"
              :validation-name="ownerRealNameErrorName"
              :reg-exp="/<\/?[^>]+(>|$)/g"
              class="post-form__field post-form__field--half-width real-name-section__field"
              @fieldvaluechanged="setOwnerRealName" />
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    computed: {
      ...mapState('PostingStore/OwnerRealNameStore', {
        ownerRealName: (state) => state.ownerRealName || '',
        ownerRealNameErrorName: (state) => state.errors.ownerRealName.name
      }),
      ownerRealNameText() { return this.$gettext('Real name'); }
    },
    methods: {
      ...mapMutations('PostingStore/OwnerRealNameStore', [
        'setOwnerRealName'
      ])
    }
  };
</script>

<style scoped>
  .real-name-section {
    align-items: center;
  }

  .real-name-section__field {
    flex-grow: 1;
  }
</style>
