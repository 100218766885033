(<template>
  <div class="feedback">
    <div class="feedback__title-wrapper">
      <h2 class="feedback__title">{{ feedbackText }}</h2>
    </div>
    <feedback-section :title="title"
                      :icon="'personal-feedback-interpreter-side'"
                      :button-description="lastUpdatedText"
                      :progress-active="progressActive"
                      class="assignment-section"
                      @save="save">
      <sk-textarea :description="messageToTranslatorText"
                   :placeholder="feedbackHintText"
                   :preselected-value="publicFeedbackComment"
                   :reg-exp="/<\/?[^>]+(>|$)/g"
                   class="feedback__field project-feedback__textarea"
                   @fieldvaluechanged="setPublicFeedbackComment" />
    </feedback-section>
  </div>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';

  export default {
    components: {
      'feedback-section': FeedbackSection
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
        'userIsInterpreter',
      ]),
      ...mapState('FeedbackStore', {
        publicFeedbackComment: ({feedback}) => feedback.publicFeedback.comment || ''
      }),
      successModalTitle() {
        return this.$pgettext('translation', 'Thank you for your feedback!');
      },
      successModalParagraphs() {
        return [
          this.$pgettext('translation', 'Your feedback has been saved.'),
          this.$pgettext('translation', 'You can edit your feedback and save it again.'),
          '<br>',
          this.$pgettext('translation', 'Your feedback will be locked once the assignment is sent for invoicing to our finance department.'),
          '<br>'
        ];
      },
      title() {
        const template = this.$pgettext('translation', 'Feedback to %{domain}:');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      messageToTranslatorText() {
        const template = this.$pgettext('translation', 'Message to %{domain}:');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      feedbackText() {
        return this.$pgettext('translation', 'Feedback');
      },
      feedbackHintText() {
        return this.$pgettext('translation', 'Write here...');
      },
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedSectionData
        });
      },
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setPublicFeedbackComment'
      ]),
      reloadPage() {
        window.location.reload();
      },
      genFeedbackSentMessage() {
        const paragraphs = this.successModalParagraphs.map((paragraph) => `<p>${paragraph}</p>`);
        return paragraphs.join('');
      },
      save() {
        this.progressActive = true;
        this.$store.commit('PostTranslationProjectStore/setTrackType', 'order_updated');
        this.$store.dispatch('PostTranslationProjectStore/editProjectPartially', {
          demanderFeedback: this.publicFeedbackComment
        })
          .then(() => {
            this.progressActive = false;
            this.$store.commit('ModalStore/setModal', {
              component: 'message-modal',
              width: 650,
              classes: ['sk-modal--new'],
              data: {
                title: this.$pgettext('translation', 'Thank you for your feedback!'),
                text: this.genFeedbackSentMessage(),
                context: this,
                showActions: false,
              }
            });
          })
          .catch(() => {
            this.progressActive = false;
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal'
            });
          });
      }
    },
  };
</script>

<style>
.feedback__field {
  margin-bottom: 20px;
}

.feedback__radios-wrapper--full-width .feedback__radio-title {
  width: 100%;
  margin-bottom: 10px;
  padding-right: 0;
}

.feedback__radios-wrapper--full-width .feedback__radio {
  margin-right: 15px;
  margin-left: 0;
}

.project-feedback__textarea > .sk-input__input-wrapper {
  height: 80px;
}

.sk-info-modal__text h3 {
  padding-bottom: 30px;
}

.sk-info-modal__text p {
  margin-bottom: 20px;
  font-size: 14px;
  text-align: left;
}
</style>

<style scoped>

.feedback {
  width: 100%;
}

.feedback__title-wrapper {
  padding: 7px 30px;
  background-color: #2b255c;
  color: #fff;
}

.feedback__title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
}

</style>
