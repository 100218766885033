(<template>
  <form class="skill-form"
        @submit.stop.prevent="validateForm">
    <h3 class="skill-type__title">{{ skillTypeTitle }}</h3>
    <template v-if="!isTranslationSkill">
      <sk-select v-if="langFromOpened || isExistingSkill"
                 :items-list="fromLangsList"
                 :description="langFromSelectDescr"
                 :default-value="langFromSelectPlace"
                 :preselected-value="langFrom"
                 :removed-item="langTo"
                 :validation-name="langFromErrorsName"
                 :disabled="isExistingSkill"
                 class="skill-form__lang-select"
                 @csvaluechanged="catchLangFrom" />
      <div v-if="!langFromOpened && !isExistingSkill"
           class="skill-form__lang-from-wrapper">
        <p class="skill-form__lang-from-label">{{ $gettext('Language from:') }} <b>{{ langFromName }}</b></p>
        <button class="sk-link skill-form__lang-from-toggle-btn"
                type="button"
                @click="langFromOpened = true">{{ $gettext('Choose another language') }}</button>
      </div>
    </template>
    <sk-select :items-list="toLangsList"
               :description="langToSelectDescr"
               :default-value="langToSelectPlace"
               :preselected-value="langTo"
               :removed-item="langFrom"
               :validation-name="langToErrorsName"
               :disabled="isExistingSkill"
               class="skill-form__lang-select skill-form__lang-select--lang-to"
               @csvaluechanged="setLangTo" />
    <template v-if="isTranslationSkill">
      <sk-select :items-list="fromLangsList"
                 :description="langFromSelectDescr"
                 :default-value="langFromSelectPlace"
                 :preselected-value="langFrom"
                 :removed-item="langTo"
                 :validation-name="langFromErrorsName"
                 :disabled="isExistingSkill"
                 class="skill-form__lang-select skill-form__lang-select--lang-to"
                 @csvaluechanged="catchLangFrom" />
    </template>
    <sk-select v-if="requiredDialect && !isTranslationSkill"
               :items-list="countriesList"
               :description="dialectToText"
               :default-value="langToSelectPlace"
               :preselected-value="dialectTo"
               :validation-name="dialectToErrorsName"
               :disabled="isExistingSkill"
               class="skill-form__lang-select skill-form__lang-select--lang-to"
               @csvaluechanged="setDialectTo" />
    <sk-input v-else-if="!isTranslationSkill"
              :description="dialectToText"
              :placeholder="dialectToText"
              :preselected-value="dialectTo"
              :disabled="isExistingSkill"
              class="skill-form__dialect-field"
              @fieldvaluechanged="setDialectTo" />
    <sk-select :type="qualifSelectType"
               :items-list="qualifSelectItems"
               :description="qualifSelectDescr"
               :default-value="qualifSelectPlace"
               :disabled="isExistingSkill"
               :preselected-value="qualification"
               :validation-name="qualificationErrorsName"
               class="skill-form__qualif-select"
               @csvaluechanged="catchQualification" />
    <info-block v-if="!isTranslationSkill"
                :accordeon-title="accordeonTitle">
      <p class="skill-form__info-acc-first-parahraph">{{ $gettext('The qualification levels 1-5 apply to interpreters registered in the National Interpretation Register, Interpreting Portal. Category 6-8 are internal qualification levels used by current platform.') }}</p>
      <div class="skill-form__info-acc-list-wrapper">
        <p class="skill-form__info-acc-list-item">{{ $gettext('Category A: The most highly qualified interpreters with both a bachelor\'s degree in interpreting in the public sector (180 credits) and state authorization. Documented with Interpreter ID.') }}</p>
        <p class="skill-form__info-acc-list-item">{{ $gettext('Category B: Very highly qualified interpreters with bachelor\'s or state authorization and basic subject (30 credits interpreting in the public sector). Documented with Interpreter ID.') }}</p>
        <p class="skill-form__info-acc-list-item">{{ $gettext('Category C: Highly qualified interpreters with state authorisation. Documented with Interpreter ID') }}</p>
        <p class="skill-form__info-acc-list-item">{{ $gettext('Category D: Well-qualified interpreters with a basic subject (30 credits interpreting in the public sector). Documented with Interpreter ID') }}</p>
        <p class="skill-form__info-acc-list-item">{{ $gettext('Category E: Qualified interpreters with courses (TAO: introductory course in the interpreter\'s area of responsibility). Documented with Interpreter ID') }}</p>
        <p class="skill-form__info-acc-list-item">{{ $gettext('Not listed in the National Register of Interpreters: Documented with a CV, course certificate and similar') }}</p>
      </div>
      <p class="skill-form__info-acc-last-parahraph">{{ $gettext('If you are not registered in the National Interpretation Register, the Interpreting Portal, but have other relevant education and / or experience, you can upload documentation on it below.') }}</p>
    </info-block>
    <hr class="skill-form__divider" />
    <template v-if="!approvedSkill">
      <sk-input v-if="requiredTolkId"
                :description="tolkIdText"
                :placeholder="tolkIdText"
                :preselected-value="tolkId"
                :validation-name="tolkIdErrorsName"
                :reg-exp="/[^0-9]/g"
                :max-length="tolkIdLimit"
                class="skill-form__tolk-id"
                @fieldvaluechanged="setTolkId" />
      <div v-if="!requiredTolkId"
           class="skill-form__certifs-wrapper">
        <h3 class="skill-form__certifs-title">{{ $gettext('Documentation') }}</h3>
        <p class="skill-form__certifs-note">{{ $gettext('Upload relevant documentation such as CV, certificates from relevant courses, relevant diplomas / transcripts, etc., and possibly a cover letter.') }}</p>
        <sk-input :preselected-value="newCertifTitle"
                  :description="newCertifTitleText"
                  :placeholder="newCertifTitleText"
                  :reg-exp="/<\/?[^>]+(>|$)/g"
                  class="skill-form__certif-title-field"
                  @fieldvaluechanged="setNewCertifTitle" />
        <div ref="popoverReference"
             class="skill-form__upload-certif-cont">
          <file-selector :is-single-select="true"
                         :is-open="isPopoverVisible"
                         :popover-options="popoverOptions"
                         @attachnewfiles="catchNewFileAttachment"
                         @attachexistingfiles="catchExistingFileAttachment"
                         @close="closePopover">
            <button slot="trigger"
                    v-if="!attachmentsCount"
                    class="sk-btn sk-btn--default skill-form__upload-certif-btn"
                    @click.prevent.stop="openPopover">{{ uploadFileText }}</button>
          </file-selector>
          <attachments-section :is-single="true"
                               :hide-btn="true"
                               class="skill-form__upload-certif-btn" />
          <div v-if="uploadErrors.length"
               class="sk-input__errors-cont">
            <span v-for="(error, index) in uploadErrors"
                  :key="index"
                  v-html="(uploadErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
          </div>
        </div>
      </div>
    </template>
    <slot></slot>
  </form>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import InfoAccordeonBlock from '@/components/shared_components/infoAccordeonBlock';
  import AttachmentsSection from '@/components/booking_components/parts/attachments_section/AttachmentsSection';
  import FileSelector from '@/components/shared_components/FileSelector';

  export default {
    components: {
      'info-block': InfoAccordeonBlock,
      'attachments-section': AttachmentsSection,
      'file-selector': FileSelector
    },
    props: {
      skillType: {
        type: String,
        default: 'interpretation'
      }
    },
    data() {
      return {
        isInTolkeregister: false,
        langFromOpened: false,
        fileInputReset: false,
        requiredTolkId: false,
        tolkIdErrorsName: 'profileTolkId',
        tolkIdNameErrors: [],
        isPopoverVisible: false,
        isSingleSelect: true,
        popoverOptions: {
          popoverReference: null,
          placement: 'top-start'
        },
      };
    },
    computed: {
      ...mapState('EditProfileStore', {
        tolkId: (state) => state.profileData.tolkId || '',
      }),
      ...mapGetters('UserInfoStore', ['userIsPayrollInterpreter']),
      ...mapState('TTManageSupplierSkill', {
        skillData: ({skillData}) => skillData || {},
        newCertifTitle: ({newCertifTitle}) => newCertifTitle || '',
        newTolkId: (state) => state.tolkId,
        // errors
        langFromErrors: ({errors}) => errors.langFrom.errors,
        langFromErrorsName: ({errors}) => errors.langFrom.name,
        langToErrors: ({errors}) => errors.langTo.errors,
        langToErrorsName: ({errors}) => errors.langTo.name,
        dialectToErrors: ({errors}) => errors.dialectTo.errors,
        dialectToErrorsName: ({errors}) => errors.dialectTo.name,
        qualificationErrors: ({errors}) => errors.qualification.errors,
        qualificationErrorsName: ({errors}) => errors.qualification.name,
        uploadErrors: ({errors}) => errors.upload.errors,
        uploadErrorsName: ({errors}) => errors.upload.name,
      }),
      ...mapGetters('PostingStore/AttachmentsStore', ['attachmentsCount']),
      tolkIdLimit() { return 4; },
      skillId() { return this.skillData.id; },
      approvedSkill() { return this.skillData.approved; },
      langFrom() { return this.skillData.langFrom; },
      langTo() { return this.skillData.langTo; },
      dialectTo() { return this.skillData.dialectTo; },
      qualification() { return this.skillData.qualification; },
      isExistingSkill() { return !!this.skillId; },
      langFromName() {
        return this.langsList.find((item) => item.id == this.langFrom)?.name;
      },
      restrictedQualifsList() {
        const defaultSourceLang = this.langsList.find((item) => item.default_source)?.id;
        if (this.langFrom != defaultSourceLang && this.langTo != defaultSourceLang) {
          return this.interpretationQualifications.filter((item) => !item.norwegian_only) || [];
        }
        return [];
      },
      qualifSelectType() {
        return this.restrictedQualifsList.length ? undefined : 'qualification';
      },
      qualifSelectItems() {
        if (this.isTranslationSkill) return this.translationQualifications;
        else return this.restrictedQualifsList.length ? this.restrictedQualifsList : undefined;
      },
      qualifsList() { return this.$store.state.selectsData.qualification; },
      interpretationQualifications() { return this.$store.getters.interpretationQualifications; },
      translationQualifications() { return this.$store.getters.translationQualifications; },
      langsList() { return this.$store.state.selectsData.language || []; },
      fromLangsList() { return this.langsList.filter((lang) => lang.id != this.langTo); },
      toLangsList() { return this.langsList.filter((lang) => lang.id != this.langFrom); },
      langToData() { return this.langsList.find((lang) => lang.id === +this.langTo); },
      requiredDialect() { return this.langToData?.validate_interpreter_skill_dialect; },
      countriesList() { return this.langToData?.dialects.map((countryName) => ({id: countryName, name: countryName})); },
      langFromSelectDescr() { return this.$gettext('Language from:'); },
      langFromSelectPlace() { return this.$gettext('Select'); },
      langToSelectDescr() { return this.$gettext('Language to:'); },
      langToSelectPlace() { return this.$gettext('Select'); },
      dialectToText() { return this.$gettext('Dialect'); },
      tolkIdText() { return this.$gettext('Enter your Tolk-ID'); },
      qualifSelectDescr() { return this.$gettext('Qualification'); },
      qualifSelectPlace() { return this.$gettext('Select the qualification level'); },
      accordeonTitle() { return this.$gettext('About the qualification levels'); },
      newCertifTitleText() { return this.$gettext('Certification title'); },
      uploadFileText() { return this.$gettext('Upload file'); },
      isInternalQualification() {
        return !this.qualifsList?.find((qualification) => qualification.id == this.qualification)?.isInTolkeregister;
      },
      isTranslationSkill() { return this.skillType == 'translation'; },
      skillTypeTitle() {
        return this.isTranslationSkill
          ? this.$gettext('Translating knowledge')
          : this.$gettext('Interpreting knowledge');
      },
    },
    methods: {
      ...mapMutations('TTManageSupplierSkill', [
        'setLangFrom',
        'setLangTo',
        'setDialectTo',
        'setQualification',
        'setNewCertifTitle',
        'setTolkId',
        'setError',
        'removeOneError',
        'removeErrors'
      ]),
      ...mapMutations('PostingStore/AttachmentsStore', [
        'setAttachmentsList'
      ]),
      ...mapMutations('DocumentCenterStore', ['deleteUploadedFile']),
      catchQualification(value) {
        const chosenQualification = this.qualifsList.find((qualification) => qualification.id === Number(value));
        const tolkIdRequired = chosenQualification.isInTolkeregister && this.tolkId === '' && !this.isTranslationSkill;
        this.requiredTolkId = tolkIdRequired;
        this.setQualification(value);
        this.removeUploadingErrors();
      },
      catchLangFrom(id) {
        this.setLangFrom(id);
        this.langFromOpened = false;
      },
      catchNewFileAttachment(newFile) {
        const certificate = {...newFile, name: newFile.filenameSearchValue};
        this.setAttachmentsList([certificate]);
      },
      catchExistingFileAttachment(files) {
        const certificates = files.map((file) => {
          return {
            ...file,
            name: file.filenameSearchValue
          };
        });
        this.setAttachmentsList(certificates);
      },
      removeUploadingErrors() {
        this.removeOneError('upload');
        this.$store.commit('ErrorsStore/setError', {name: this.uploadErrorsName, errors: []});
      },
      addTolkId() {
        const form = new FormData();
        form.append('interpreter[tolk_id]', this.newTolkId);
        this.$store.dispatch('EditProfileStore/updateUserProfile', {
          id: this.$route.params.id,
          form: form
        });
      },
      validateForm() {
        let invalid = false;
        const tolkIdLength = this.newTolkId.length;
        this.tolkIdNameErrors = [];
        this.removeErrors();

        if (!this.langFrom) {
          this.setError({
            fieldName: 'langFrom',
            errorText: this.$gettext('Your language is required.')
          });
          invalid = true;
        }
        if (!this.langTo) {
          this.setError({
            fieldName: 'langTo',
            errorText: this.$gettext('Target language is required')
          });
          invalid = true;
        }
        if (!this.dialectTo && this.requiredDialect && !this.isTranslationSkill) {
          this.setError({
            fieldName: 'dialectTo',
            errorText: this.$gettext('Dialect for this language is required')
          });
          invalid = true;
        }
        if (this.langFrom && this.langTo && this.langFrom == this.langTo) {
          this.setError({
            fieldName: 'langTo',
            errorText: this.$gettext('Target language must be different from your language.')
          });
          invalid = true;
        }
        if (!this.qualification) {
          this.setError({
            fieldName: 'qualification',
            errorText: this.$gettext('Your qualification level is required')
          });
          invalid = true;
        }
        if (this.requiredTolkId && !this.newTolkId) {
          this.tolkIdNameErrors.push(this.$gettext('Tolk-ID is required'));
          invalid = true;
        } else if (tolkIdLength >= 1 && tolkIdLength < 4) {
          this.tolkIdNameErrors.push(this.$gettext('Tolk-ID must contain 4 characters'));
          invalid = true;
        }

        if (this.qualification && this.isInternalQualification && !this.attachmentsCount && !this.$isGodmode()) {
          this.setError({
            fieldName: 'upload',
            errorText: this.$gettext('Please upload at least one file.'),
          });
          invalid = true;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.langFromErrorsName, errors: this.langFromErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.langToErrorsName, errors: this.langToErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.dialectToErrorsName, errors: this.dialectToErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.qualificationErrorsName, errors: this.qualificationErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.uploadErrorsName, errors: this.uploadErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.tolkIdErrorsName, errors: this.tolkIdNameErrors});

        if (!invalid) {
          this.$emit('formsubmitted');
          if (this.requiredTolkId) {
            this.addTolkId();
          }
        }
      },
      closePopover() {
        this.isPopoverVisible = false;
      },
      openPopover() {
        this.isPopoverVisible = true;
      },
    },
    mounted() {
      this.popoverOptions.popoverReference = this.$refs.popoverReference;
    },
    beforeMount() {
      if (this.isTranslationSkill && !this.langFrom) {
        this.setLangFrom('');
      } else {
        for (const lang of this.langsList) {
          if (lang.default_source && !this.langFrom) this.setLangFrom(lang.id);
        }
      }
    }
  };
</script>

<style>
.skill-form__upload-certif-btn.post-form__field {
  margin: 0;
}
</style>

<style scoped>
  .skill-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  .skill-type__title {
    width: 100%;
    padding-bottom: 10px;
  }

  .skill-form__lang-select,
  .skill-form__qualif-select {
    width: 100%;
    margin-bottom: 15px;
  }

  .skill-form__divider {
    width: 100%;
    margin: 10px 0;
    opacity: 0.4;
  }

  .skill-form__lang-from-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  .skill-form__lang-from-toggle-btn {
    font-weight: normal;
    font-size: 10px;
  }

  .skill-form__lang-select--lang-to,
  .skill-form__dialect-field,
  .skill-form__tolk-id {
    width: calc(50% - 10px);
  }

  .skill-form__certifs-wrapper {
    width: 100%;
    margin-top: 18px;
  }

  .skill-form__certifs-title {
    font-size: 14px;
  }

  .skill-form__certifs-note {
    font-size: 12px;
  }

  .skill-form__certif-title-field {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .skill-form__upload-certif-cont {
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .skill-form__uploaded-certif-cont {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .skill-form__uploaded-one-certif {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    padding-left: 20px;
    text-overflow: ellipsis;
  }

  .skill-form__uploaded-certif-link {
    color: inherit;
    font-size: 14px;
    text-decoration: none;
  }

  .skill-form__uploaded-certif-link:hover {
    text-decoration: underline;
  }

  .skill-form__uploaded-certif-remove-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    background-image: var(--image-trash-icon);
    background-position: 0 50%;
    background-size: 11px auto;
    background-repeat: no-repeat;
  }

  .skill-form__info-acc-first-parahraph,
  .skill-form__info-acc-list-wrapper {
    margin-bottom: 20px;
  }

  .skill-form__info-acc-list-item {
    margin-bottom: 5px;
  }
</style>
