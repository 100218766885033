(<template>
  <div class="add-ticket-modal">
    <div class="add-ticket-modal__steps">
      <p v-html="successMessage"></p>
    </div>
    <div class="add-ticket-modal__fields">
      <sk-select :default-value="categoryText"
                 :description="categoryText"
                 :items-list="categoriesList"
                 :preselected-value="category"
                 :validation-name="categoryErrorsName"
                 class="add-ticket-modal__field add-ticket-modal__field--half-width"
                 @csvaluechanged="catchNewTicketCategory" />
      <search-multiselect-select :description="jobIdsText"
                                 :default-value="jobIdsText"
                                 :items-list="computedJobIds"
                                 :preselected-value="jobIds"
                                 :validation-name="jobIdsErrorsName"
                                 :tags="jobIdsFullList"
                                 class="add-ticket-modal__field"
                                 @onresetfilter="clearSearchResult"
                                 @onsuccess="catchSearchResult"
                                 @checkboxeschanged="catchJobIdsList" />
      <sk-input v-if="isFinanceTicket"
                :placeholder="invoiceNumberText"
                :preselected-value="invoiceNumber"
                :description="invoiceNumberText"
                :reg-exp="/<\/?[^>]+(>|$)/g"
                class="add-ticket-modal__field"
                @fieldvaluechanged="setNewTicketInvoiceNum" />
      <sk-textarea :placeholder="descriptionPlaceholder"
                   :description="descriptionText"
                   :validation-name="descriptionErrorsName"
                   :preselected-value="description"
                   :reg-exp="/<\/?[^>]+(>|$)/g"
                   class="add-ticket-modal__field add-ticket-modal__field--text"
                   @fieldvaluechanged="setNewTicketDescription" />
      <div class="add-ticket-modal__btns-wrapper">
        <button class="sk-btn sk-btn--white add-ticket-modal__btn"
                @click="closeModal">{{ $gettext('Cancel') }}</button>
        <button class="sk-btn sk-btn--default add-ticket-modal__btn"
                @click="submit">{{ $gettext('Submit') }}</button>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapState, mapMutations, mapActions, mapGetters} from 'vuex';
  import SearchJobIdsSelect from './SearchJobIdsSelect';

  export default {
    components: {
      'search-multiselect-select': SearchJobIdsSelect
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('TicketSystemStore', {
        category: ({newTicket}) => newTicket.category,
        jobIds: ({newTicket}) => newTicket.jobIds || [],
        description: ({newTicket}) => newTicket.description,
        invoiceNumber: ({newTicket}) => newTicket.invoiceNumber,
        jobIdsList: ({jobIdsList}) => jobIdsList || [],
        jobIdsFullList: ({jobIdsFullList}) => jobIdsFullList || [],
        categoryErrorsName: ({errors}) => errors.category.name,
        descriptionErrorsName: ({errors}) => errors.description.name,
        jobIdsErrorsName: ({errors}) => errors.jobIds.name
      }),
      ...mapGetters('UserInfoStore', ['kardemommeEnterprise']),
      isFinanceTicket() { return this.category === 'finance'; },
      computedJobIds() {
        return this.jobIdsList.map((job) => {
          const title = job.subject && job.category ? `${job.category}: ${job.subject}` : job.subject;
          const id = job.id ? `#${job.id}` : '';

          job.name = id && title ? `${id} - ${title}` : id || title;
          return job;
        });
      },
      categoryText() { return this.$gettext('Issue'); },
      jobIdsText() { return this.$gettext('Assignment number(s)'); },
      descriptionText() { return this.$gettext('Message'); },
      descriptionPlaceholder() { return this.$gettext('Write here...'); },
      invoiceNumberText() { return this.$gettext('Invoice number'); },
      successMessage() {
        return this.$gettext('All your colleagues have access to submitted messages. If your inquiry concerns finance, invoices, etc., please contact <a class="sk-link sk-link--gray-bg" href="mailto:regnskap@salita.no">regnskap@salita.no</a>.');
      },
      categoriesList() {
        const categories = [{
          id: 'assignment',
          name: this.kardemommeEnterprise ? this.$pgettext('tickets', 'Interpretation assignment') : this.$pgettext('tickets', 'Assignment')
        }, {
          id: 'finance',
          name: this.$pgettext('tickets', 'Finance')
        }, {
          id: 'other',
          name: this.$gettext('Other')
        }];

        if (this.kardemommeEnterprise) {
          categories.push({id: 'translation_assignment', name: this.$pgettext('tickets', 'Translation assignment')});
        }

        return categories;
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapActions('TicketSystemStore', ['setError', 'removeErrors']),
      ...mapMutations('TicketSystemStore', [
        'setNewTicketCategory',
        'setNewTicketJobIds',
        'setNewTicketInvoiceNum',
        'setNewTicketDescription',
        'setJobIdsList',
        'setJobIdsFullList',
        'addJobIdsFullListItem',
        'clearModalStore'
      ]),
      closeModal() { this.$emit('closemodal'); },
      catchNewTicketCategory(value) {
        this.setNewTicketCategory(value);
        this.setNewTicketInvoiceNum('');
      },
      clearSearchResult() {
        this.setJobIdsList([]);
      },
      catchSearchResult() {
        if (!this.computedJobIds.length) {
          this.setJobIdsList([{
            subject: this.$gettext('Not found'),
            id: ''
          }]);
        }
      },
      catchJobIdsList(data) {
        if (data.length < this.jobIds.length) {
          this.setJobIdsFullList(this.jobIdsFullList.filter((item) => data.indexOf(item.id) !== -1));
        } else {
          this.computedJobIds.forEach((item) => {
            if (data.indexOf(item.id) !== -1 && !this.jobIdsFullList.find((job) => job.id === item.id)) {
              this.addJobIdsFullListItem(item);
            }
          });
        }
        this.setNewTicketJobIds(data);
      },
      validateForm() {
        let isValidForm = true;

        this.removeErrors();
        if (!this.category) {
          isValidForm = false;
          this.setError({fieldName: 'category', errorText: this.$gettext('Issue is required')});
        }
        if (!this.description || this.description.trim().length < 5) {
          isValidForm = false;
          this.setError({fieldName: 'description', errorText: this.$gettext('There should be at least 5 characters')});
        }
        if (!this.jobIds.length) {
          isValidForm = false;
          this.setError({fieldName: 'jobIds', errorText: this.$gettext('Assignment number(s) is required')});
        }

        return isValidForm;
      },
      submit() {
        if (this.validateForm()) {
          this.closeModal();
          this.data.context?.addTicket();
        }
      }
    },
    beforeDestroy() {
      this.removeErrors();
      this.clearModalStore();
    }
  };
</script>

<style>
  .add-ticket-modal__field--text textarea {
    height: 90px;
  }
</style>

<style scoped>
  .add-ticket-modal__steps {
    padding: 20px 30px;
  }

  .add-ticket-modal__step {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .add-ticket-modal__step-index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: #008489;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }

  .add-ticket-modal__fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 30px 30px;
  }

  .add-ticket-modal__field {
    width: 100%;
    margin-bottom: 20px;
  }

  .add-ticket-modal__field--half-width {
    width: calc(50% - 5px);
  }

  .add-ticket-modal__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    width: calc(100% + 15px);
    margin: 0 -7.5px;
  }

  .add-ticket-modal__btn {
    width: auto;
    min-width: 80px;
    margin: 0 7.5px;
    padding: 0 15px;
  }
</style>
