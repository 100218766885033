(<template>
  <sk-input :description="caseNumberText"
            :preselected-value="caseNumber"
            :placeholder="caseNumberPlaceholder"
            :validation-name="caseNumberErrorName"
            :reg-exp="/<\/?[^>]+(>|$)/g"
            class="post-form__field post-form__field--half-width"
            @fieldvaluechanged="setCaseNumber" />
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    computed: {
      ...mapState('PostingStore/CaseNumberStore', {
        caseNumber: (state) => state.caseNumber,
        // error names
        caseNumberErrorName: (state) => state.errors.caseNumber.name
      }),
      caseNumberText() { return this.$store.getters['UserInfoStore/labelForCaseNumber'] || this.$gettext('Resource number'); },
      caseNumberPlaceholder() { return this.$store.getters['UserInfoStore/labelForCaseNumber'] || this.$gettext('Case number'); }
    },
    methods: {
      ...mapMutations('PostingStore/CaseNumberStore', [
        'setCaseNumber'
      ])
    }
  };
</script>
