(<template>
  <form class="security-form"
        @submit.stop.prevent="submitForm">
    <sk-textarea :description="rawMetadataDesc"
                 :placeholder="rawMetadataText"
                 :autofocus="true"
                 :reg-exp="/<\/?[^>]+(>|$)/g"
                 style="width: 100%;"
                 rows="10"
                 preselected-value=""
                 :validation-name="rawMetadataErrorsName"
                 @fieldvaluechanged="setRawMetadata" />
    <div class="security-form__btns-wrapper">
      <button class="sk-btn sk-btn--default security-form__btn security-form__btn--update"
              type="submit">{{ $gettext('Update') }}</button>
    </div>
  </form>
</template>)

<script>
  import {mapActions, mapMutations, mapState} from 'vuex';

  export default {
    props: {
    },
    computed: {
      ...mapState('TTEnterpriseSecurityStore', {
        rawMetadataErrors: (state) => state.errors.rawMetadata.errors,
        rawMetadataErrorsName: (state) => state.errors.rawMetadata.name,
        rawMetadata: (state) => state.form.rawMetadata,
      }),
      rawMetadataText() { return this.$gettext('XML metadata contents'); },
      rawMetadataDesc() { return this.$gettext('Paste your XML metadata here'); },
    },
    methods: {
      ...mapMutations('TTEnterpriseSecurityStore', [
        'setError',
        'removeOneError',
        'removeErrors',
        'setRawMetadata',
        'resetForm'
      ]),
      ...mapActions('TTEnterpriseSecurityStore', [
        'createForm',
        'updateEnterpriseSecurity'
      ]),
      submitForm() {
        let indicator = false;

        this.removeErrors();

        if (this.rawMetadata.trim() === '') {
          this.setError({
            fieldName: 'rawMetadata',
            errorText: this.$gettext('XML metadata can\'t be blank')
          });
          indicator = true;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.rawMetadataErrorsName, errors: this.rawMetadataErrors});

        if (!indicator) {
          this.sendInfo();
        }
      },
      finishRequest() {
        this.$emit('formcompleted');
        this.resetForm();
      },
      sendInfo() {
        this.$emit('formstarted');
        this.createForm(this.fullForm)
          .then((form) => {
            this.updateEnterpriseSecurity(form)
              .then(() => {
                this.finishRequest();
              })
              .catch((error) => {
                const errorData = error.data;
                this.$emit('stopprogress');
                this.setError({
                  fieldName: 'rawMetadata',
                  errorText: errorData.errors[0],
                });
                this.$store.commit('ErrorsStore/setError', {
                  name: this.rawMetadataErrorsName,
                  errors: this.rawMetadataErrors
                });
                this.$scrollToErrors();
              });
          });
      }
    }
  };
</script>

<style scoped>
  .security-form {
    display: block;
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    padding: 0;
  }

  .security-form__field {
    margin-bottom: 15px;
  }

  .security-form__submit-btn {
    margin-bottom: 36px;
  }

  .security-form__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .security-form__btn {
    display: inline-block;
    width: auto;
    padding: 0 25px;
  }

  .security-form__btn:first-child {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    .security-form {
      max-width: none;
    }
  }
</style>
