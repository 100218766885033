(<template>
  <section :class="{'in-progress': modalProgress}"
           class="add-skill__main-wrapper is-progress-bar">
    <search-select :description="supplierDescription"
                   :default-value="supplierDefaultText"
                   :items-list="computedSuppliersList"
                   :preselected-value="selectedSupplier"
                   :validation-name="errors.supplier.name"
                   class="add-skill__search-select"
                   @startprogress="modalProgress = true"
                   @stopprogress="modalProgress = false"
                   @onresetfilter="clearSearchResult"
                   @onsuccess="catchSearchResult"
                   @csvaluechanged="changeSupplier" />
    <sk-select :items-list="fromLangsList"
               :description="langFromSelectDescr"
               :default-value="langFromSelectPlace"
               :preselected-value="langFrom"
               :removed-item="langTo"
               :validation-name="errors.langFrom.name"
               class="add-skill__three-quarter-width"
               @csvaluechanged="catchLangFrom" />
    <sk-input :description="dialectFromText"
              :placeholder="dialectText"
              :preselected-value="dialectFrom"
              :reg-exp="/<\/?[^>]+(>|$)/g"
              class="add-skill__quarter-width"
              @fieldvaluechanged="catchDialectFrom" />
    <sk-checkbox :item-text="fromNativeText"
                 :preselected-value="nativeFrom"
                 class="add-skill__native-from"
                 @checkboxvaluechanged="catchNativeFrom" />
    <sk-select :items-list="toLangsList"
               :description="langToSelectDescr"
               :default-value="langToSelectPlace"
               :preselected-value="langTo"
               :removed-item="langFrom"
               :validation-name="errors.langTo.name"
               class="add-skill__three-quarter-width"
               @csvaluechanged="catchLangTo" />
    <sk-input :description="dialectToText"
              :placeholder="dialectText"
              :preselected-value="dialectTo"
              :reg-exp="/<\/?[^>]+(>|$)/g"
              class="add-skill__quarter-width"
              @fieldvaluechanged="catchDialectTo" />
    <sk-checkbox :item-text="toNativeText"
                 :preselected-value="nativeTo"
                 class="add-skill__native-to"
                 @checkboxvaluechanged="catchNativeTo" />

    <sk-select :items-list="interpretationQualifications"
               :description="qualifSelectDescr"
               :default-value="qualifSelectPlace"
               :preselected-value="qualification"
               :validation-name="errors.qualification.name"
               class="add-skill__qualification"
               @csvaluechanged="catchQualification" />

    <sk-select :items-list="skillStatusList"
               :description="statusDescr"
               :preselected-value="skillStatus"
               class="add-skill__skill-status"
               @csvaluechanged="catchSkillStatus" />

    <div class="add-skill__btns-wrapper">
      <button class="sk-btn sk-btn--white add-skill__cancel-btn"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <div class="add-skill__right-btns-wrapper">
        <button class="sk-btn sk-btn--white add-skill__btn"
                @click="validateForm">{{ $gettext('Add') }}</button>
        <button class="sk-btn sk-btn--default add-skill__btn"
                @click="validateForm({notify: true})">{{ $gettext('Add and notify') }}</button>
      </div>
    </div>
  </section>
</template>)

<script>
  import {mapState} from 'vuex';
  import SearchSupplierSelect from '@/components/interpretation_skills/SearchSupplierSelect';

  export default {
    components: {
      'search-select': SearchSupplierSelect
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        selectedSupplier: '',
        suppliersList: [],
        langTo: '',
        langFrom: '',
        dialectTo: '',
        dialectFrom: '',
        nativeFrom: false,
        nativeTo: false,
        qualification: '',
        skillStatus: 'approved',
        errors: {
          langFrom: {name: 'langFromErrorsName', errors: []},
          langTo: {name: 'langToErrorsName', errors: []},
          supplier: {name: 'supplierErrorsName', errors: []},
          qualification: {name: 'qualificationErrorsName', errors: []}
        },
        modalProgress: false
      };
    },
    computed: {
      ...mapState('AllSuppliersStore', {
        searchSuppliersList: ({searchSuppliersList}) => searchSuppliersList || [],
      }),
      computedSuppliersList() {
        if (!this.suppliersList.length) return [{}];
        return this.searchSuppliersList.map((supplier) => {
          return {
            id: supplier.email,
            name: `${supplier.name} (${supplier.email})`
          };
        });
      },
      qualifsList() { return this.$store.state.selectsData.qualification; },
      interpretationQualifications() { return this.$store.getters.interpretationQualifications; },
      langsList() { return this.$store.state.selectsData.language || []; },
      fromLangsList() { return this.langsList.filter((lang) => lang.id != this.langTo); },
      toLangsList() { return this.langsList.filter((lang) => lang.id != this.langFrom); },
      skillStatusList() {
        return [
          {
            id: 'approved',
            name: 'Approved',
          },
          {
            id: 'learning',
            name: 'Learning',
          },
          {
            id: 'declined',
            name: 'Declined',
          }
        ];
      },
      supplierDescription() { return this.$gettext('Supplier*'); },
      supplierDefaultText() { return this.$gettext('Supplier name or email'); },
      langFromSelectDescr() { return this.$gettext('Language from*'); },
      langFromSelectPlace() { return this.$gettext('Select language'); },
      langToSelectDescr() { return this.$gettext('Language to*'); },
      langToSelectPlace() { return this.$gettext('Select language'); },
      dialectToText() { return this.$gettext('Dialect to'); },
      dialectFromText() { return this.$gettext('Dialect from'); },
      dialectText() { return this.$gettext('Dialect'); },
      toNativeText() { return this.$gettext('Native To'); },
      fromNativeText() { return this.$gettext('Native from'); },
      qualifSelectDescr() { return this.$gettext('Qualification*'); },
      qualifSelectPlace() { return this.$gettext('Select qualification'); },
      statusDescr() { return this.$gettext('Status'); },
    },
    methods: {
      setError({fieldName, errorText}) {
        this.errors[fieldName].errors.push(errorText);
        this.$store.commit('ErrorsStore/setError', {
          name: this.errors[fieldName].name,
          errors: this.errors[fieldName].errors
        });
      },
      removeErrors() {
        for (const key of Object.keys(this.errors)) {
          this.errors[key].errors = [];
        }
        this.$store.commit('ModalStore/removeModalErrors');
        this.$store.commit('ErrorsStore/removeAllErrors');
      },
      startProgress() {
        this.$store.commit('ModalStore/startModalProgress');
      },
      stopProgress() {
        this.$store.commit('ModalStore/stopModalProgress');
      },
      closeModal() {
        this.$emit('closemodal');
      },
      validateForm({notify = false}) {
        let isValidForm = true;

        this.removeErrors();
        this.$store.commit('ModalStore/removeModalErrors');

        if (!this.selectedSupplier) {
          isValidForm = false;
          this.setError({
            fieldName: 'supplier',
            errorText: this.$gettext('Supplier is required')
          });
        }
        if (!this.langFrom) {
          isValidForm = false;
          this.setError({
            fieldName: 'langFrom',
            errorText: this.$gettext('Source language is required')
          });
        }

        if (!this.langTo) {
          isValidForm = false;
          this.setError({
            fieldName: 'langTo',
            errorText: this.$gettext('Target language is required')
          });
        }

        if (!this.qualification) {
          isValidForm = false;
          this.setError({
            fieldName: 'qualification',
            errorText: this.$gettext('Qualification is required')
          });
        }

        if (isValidForm) {
          this.submitForm({notify});
        }
      },
      submitForm({notify = false}) {
        const params = {
          skill: {
            supplier: this.selectedSupplier,
            languageFromId: this.langFrom,
            languageToId: this.langTo,
            dialectFrom: this.dialectFrom.trim(),
            dialectTo: this.dialectTo.trim(),
            nativeFrom: this.nativeFrom,
            nativeTo: this.nativeTo,
            qualificationId: this.qualification,
            status: this.skillStatus,
          },
          notifySupplier: notify
        };
        this.modalProgress = true;
        this.$store.dispatch('AllInterpretationSkillsStore/createSkill', params)
          .then(() => {
            this.modalProgress = false;
            this.closeModal();
            if (this.data.context && this.data.context[this.data.successHandlingCallbackName]) {
              this.data.context[this.data.successHandlingCallbackName]();
            }
          }).catch((error) => {
            this.modalProgress = false;
            this.handleError(error);
          });
      },
      handleError(error) {
        if (error && error.data && error.data.errors.language_pair_id.includes('skill is already exists')) {
          this.showToast(this.$gettext('This skill is already added'));
        }
        if (error
          && error.data
          && error.data.errors) {
          if (error.data.errors.length
            && error.data.errors.includes('Email is already registered')) {
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('This email address has already been registered')
            });
          } else if (error.data.errors.email
            && error.data.errors.email.length
            && error.data.errors.email.includes('Invalid email')) {
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('Please check the email address you have provided for spelling errors, and try again.')
            });
          }
        } else {
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
      },
      catchLangFrom(value) {
        this.langFrom = value;
      },
      catchLangTo(value) {
        this.langTo = value;
      },
      catchDialectTo(value) {
        this.dialectTo = value;
      },
      catchDialectFrom(value) {
        this.dialectFrom = value;
      },
      catchNativeTo(value) {
        this.nativeTo = value;
      },
      catchNativeFrom(value) {
        this.nativeFrom = value;
      },
      catchQualification(value) {
        this.qualification = value;
      },
      catchSkillStatus(value) {
        this.skillStatus = value;
      },
      changeSupplier(value) {
        this.selectedSupplier = value;
      },
      clearSearchResult() {
        this.suppliersList = [];
      },
      catchSearchResult() {
        if (!this.suppliersList.length) {
          this.suppliersList = [{name: 'Not found', uid: ''}];
        }
      },
      showToast(message) {
        this.$store.commit('ToastStore/setToast', {
          data: {
            position: 'bottom-center',
            message: message,
            autoDeleteTime: 6000
          }
        });
      },
    },
    beforeMount() {
      this.removeErrors();
    }
  };
</script>

<style scoped>
.add-skill__main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 30px 30px;
}

.add-skill__skill-status,
.add-skill__qualification,
.add-skill__native-to,
.add-skill__search-select,
.add-skill__native-from {
  width: 100%;
  margin-bottom: 12px;
}

.add-skill__three-quarter-width {
  width: calc(60% - 10px);
}

.add-skill__half-width {
  width: calc(50% - 10px);
}

.add-skill__quarter-width {
  width: calc(40% - 10px);
}

.add-skill__btns-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.add-skill__right-btns-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.add-skill__btn {
  width: auto;
  margin: 20px 5px 0;
  padding: 0 20px;
}

.add-skill__cancel-btn {
  max-width: max-content;
  margin: 20px 5px 0;
  padding: 0 20px;
  border: none !important;
}

@media (max-width: 767px) {
  .add-skill__main-wrapper {
    padding: 15px;
  }
}
</style>
