(<template>
  <feedback-section :title="title"
                    :icon="'duration'"
                    :button-description="lastUpdatedText"
                    :progress-active="progressActive"
                    :hide-button="!showSaveButton"
                    class="interpreter-showedup"
                    @save="save">
    <interpreter-info-section class="interpreter-showedup__info" />
    <div class="interpreter-showedup__btns-wrapper">
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': showedUp, 'sk-btn--white': !showedUp, 'feedback__toggle-btn--lg': $isGodmode()}"
              @click="selectShowedUp(true)">{{ $gettext('Yes') }}</button>
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': notShowedUp, 'sk-btn--white': !notShowedUp, 'feedback__toggle-btn--lg': $isGodmode()}"
              @click="selectShowedUp(false)">{{ $gettext('No') }}</button>
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': jobCompletedByColleague, 'sk-btn--white': !jobCompletedByColleague, 'feedback__toggle-btn--lg': $isGodmode()}"
              @click="selectJobCompletedByColleague(true)">{{ $gettext('A colleague completed the assignment') }}</button>
    </div>
    <div v-if="ticketIds && !showedUp"
         class="sk-link tickets-list__link"
         @click="openTicketsList">{{ $gettext('Previous complaints') }}</div>
    <div v-if="showMessageBlock"
         class="interpreter-showedup__report-message">
      <p class="interpreter-showedup__report-message-text">{{ reportMessageTitle }}</p>
      <sk-textarea
        :preselected-value="ticketComment"
        :placeholder="reportMessagePlaceholder"
        :reg-exp="/<\/?[^>]+(>|$)/g"
        class="interpreter-showedup__textarea"
        @fieldvaluechanged="setTicketComment" />

    </div>
    <!--      Feedback Step - 3 -->
    <div v-if="showedUp"
         class="reported-section">
      <reported-duration-section :with-icon="false"
                                 :show-log-text="false" />
    </div>
  </feedback-section>
</template>)

<script>
  import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';
  import InterpreterInfoSection from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/interpreter_showedup_section/InterpreterInfoSection';
  import ReportedDurationSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/reported_duration_section/ReportedDurationSection';

  export default {
    components: {
      'feedback-section': FeedbackSection,
      'interpreter-info-section': InterpreterInfoSection,
      'reported-duration-section': ReportedDurationSection
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('OneAssignmentStore', [
        'storeJob',
        'jobFeedback',
        'currentDiscussion'
      ]),
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter'
      ]),
      ...mapGetters('FeedbackStore', [
        'showedUp',
        'notShowedUp',
        'jobCompletedByColleague'
      ]),
      ...mapState('FeedbackStore', {
        jobCompleted: ({feedback}) => feedback.jobCompleted,
        showedUpVal: ({feedback}) => feedback.showedUp,
        ticketComment: ({feedback}) => feedback.ticketComment,
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData || {}
      }),
      supplierInfo() {
        return this.currentDiscussion.supplierInfo;
      },
      lastUpdatedSectionData() {
        return this.userIsInterpreter
          ? this.lastUpdatedData.supplier_feedback_updated_assignment
          : this.lastUpdatedData.demander_feedback_updated_showed_up;
      },
      title() {
        const template = this.$gettext('Did the interpreter %{name} show up?');
        return this.$gettextInterpolate(template, {name: this.supplierInfo?.name});
      },
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedSectionData
        });
      },
      feedbackId() {
        return this.jobFeedback.id || '';
      },
      reportMessageTitle() {
        const template = this.$gettext('Wish to report something else to %{domain}');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      reportMessagePlaceholder() {
        return this.$gettext('Write message here ...');
      },
      showMessageBlock() {
        return this.notShowedUp || this.jobCompletedByColleague;
      },
      showSaveButton() {
        return (this.notShowedUp || this.jobCompletedByColleague) && this.ticketComment;
      },
      ticketIds() {
        if (this.storeJob.ticketsIds?.default && this.storeJob.ticketsIds?.interpreterDidNotShowUp) {
          return [
            ...this.storeJob.ticketsIds?.interpreterDidNotShowUp,
            ...this.storeJob.ticketsIds?.default
          ];
        } else if (!this.storeJob.ticketsIds?.default) {
          return this.storeJob.ticketsIds?.interpreterDidNotShowUp;
        } else {
          return this.storeJob.ticketsIds?.default;
        }
      },
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setTicketComment'
      ]),
      ...mapActions('FeedbackStore', [
        'setShowedUp',
        'setJobCompletedByColleague'
      ]),
      selectJobCompletedByColleague(value) {
        this.setShowedUp('');
        this.setJobCompletedByColleague(value);
      },
      selectShowedUp(showedUp) {
        this.setTicketComment('');
        this.setJobCompletedByColleague('');
        this.setShowedUp(showedUp);
        showedUp === true ? this.save() : null;
      },
      setFeedbackInfo() {
        this.setJobCompletedByColleague(this.jobFeedback.jobCompletedByColleague || localStorage.jobCompletedByColleague || false);
        this.setShowedUp(this.jobFeedback.showedUp || localStorage.showedUp);
        this.setTicketComment(this.jobFeedback.ticketComment || localStorage.ticketComment || '');
      },
      setProgress(value) {
        this.progressActive = value;
      },
      validate() {
        return this.jobCompletedByColleague || this.showedUp !== '';
      },
      save() {
        if (this.validate()) {
          const params = {
            discussionId: this.currentDiscussion.id,
            feedbackId: this.feedbackId,
            feedback: {
              ticket_comment: this.ticketComment
            }
          };
          if (this.ticketComment) params.feedback.ticket_context = 'interpreter_did_not_show_up';
          if (this.showedUpVal !== '') params.feedback.showed_up = this.showedUp;
          if (this.jobCompletedByColleague) params.feedback.job_completed_by_colleague = this.jobCompletedByColleague;
          const action = this.feedbackId ? 'FeedbackStore/updateFeedbackV3' : 'FeedbackStore/sendFeedbackV3';
          this.setProgress(true);
          this.$store.dispatch(action, params).then(() => {
            this.setProgress(false);
          }).catch(() => {
            this.setProgress(false);
          });
        }
      },
      openTicketsList() {
        this.$store.commit('ModalStore/setModal', {
          component: 'tickets-list',
          width: 410,
          data: {
            title: this.$gettext('Complaint tickets list'),
            ticketsList: this.ticketIds
          }
        });
      }
    },
    mounted() {
      this.setFeedbackInfo();
    }
  };
</script>

<style>
.reported-section .feedback-section {
  padding: 10px 0 !important;
}
</style>

<style scoped>
.interpreter-showedup__btns-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 50px;
}

.interpreter-showedup__info {
  margin-bottom: 20px;
}

.interpreter-showedup__report-message {
  margin-top: 25px;
}

.interpreter-showedup__textarea {
  height: 110px;
}

.tickets-list__link {
  position: absolute;
  bottom: 60px;
  left: 70px;
  z-index: 2;
  width: auto;
  height: auto;
}
</style>
