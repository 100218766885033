(<template>
  <div class="ticket-message-modal">
    <sk-textarea :placeholder="replyPlaceholder"
                 :validation-name="replyTextErrorsName"
                 :preselected-value="replyText"
                 :reg-exp="/<\/?[^>]+(>|$)/g"
                 class="ticket-message-modal__field"
                 @fieldvaluechanged="setReplyText" />
    <div class="ticket-message-modal__btns-wrapper">
      <button class="sk-btn sk-btn--white ticket-message-modal__btn"
              @click="closeModal">{{ $gettext('Cancel') }}
      </button>
      <button class="sk-btn sk-btn--default ticket-message-modal__btn"
              @click="submit">{{ $pgettext('custom', 'Send') }}
      </button>
    </div>
  </div>
</template>)

<script>
  import {mapActions, mapMutations, mapState} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('OneTicketStore', {
        replyText: ({replyText}) => replyText,
        replyTextErrorsName: ({errors}) => errors.replyText.name
      }),
      replyPlaceholder() { return this.$gettext('Write here...'); },
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapActions('OneTicketStore', [
        'setError',
        'removeErrors'
      ]),
      ...mapMutations('OneTicketStore', [
        'setReplyText',
        'clearModalStore'
      ]),
      closeModal() {
        this.$emit('closemodal');
      },
      validateForm() {
        let isValidForm = true;

        this.removeErrors();
        if (!this.replyText.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'replyText',
            errorText: this.$gettext('Reply text is required')
          });
        }

        return isValidForm;
      },
      submit() {
        if (this.validateForm()) {
          this.closeModal();
          this.data.context?.addReplyMessage(this.replyText);
        }
      }
    },
    beforeDestroy() {
      this.removeErrors();
      this.clearModalStore();
    }
  };
</script>

<style>
  .ticket-message-modal__field textarea {
    height: 70px;
  }
</style>

<style scoped>
  .ticket-message-modal {
    padding: 20px 30px;
  }

  .ticket-message-modal__field {
    width: 100%;
    margin-bottom: 20px;
  }

  .ticket-message-modal__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    width: calc(100% + 15px);
    margin: 0 -7.5px;
  }

  .ticket-message-modal__btn {
    width: auto;
    min-width: 80px;
    margin: 0 7.5px;
    padding: 0 15px;
  }
</style>
