(<template>
  <article :class="{'in-progress': progressActive}"
           class="send-details-modal is-progress-bar">
    <h2 class="send-details-modal__title">{{ titleText }}</h2>
    <sk-note :text="infoText"
             class="send-details-modal__info" />
    <sk-input :description="emailDescription"
              :placeholder="emailPlaceholder"
              :preselected-value="email"
              :validation-name="emailErrorsName"
              :reg-exp="/<\/?[^>]+(>|$)/g"
              class="send-details-modal__email"
              @fieldvaluechanged="catchEmail" />
    <sk-textarea :description="messageDescription"
                 :placeholder="massegePlaceholder"
                 :preselected-value="message"
                 :reg-exp="/<\/?[^>]+(>|$)/g"
                 class="send-details-modal__message"
                 @fieldvaluechanged="catchMessage" />
    <div class="send-details-modal__btn-cont">
      <button class="sk-btn sk-btn--default send-details-modal__btn"
              @click="catchDetailsEmail">{{ $gettext('Send email') }}</button>
    </div>
  </article>
</template>)

<script>
  import {mapActions, mapGetters} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        progressActive: false,
        email: '',
        emailErrors: [],
        emailErrorsName: 'emailError',
        message: ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      emailDescription() { return this.$gettext('Email address*'); },
      emailPlaceholder() { return this.$gettext('Email*'); },
      messageDescription() { return this.$gettext('Personal message'); },
      massegePlaceholder() { return this.$gettext('Write here...'); },
      titleText() {
        return this.userIsInterpreter
          ? this.$gettext('Send a copy of the assignment details to yourself')
          : this.$gettext('Send your colleague these assignment details');
      },
      infoText() {
        return this.userIsInterpreter
          ? this.$gettext('Do not send this information to anyone other than yourself.')
          : this.$gettext('Only send these assignment details to people who you work with and trust! Don\'t add any sensitive information.');
      },
      jobId() { return this.data.jobId || ''; }
    },
    methods: {
      ...mapActions('OneAssignmentStore', ['sendJobDetails']),
      catchEmail(value) { this.email = value; },
      catchMessage(value) { this.message = value; },
      validateEmail(email) {
        return helpers.validation.validateEmail(email);
      },
      isAnyErrors() {
        this.emailErrors = [];
        if (!this.email.trim()) {
          this.emailErrors.push(this.$gettext('Email is required'));
        } else if (!this.validateEmail(this.email)) {
          this.emailErrors.push(this.$gettext('Email is not valid'));
        }
        this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});
        return this.emailErrors.length;
      },
      catchDetailsEmail() {
        if (!this.isAnyErrors()) {
          const form = new FormData();
          const id = this.jobId || this.$route.params.id;
          this.progressActive = true;
          form.append('email', this.email);
          form.append('message', this.message);
          form.append('platform', 'email');
          this.sendJobDetails({form, id}).then(() => {
            this.progressActive = false;
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Success! These assignment details have been sent to your colleague via E-mail.')
              });
            });
          }).catch((error) => {
            this.progressActive = false;
            if (error?.data?.errors?.email?.length
              && error.data.errors.email.includes('Invalid email')) {
              this.emailErrors.push(this.$gettext('Please check the email address you have provided for spelling errors, and try again.'));
              this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});
            }
          });
        }
      },
      preselectData() {
        this.email = this.data.email || '';
      }
    },
    beforeMount() {
      this.$store.commit('ErrorsStore/removeAllErrors');
    },
    mounted() {
      this.preselectData();
    }
  };
</script>

<style scoped>
  .send-details-modal {
    padding: 15px;
  }

  .send-details-modal__title {
    margin: 20px auto;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    text-align: center;
  }

  .send-details-modal__info {
    margin-bottom: 20px;
  }

  .send-details-modal__email {
    margin-bottom: 20px;
  }

  .send-details-modal__message {
    height: 98px;
    margin-bottom: 20px;
  }

  .send-details-modal__btn-cont {
    display: flex;
    justify-content: flex-end;
  }

  .send-details-modal__btn {
    width: auto;
    padding: 0 10px;
  }
</style>
