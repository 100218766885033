(<template>
  <form class="payment-modal"
        @submit.prevent.stop="validateInvoice">
    <org-number-field v-if="!isEmailInvoice"
                      :description="norwegianOrgNumberText"
                      :placeholder="norwegianOrgNumberText"
                      :validation-name="errors.orgNumber.name"
                      :preselected-value="orgNumber"
                      :country="orgNumberCountry"
                      :disabled="!!paymentId"
                      :max-length="9"
                      class="payment-modal__field"
                      :autofocus="!isEmailInvoice"
                      @fieldvaluechanged="changeOrgNumber" />
    <sk-input v-if="!isEhfInvoice"
              :description="emailText"
              :placeholder="emailText"
              :preselected-value="email"
              :validation-name="errors.email.name"
              :type="'email'"
              :reg-exp="/<\/?[^>]+(>|$)/g"
              class="participants-section__input"
              :autofocus="isEmailInvoice"
              @fieldvaluechanged="catchEmail" />
    <full-address v-if="isEmailInvoice"
                  ref="addressComponent"
                  :description="addressText" />
    <sk-note v-if="!isEmailInvoice && !$isGodmode()"
             class="payment-modal__email-note"
             :text="collectiveInvoicesText" />
    <sk-checkbox v-if="userIsAdminOfEnterprise || $isGodmode()"
                 :preselected-value="defaultValue"
                 :item-text="defaultText"
                 class="payment-modal__field payment-checkbox__field"
                 @checkboxvaluechanged="changeDefaultValue" />
    <div v-if="isNewPaymentMethod"
         class="payment-modal__default-departments-methods">
      <sk-checkbox v-if="userIsAdminOfEnterprise"
                   :preselected-value="defaultDepartmentsValue"
                   :item-text="defaultDepartmentsText"
                   class="payment-modal__field"
                   @checkboxvaluechanged="changeDefaultDepartmentsValue" />
      <sk-multiselect v-if="defaultDepartmentsValue"
                      :items-list="departmentsList"
                      :preselected-value="defaultDepartmentIds"
                      :default-value="defaultDepartmentsSelectText"
                      :validation-name="errors.defaultDepartments.name"
                      @checkboxeschanged="changeDepartmentsHandler" />
    </div>
    <section class="payment-modal__action-btns-cont">
      <button class="sk-btn sk-btn--white is-progress-bar payment-modal__action-btn"
              type="button"
              @click="$emit('closemodal')">{{ cancelText }}</button>
      <button class="sk-btn sk-btn--default is-progress-bar payment-modal__action-btn"
              type="submit">{{ submitText }}</button>
    </section>
  </form>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import OrgNumberField from '@/components/enterprise/form_parts/OrgNumberField';
  import FullAddress from '@/components/shared_components/FullAddress';

  export default {
    components: {
      'org-number-field': OrgNumberField,
      'full-address': FullAddress
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        orgNumberCountry: 'Norway',
        defaultValue: this.data.currentPayment?.default || false,
        orgNumber: this.data.currentPayment?.orgNumber || '',
        email: this.data.currentPayment?.email || '',
        address: this.data.currentPayment?.address || '',
        defaultDepartmentIds: [],
        defaultDepartmentsValue: false,
        errors: {
          orgNumber: {name: 'orgNumberValidation', errors: []},
          email: {name: 'emailError', errors: []},
          address: {name: 'addressError', errors: []},
          defaultDepartments: {name: 'departmentsError', errors: []},
        }
      };
    },
    computed: {
      ...mapState('FullAddressStore', [
        'line1',
        'line2',
        'county',
        'postcode',
        'city',
        'country'
      ]),
      ...mapState('EnterpriseStore', {
        departmentsList: ({departments}) => departments || [],
      }),
      ...mapGetters('UserInfoStore', [
        'enterpriseName',
        'userIsAdminOfEnterprise',
      ]),
      paymentId() {
        return this.data.currentPayment?.id || '';
      },
      defaultText() {
        const template = this.enterpriseName
          ? this.$gettext('Set as default for "%{name}"')
          : this.$gettext('Set as default');
        return this.$gettextInterpolate(template, {name: this.enterpriseName});
      },
      defaultDepartmentsSelectText() {
        return this.$gettext('Select deapartments');
      },
      defaultDepartmentsText() {
        return this.$gettext('Set as default for department(s)');
      },
      norwegianOrgNumberText() {
        return this.$gettext('Norwegian organisation number');
      },
      cancelText() {
        return this.$gettext('Cancel');
      },
      submitText() {
        return this.paymentId ? this.$gettext('Edit method') : this.$gettext('Add method');
      },
      invoiceFrequencyText() {
        return this.$gettext('Invoice frequency');
      },
      emailText() { return this.$gettext('Invoice email address'); },
      addressText() { return this.$gettext('Invoice address'); },
      collectiveInvoicesText() { return this.$gettext('Contact <a class="sk-link sk-link--gray-bg" href="mailto:regnskap@salita.no">regnskap@salita.no</a> if you would like to receive collective invoices.'); },
      isNewPaymentMethod() { return !this.data.currentPayment; },
      isEmailInvoice() { return this.data.type === 'email'; },
      isEhfInvoice() { return this.data.type === 'ehf'; },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; },
      invoiceFrequencyList() {
        return [{
          id: 'daily',
          name: helpers.invoiceInfo.invoiceFrequency(this, 'daily')
        }, {
          id: 'weekly',
          name: helpers.invoiceInfo.invoiceFrequency(this, 'weekly')
        }, {
          id: 'fortnightly',
          name: helpers.invoiceInfo.invoiceFrequency(this, 'fortnightly')
        }];
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapMutations('FullAddressStore', [
        'clearStore',
        'setAddressInfo'
      ]),
      setError({fieldName, errorText}) {
        this.errors[fieldName].errors.push(errorText);
        this.$store.commit('ErrorsStore/setError', {
          name: this.errors[fieldName].name,
          errors: this.errors[fieldName].errors
        });
      },
      removeErrors() {
        for (const key of Object.keys(this.errors)) {
          this.errors[key].errors = [];
        }
        this.$store.commit('ErrorsStore/removeAllErrors');
      },
      changeOrgNumber(value) {
        this.orgNumber = value;
      },
      changeDefaultDepartmentsValue(value) {
        this.defaultDepartmentsValue = value;
      },
      changeDepartmentsHandler(value) {
        this.defaultDepartmentIds = [...value];
      },
      changeDefaultValue(value) {
        this.defaultValue = value;
      },
      catchEmail(value) {
        this.email = value;
      },
      validateEmail(email) {
        return helpers.validation.validateEmail(email);
      },
      validateInvoice() {
        let isValidForm = true;

        this.removeErrors();
        if (!this.isEmailInvoice && !this.orgNumber.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'orgNumber',
            errorText: this.$gettext('Organisation number is required')
          });
        }
        if (!this.isEmailInvoice && this.orgNumber.trim() && this.orgNumber.trim() < 9) {
          isValidForm = false;
          this.setError({
            fieldName: 'orgNumber',
            errorText: this.$gettext('Organisation number should be 9 symbols')
          });
        }
        if (this.isEmailInvoice) isValidForm = this.$refs.addressComponent.validateForm();

        if (
          // email required always validate
          (this.isEmailInvoice && !this.validateEmail(this.email.trim()))
          // email not required validate if given
          || (this.email.trim() && !this.validateEmail(this.email.trim()))
        ) {
          isValidForm = false;
          this.setError({
            fieldName: 'email',
            errorText: this.$gettext('Valid email address is required.')
          });
        }

        if (this.defaultDepartmentsValue && !this.defaultDepartmentIds.length) {
          isValidForm = false;
          this.setError({
            fieldName: 'defaultDepartments',
            errorText: this.$gettext('Choose department(s).')
          });
        }

        if (isValidForm) {
          this.saveInvoice();
        }
      },
      getOrganizationInvoiceForm() {
        const form = new FormData();

        form.append('payment_method[org_number]', this.orgNumber);
        form.append('payment_method[method_type]', 'organization');
        form.append('payment_method[default]', !!this.defaultValue);
        // send ehf value for trigger BE validation of org number
        form.append('payment_method[invoice_delivery_mechanizm]', 'ehf');
        if (this.email) form.append('payment_method[email]', this.email);
        if (this.defaultDepartmentIds && this.defaultDepartmentIds.length) {
          this.defaultDepartmentIds.forEach((deapartmentId) => {
            form.append('payment_method[departments_ids][]', deapartmentId);
          });
        } else {
          form.append('payment_method[departments_ids][]', null);
        }

        return form;
      },
      getEmailInvoiceForm() {
        const form = new FormData();

        form.append('payment_method[email]', this.email);
        form.append('payment_method[method_type]', 'individual');
        form.append('payment_method[default]', !!this.defaultValue);
        form.append('payment_method[address][address_line_1]', this.line1);
        form.append('payment_method[address][address_line_2]', this.line2);
        form.append('payment_method[address][city]', this.city);
        form.append('payment_method[address][county]', this.county);
        form.append('payment_method[address][country]', this.country);
        form.append('payment_method[address][postcode]', this.postcode);
        form.append('payment_method[invoice_delivery_mechanizm]', 'email');
        if (this.defaultDepartmentIds && this.defaultDepartmentIds.length) {
          this.defaultDepartmentIds.forEach((deapartmentId) => {
            form.append('payment_method[departments_ids][]', deapartmentId);
          });
        } else {
          form.append('payment_method[departments_ids][]', null);
        }

        return form;
      },
      saveInvoice() {
        const actionName = this.paymentId ? 'PaymentPayoutStore/updatePayment' : 'PaymentPayoutStore/createPayment';
        const form = this.isEmailInvoice ? this.getEmailInvoiceForm() : this.getOrganizationInvoiceForm();
        const params = {form};

        if (this.paymentId) {
          params.paymentId = this.paymentId;
        }
        this.$emit('startprogress');
        this.$store.dispatch(actionName, params).then((data) => {
          if (this.data.context && this.data.context[this.data.successHandlingCallback]) {
            this.data.context[this.data.successHandlingCallback](data || {});
          }
          this.stopProgress();
          this.closeModal();
        }).catch((error) => {
          this.handleErrors(error);
        });
      },
      handleErrors(error) {
        this.stopProgress();
        if (error?.data?.errors?.length
          && error.data.errors.includes('Organisation number is invalid')) {
          this.setError({
            fieldName: 'orgNumber',
            errorText: this.$gettext('Organization number is not valid.')
          });
        } else if (error?.data?.errors['global!']) {
          this.setError({
            fieldName: 'orgNumber',
            errorText: error?.data?.errors['global!'][0]
          });
        } else if (error?.data?.errors === 'Org number er allerede i bruk') {
          this.setError({
            fieldName: 'orgNumber',
            errorText: this.$gettext('A payment method with the same organization number already exists.')
          });
        } else if (error?.status == 401) {
          this.$store.dispatch('UserInfoStore/fetchUserInfo', this.$store.getters['UserInfoStore/userUid']).then(() => {
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Sorry you don\'t have rights to create payment methods. Please contact an administrator.')
              });
            }, 0);
          });
        } else if (error?.data?.errors?.org_number.includes('Organization number is invalid')) {
          this.setError({
            fieldName: 'orgNumber',
            errorText: this.$gettext('Organization number is invalid.')
          });
        } else {
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      closeModal() {
        this.$emit('closemodal');
      }
    },
    mounted() {
      this.isEmailInvoice && this.setAddressInfo(this.address);
    },
    beforeDestroy() {
      this.removeErrors();
      this.clearStore();
    }
  };
</script>

<style scoped src="@assets/css/payment_modal.css"></style>
