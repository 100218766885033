(<template>
  <post-section :class="{'in-progress': progress}"
                :title="customerInvoiceSectionTitle"
                class="buyer-invoice is-progress-bar">
    <div class="buyer-invoice__items-wrapper">
      <payment-method-section :show-assignment-helper="true" />
      <div v-for="(item, index) in items"
           :key="index"
           class="buyer-invoice__item">
        <div class="buyer-invoice__item-title-wrapper">
          <button v-if="item.showHelperIcon"
                  class="buyer-invoice__item-help-icon"
                  @click="item.onHelperClick"></button>
          <div :ref="item.ref"
               :class="{'hasIcon': item.showHelperIcon}"
               class="buyer-invoice__item-title">{{ item.text }}</div>
        </div>
        <component :is="item.componentName"
                   v-if="item.componentName"
                   :key="index"
                   :ref="item.ref"
                   :no-description="true"
                   :preselected-value="item.value"
                   :class="item.classes"
                   :default-value="item.defaultValue"
                   :placeholder="item.text"
                   :reg-exp="item.regExp"
                   :validation-name="item.errorsName"
                   :text="item.message"
                   class="buyer-invoice__item-input"
                   @focus="item.onFocus ? item.onFocus() : null"
                   @fieldvaluechanged="item.onClick" />
        <div v-else
             :class="{ 'is-with-error': (item.errors && item.errors.length) }">
          <div class="buyer-invoice__item-text"
               :class="item.classes"
               v-html="item.value"></div>
          <div v-if="item.errors && item.errors.length"
               class="sk-input__errors-cont">
            <span v-for="(error, errorIndex) in item.errors"
                  :key="errorIndex"
                  v-html="(item.errors.length === 1) ? error : `${errorIndex + 1}. ${error}`"></span>
          </div>
        </div>
      </div>
    </div>
  </post-section>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import PostingValidation from '@/prototypes/PostingValidation';
  import PostSection from '@/components/booking_components/PostSection';
  import PaymentMethodSection from '@/components/booking_components/parts/PaymentMethodSection';

  export default {
    components: {
      'post-section': PostSection,
      'payment-method-section': PaymentMethodSection,
    },
    extends: PostingValidation,
    props: {
      title: {
        type: String,
        default: ''
      },
      progress: Boolean
    },
    data() {
      return {
        bookingRefWarning: [],
        paymentBookingRefWarning: []
      };
    },
    computed: {
      ...mapState('PostingStore/BookingRefStore', {
        bookingRef: (state) => state.bookingReference || '',
        paymentBookingRef: (state) => state.paymentBookingReference || ''
      }),
      ...mapGetters('PostingStore/PaymentMethodStore', ['currentPaymentMethodData']),
      ...mapGetters('UserInfoStore', [
        'mainUserInfo',
        'enterpriseDefaultBookingReference',
        'enterpriseDefaultPaymentBookingReference',
      ]),
      ...mapState('PostingStore', {
        bookingRef: ({BookingRefStore}) => BookingRefStore.bookingReference || '',
        paymentBookingRef: ({BookingRefStore}) => BookingRefStore.paymentBookingReference || '',
        caseNumber: ({CaseNumberStore}) => CaseNumberStore.caseNumber || '',
        // errors
        bookingRefErrorName: ({BookingRefStore}) => BookingRefStore.errors.bookingRef.name,
        paymentBookingRefErrorName: ({BookingRefStore}) => BookingRefStore.errors.paymentBookingRef.name,
        caseNumberErrorName: ({CaseNumberStore}) => CaseNumberStore.errors.caseNumber.name,
      }),
      ...mapState('PostAssignmentHelperStore', {
        isHelperVisible: ({isHelperVisible}) => isHelperVisible || false
      }),
      bookingRegExp() { return new RegExp(this.currentPaymentMethodData.bookingReferenceRegex || ''); },
      paymentBookingRegExp() { return new RegExp(this.currentPaymentMethodData.paymentBookingReferenceRegex || ''); },
      defaultRegExpHint() { return this.$gettext('Allowed characters: 0-9 A-Z :/-'); },
      bookingRegExpHint() { return this.currentPaymentMethodData.bookingReferenceRegexHint || this.defaultRegExpHint; },
      paymentBookingRegExpHint() { return this.currentPaymentMethodData.paymentBookingReferenceRegexHint || this.defaultRegExpHint; },
      customerInvoiceSectionTitle() {
        const btnText = this.$gettext('Edit');
        return `${this.title}<button class="sk-btn sk-btn--white sk-btn--rounded buyer-invoice__btn invoice-edit-btn" key="invoice-edit-btn" type="button">${btnText}</button>`;
      },
      showBookingReferenceField() { return this.currentPaymentMethodData.allowBookingReference; },
      showPaymentBookingReferenceField() { return this.currentPaymentMethodData.allowPaymentBookingReference; },
      showCaseNumberField() { return this.currentPaymentMethodData.allowCaseNumber; },
      labelForBookingRef() { return this.currentPaymentMethodData.labelForBookingReference; },
      labelForPaymentBookingRef() { return this.currentPaymentMethodData.labelForPaymentBookingReference; },
      labelForCaseNumber() { return this.currentPaymentMethodData.labelForCaseNumber; },
      itemsList() {
        return [
          {
            condition: this.showBookingReferenceField,
            ref: 'bookingRef',
            text: `${this.labelForBookingRef || this.$gettext('Booking ref.')}:`,
            value: this.bookingRef,
            componentName: 'sk-input',
            defaultValue: '-',
            regExp: /[^0-9a-zA-ZÆØÅæøå\/:\- ]/g,
            errorsName: this.bookingRefErrorName,
            onFocus: () => {
              this.setContext('interpretation');
              this.setPopoverReference(this.$refs.bookingRef);
              this.openHelper('bookingReference');
            },
            onClick: (value) => {
              this.setBookingReference(value);
              this.setFromBookingReference('user');
              this.checkBookingReference(value, this.bookingRegExp, this.bookingRefWarning);
            }
          },
          {
            condition: this.mainUserInfo.role === 'buyer',
            ref: 'bookingRef',
            text: `${this.labelForBookingRef || this.$gettext('Booking ref.')}:`,
            value: this.bookingRef,
            componentName: 'sk-input',
            defaultValue: '-',
            regExp: /[^0-9a-zA-ZÆØÅæøå\/:\- ]/g,
            errorsName: this.bookingRefErrorName,
            onFocus: () => {
              this.setContext('interpretation');
              this.setPopoverReference(this.$refs.bookingRef);
              this.openHelper('bookingReference');
            },
            onClick: (value) => {
              this.setBookingReference(value);
              this.setFromBookingReference('user');
              this.checkBookingReference(value, this.bookingRegExp, this.bookingRefWarning);
            }
          },
          {
            condition: this.showBookingReferenceField,
            classes: ['sk-input-warning', ...this.bookingRefWarning],
            value: this.bookingRegExpHint
          },
          {
            condition: this.showPaymentBookingReferenceField,
            ref: 'paymentBookingRef',
            text: `${this.labelForPaymentBookingRef || this.$gettext('Attestant BID')}:`,
            value: this.paymentBookingRef,
            componentName: 'sk-input',
            defaultValue: '-',
            regExp: /[^0-9a-zA-ZÆØÅæøå\/:\- ]/g,
            errorsName: this.paymentBookingRefErrorName,
            onFocus: () => {
              this.setContext('interpretation');
              this.setPopoverReference(this.$refs.paymentBookingRef);
              this.openHelper('paymentBookingReference');
            },
            onClick: (value) => {
              this.setPaymentBookingReference(value);
              this.setFromPaymentBookingReference('user');
              this.checkBookingReference(value, this.paymentBookingRegExp, this.paymentBookingRefWarning);
            }
          },
          {
            condition: this.mainUserInfo.role === 'buyer',
            ref: 'paymentBookingRef',
            text: `${this.labelForPaymentBookingRef || this.$gettext('Payment booking reference')}:`,
            value: this.paymentBookingRef,
            componentName: 'sk-input',
            defaultValue: '-',
            regExp: /[^0-9a-zA-ZÆØÅæøå\/:\- ]/g,
            errorsName: this.paymentBookingRefErrorName,
            onFocus: () => {
              this.setContext('interpretation');
              this.setPopoverReference(this.$refs.paymentBookingRef);
              this.openHelper('paymentBookingReference');
            },
            onClick: (value) => {
              this.setPaymentBookingReference(value);
              this.setFromPaymentBookingReference('user');
              this.checkBookingReference(value, this.paymentBookingRegExp, this.paymentBookingRefWarning);
            }
          },
          {
            condition: this.showPaymentBookingReferenceField,
            classes: ['sk-input-warning', ...this.paymentBookingRefWarning],
            value: this.paymentBookingRegExpHint
          },
          {
            condition: this.showCaseNumberField,
            ref: 'caseNumber',
            text: `${this.labelForCaseNumber || this.$gettext('Case number')}:`,
            value: this.caseNumber,
            componentName: 'sk-input',
            regExp: /<\/?[^>]+(>|$)/g,
            defaultValue: '-',
            errorsName: this.caseNumberErrorName,
            onFocus: () => {
              this.setContext('interpretation');
              this.setPopoverReference(this.$refs.caseNumber);
              this.openHelper('caseNumber');
            },
            onClick: (value) => {
              this.setCaseNumber(value);
            }
          },
        ];
      },
      items() { return this.itemsList.filter((item) => item.condition); }
    },
    watch: {
      customerInvoiceSectionTitle() {
        setTimeout(this.addEditHandler, 100);
      },
      bookingRegExp() {
        this.checkBookingReference(this.bookingRef, this.bookingRegExp, this.bookingRefWarning);
      },
      paymentBookingRegExp() {
        this.checkBookingReference(this.paymentBookingRef, this.paymentBookingRegExp, this.paymentBookingRefWarning);
      }
    },
    methods: {
      ...mapMutations('PostingStore/BookingRefStore', [
        'setBookingReference',
        'setFromBookingReference',
        'setPaymentBookingReference',
        'setFromPaymentBookingReference',
      ]),
      ...mapMutations('PostingStore/CaseNumberStore', [
        'setCaseNumber',
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'closeHelper',
        'setContext',
        'setPopoverReference',
      ]),
      openEditModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'assignment-info-edit-modal',
          classes: ['sk-modal--new'],
          data: {
            sectionName: ['invoiceInformationSections'],
            validateFuncName: 'validateInvoiceSection',
            sendInfoFunc: this.sendInfoFunc,
            successHandlingFunc: this.successHandlingFunc,
            submitButtonText: this.$gettext('Confirm changes'),
            openEditFunc: this.openEditModal
          }
        });
      },
      sendInfoFunc() {
        return Promise.resolve();
      },
      successHandlingFunc() {
        this.checkBookingReference(this.bookingRef, this.bookingRegExp, this.bookingRefWarning);
        this.checkBookingReference(this.paymentBookingRef, this.paymentBookingRegExp, this.paymentBookingRefWarning);
        this.$store.dispatch('ModalStore/closeModal');
      },
      addEditHandler() {
        const linkElement = document.querySelector('.invoice-edit-btn');

        if (linkElement) {
          linkElement.addEventListener('click', this.openEditModal);
        }
      },
      checkBookingReference(value, regexp, warningField) {
        if (value === '' || !regexp.test(value)) {
          warningField.pop('sk-input-warning-checked');
        } else if (regexp.test(value) && !warningField.length) {
          warningField.push('sk-input-warning-checked');
        }
      }
    },
    mounted() {
      this.addEditHandler();
      this.checkBookingReference(this.bookingRef, this.bookingRegExp, this.bookingRefWarning);
      this.checkBookingReference(this.paymentBookingRef, this.paymentBookingRegExp, this.paymentBookingRefWarning);
    }
  };
</script>

<style>
.buyer-invoice__item-input .sk-input__input {
  height: 25px;
  font-size: 12px;
  line-height: 25px;
}

#app.salita-facelifted .buyer-invoice__item-input .sk-input__input {
  height: 36px;
  font-size: 14px;
  line-height: 20px;
}

.buyer-invoice .post-section__title {
  display: flex;
  justify-content: space-between;
}

.buyer-invoice .buyer-invoice__btn {
  width: auto;
  min-width: 50px;
  padding: 0 10px;
}

.buyer-invoice__item-input.payment-method {
  max-width: 100%;
}

.buyer-invoice__item-input .payment-method__row {
  margin: 0;
}

.buyer-invoice__item-input .payment-method__select-text {
  font-weight: normal;
}
</style>

<style scoped>
.buyer-invoice__items-wrapper {
  display: flex;
  flex-direction: column;
  margin: -5px 0;
}

.buyer-invoice__item {
  display: flex;
  margin: 5px 0;
}

.buyer-invoice__item-help-icon {
  width: 20px;
  height: 20px;
  background-image: var(--image-question-mark-icon-black);
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.buyer-invoice__item-help-icon:focus {
  box-shadow: inset 0 0 0 2px #3af;
}

.buyer-invoice__item-title-wrapper {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}

.buyer-invoice__item-title {
  width: 160px;
  min-width: 160px;
  padding-right: 10px;
  font-weight: bold;
  line-height: 1.14;
}

.buyer-invoice__item-title.hasIcon {
  width: 132px;
  min-width: 132px;
  word-break: break-word;
}

.buyer-invoice__item-input {
  max-width: 300px;
  margin-bottom: 0;
}

.buyer-invoice__item-text {
  max-width: 300px;
  color: #7f7f7f;
}

.sk-input-warning {
  padding-left: 20px;
  background-image: url(~@assets/imgs/salita/undefined_imgs/warning_icon.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  color: #7f7f7f;
  font-size: 12px;
  line-height: 16px;
  word-break: normal;
}

.sk-input-warning-checked {
  background-image: url(~@assets/imgs/undefined_imgs/check_icon_green.svg);
}

@media (max-width: 767px) {
  .buyer-invoice__item {
    flex-wrap: wrap;
  }

  .buyer-invoice__item-title {
    width: 100%;
  }

  .buyer-invoice__item-help-icon {
    display: none;
  }
}
</style>
