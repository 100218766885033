(<template>
  <div class="public-feedback">
    <p class="public-feedback__title">{{ title }}</p>
    <p class="public-feedback__note">
      {{ $gettext('For complaints') }}
      <router-link :to="$makeRoute({name: 'BuyerTicketSystem', query: {view: 'opened', page: 1}})">{{ $gettext(' click here') }}</router-link>
    </p>
    <sk-textarea :placeholder="publicFeedbackCommentPlaceholder"
                 :preselected-value="publicFeedbackComment"
                 :validation-name="publicCommentErrorName"
                 :maxlength="feedbackCharLimit"
                 :reg-exp="/<\/?[^>]+(>|$)/g"
                 class="feedback__field public-feedback__textarea"
                 @fieldvaluechanged="setPublicFeedbackComment" />
    <p class="public-feedback__character-limit"
       v-html="charMessage"></p>
    <sk-checkbox v-if="publicFeedbackComment"
                 :preselected-value="allowedByDemander"
                 :item-text="allowedByDemanderText"
                 class="sk-checkbox--top-alignment feedback__field cancel-modal__checkbox"
                 @checkboxvaluechanged="setPublicFeedbackAllowedByDemander" />
  </div>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
      ]),
      ...mapState('FeedbackStore', {
        publicFeedbackComment: ({feedback}) => feedback.publicFeedback.comment || '',
        allowedByDemander: ({feedback}) => feedback.publicFeedback.allowedByDemander,
        publicCommentErrorName: ({feedback}) => feedback.errors.publicFeedbackComment.name
      }),
      title() {
        return this.$gettext('Write direct feedback to the interpreter');
      },
      publicFeedbackCommentPlaceholder() {
        return this.$gettext('Write here...');
      },
      allowedByDemanderText() {
        return this.$gettext('My feedback may be published on the interpreter\'s profile page so that other clients can read it');
      },
      remainingChars() {
        return this.feedbackCharLimit - this.publicFeedbackComment.length;
      },
      feedbackCharLimit() {
        return 1000;
      },
      charMessage() {
        const remChars = this.remainingChars;
        const charLimit = this.feedbackCharLimit;
        const template = this.$gettext('Maximum <strong>%{charLimit}</strong> characters ( <strong> %{remChars} </strong> remaining )');
        return this.$gettextInterpolate(template, {remChars, charLimit});
      }
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setPublicFeedbackComment',
        'setPublicFeedbackAllowedByDemander'
      ])
    }
  };
</script>

<style>
  .public-feedback .sk-input__textarea {
    min-height: 80px;
  }
</style>

<style scoped>
.public-feedback__character-limit {
  margin: -10px 0 10px 20px;
}

.public-feedback__note {
  margin-bottom: 5px;
  color: #b68e56;
}

.public-feedback__note a {
  text-decoration: underline;
}

.public-feedback__title {
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
