(<template>
  <article class="projects-list">
    <header class="projects-list__header">
      <div class="projects-list__header-container"
           :class="smallerCards ? 'md-container' : 'container'">
        <div v-if="showColleaguesOrders || enterpriseId"
             class="projects-list__headers-wrap">
          <button v-for="headerTab in headersTabs"
                  :key="headerTab.id"
                  class="projects-list__header-text header-tab"
                  :class="{'header-tab--active': headerTab.id === currentHeaderTab}"
                  @click="setCurrentHeader(headerTab.id)">{{ headerTab.title }}</button>
        </div>
        <h1 v-else-if="headerText"
            class="projects-list__header-text">{{ headerText }}</h1>
        <button v-if="buttonText"
                class="sk-btn sk-btn--default navigation__project-btn"
                @click="$emit('header-action')">{{ buttonText }}</button>
      </div>
    </header>
    <div class="projects-list__content">
      <div class="projects-list__content-wrap"
           :class="smallerCards ? 'md-container' : 'container'">
        <h3 v-if="title"
            class="projects-list__title">{{ title }}</h3>
        <div class="projects-list__tabs-search-wrapper">
          <div class="tabs">
            <button v-for="tab in tabs"
                    :key="tab.tab"
                    :class="{'tabs__btn--active': tab.isActive}"
                    class="tabs__btn"
                    type="button"
                    @click="tab.onClick ? tab.onClick(tab.tab) : ''">{{ tab.title }}</button>
          </div>
        </div>
        <!------ Search Section  ----->
        <div class="projects-list__search-wrapper">
          <sk-input class="projects-list__search-field"
                    :placeholder="searchPlaceholder"
                    :preselected-value="searchTerm"
                    :input-icon="'search'"
                    :margin-bottom="false"
                    :reg-exp="/<\/?[^>]+(>|$)/g"
                    @fieldvaluechanged="catchSearchTerm"
                    @enterpressed="search" />
          <button v-if="searchTerm"
                  class="project-list__search--clear-btn"
                  @click="resetSearch"></button>
          <button class="sk-btn sk-btn--default projects-list__search-btn"
                  @click="search">{{ searchText }}</button>
        </div>
        <sk-note v-if="paginationField === 'availableSubtasks' && isProactiveTab"
                 class="projects-list__proactive-note"
                 :text="proactiveNoteText" />
        <!--        <div class="projects-filter">-->
        <!--          <div class="icon projects-filter__icon"></div>-->
        <!--          <p>{{ filter }}</p>-->
        <!--        </div>-->
        <div v-if="projects"
             :class="{'in-progress': activeProgress}"
             class="project-cards is-progress-bar">
          <single-project v-for="(project, index) in projects"
                          :key="`${project.id}-${index}`"
                          :show-name="!userIsInterpreter"
                          :color-pallets="cardColorPallet"
                          :data="project"
                          @startprogress="activeProgress = true"
                          @stopprogress="activeProgress = false" />
        </div>
      </div>
      <sk-pagination v-if="totalPages > 1"
                     class="projects-list__pagination"
                     :current="+currentPage"
                     :total="+totalPages"
                     @prev="toPrevPage"
                     @next="toNextPage"
                     @newpage="toNewPage" />
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import SingleProject from '@/components/project_components/list/SingleProject';

  export default {
    components: {
      'single-project': SingleProject
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      headerText: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      tabs: {
        type: Array,
        default: () => []
      },
      projects: {
        type: Array,
        default: () => []
      },
      cardColorPallet: {
        type: Function,
        default: () => this.defaultPallet
      },
      smallerCards: {
        type: Boolean,
        default: false
      },
      progress: {
        type: Boolean,
        default: false
      },
      showColleaguesOrders: {
        type: Boolean,
        default: false
      },
      currentPage: {
        type: [String, Number],
        default: 0
      },
      totalPages: {
        type: [String, Number],
        default: 0
      },
      paginationField: {
        type: String,
        default: ''
      },
      searchPlaceholder: {
        type: String,
        default() {
          return this.$gettext('Search');
        }
      }
    },
    data() {
      return {
        currentHeaderTab: this.$route.query.scope || 'my_projects',
        currentView: this.$route.query.view || 'active',
        searchTerm: '',
        activeProgress: this.progress
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userIsNotInterpreter',
        'enterpriseId'
      ]),
      filter() { return this.$gettext('Filter'); },
      searchText() { return this.$gettext('Search'); },
      myProjectsText() { return this.$pgettext('translation', 'My Projects'); },
      myColleaguesText() { return this.$pgettext('translation', 'Department projects'); },
      myEnterprisesText() { return this.$pgettext('translation', 'Enterprise projects'); },
      isProactiveTab() { return this.$route.query.availableStatus === 'proactive'; },
      proactiveNoteText() {
        return this.$gettext('These assignments are made available to all our preferred  translators, and can be claimed at any time. You can find invitations to assignments in a separate tab.');
      },
      headersTabs() {
        return [
          {
            id: 'my_projects',
            title: this.myProjectsText,
            condition: this.showColleaguesOrders
          },
          {
            id: 'colleagues_orders',
            title: this.myColleaguesText,
            condition: this.showColleaguesOrders
          },
          {
            id: 'enterprise_orders',
            title: this.myEnterprisesText,
            condition: this.enterpriseId
          }
        ].filter((tab) => tab.condition);
      },
    },
    methods: {
      catchSearchTerm(term) {
        this.searchTerm = term;
      },
      search() {
        this.$emit('search', this.searchTerm);
      },
      resetSearch() {
        this.searchTerm = '';
        this.$emit('search', this.searchTerm);
      },
      defaultPallet(status) {
        const statuses = ['quote_requested', 'quote_accepted', 'quote_rejected', 'quote_sent'];
        if (statuses.includes(status)) {
          return {
            textColor: '#003855',
            bgColor: '#99afbb'
          };
        } else if (status === 'in_progress') {
          return {
            textColor: '#008489',
            bgColor: '#b2dadc'
          };
        } else if (status === 'finished' || status === 'completed') {
          return {
            textColor: '#2b255c',
            bgColor: '#d5d3de'
          };
        } else if (status === 'cancelled') {
          return {
            textColor: '#e52322',
            bgColor: '#f5a7aa'
          };
        } else {
          return {
            textColor: '#003855',
            bgColor: '#99afbb'
          };
        }
      },
      setCurrentHeader(headerTab) {
        this.currentHeaderTab = headerTab;
        this.$emit('header-tab-changed', headerTab);
      },
      toPrevPage() {
        this.$emit('prev', {field: this.paginationField});
      },
      toNewPage(page) {
        this.$emit('newpage', {field: this.paginationField, page});
      },
      toNextPage() {
        this.$emit('next', {field: this.paginationField});
      }
    }
  };
</script>

<style>
.projects-list__search-wrapper {
  position: relative;
  display: flex;
  margin-top: 17px;
}

.projects-list__search-field {
  flex: 8.5;
  margin-bottom: 0;
}

.projects-list__search-field .sk-input__input--with-icon {
  padding-right: 10px;
  padding-left: 40px;
}

.projects-list__search-field .sk-input__field-icon {
  left: 10px;
}

.projects-list__search-btn {
  flex: 1.5;
  border-radius: 0 3px 3px 0;
}

.projects-list__search-field .sk-input__input {
  border-radius: 4px 0 0 4px !important;
}

.project-list__search--clear-btn {
  position: absolute;
  top: 33%;
  right: 70px;
  width: 15px;
  height: 15px;
  transform: translate(0, -50%);
}

.project-list__search--clear-btn::before,
.project-list__search--clear-btn::after {
  content: ' ';
  position: absolute;
  top: 0;
  width: 1px;
  height: 15px;
  background-color: #a7abbd;
}

.project-list__search--clear-btn::before {
  transform: rotate(45deg);
}

.project-list__search--clear-btn::after {
  transform: rotate(-45deg);
}

.projects-list__proactive-note {
  margin-top: 15px;
}

.projects-list__navigation .sk-date-picker {
  width: auto;
  border: none;
}

.projects-list__navigation .sk-date-picker__value {
  width: auto;
  min-width: 100px;
  padding-right: 25px;
  background-color: transparent;
  font-weight: bold;
}

.projects-list__navigation .sk-date-picker__value::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 12px;
  box-sizing: border-box;
  width: 7px;
  border-top: 1px solid #000;
  transform: rotate(43deg);
}

.projects-list__navigation .sk-date-picker__value::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 7px;
  box-sizing: border-box;
  width: 7px;
  border-top: 1px solid #000;
  transform: rotate(-43deg);
}

.projects-list__navigation .is-active .sk-date-picker__value::before {
  transform: rotate(-43deg);
}

.projects-list__navigation .is-active .sk-date-picker__value::after {
  transform: rotate(43deg);
}

.projects-list__navigation .sk-date-picker__value:active {
  width: auto;
  padding-right: 25px;
  background-color: transparent;
}

.projects-list__navigation .sk-date-picker__calendar.sk-date-picker__calendar {
  left: -100px;
}

.projects-list__navigation .sk-select {
  background-color: white;
}

@media (max-width: 767px) {
  .projects-list__navigation .sk-date-picker__calendar.sk-date-picker__calendar {
    left: 0;
  }
}
</style>

<style scoped>
.project-cards {
  margin-top: 40px;
}

.container {
  width: 100%;
  max-width: 1020px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.md-container {
  width: 100%;
  max-width: 760px;
  margin-right: auto;
  padding-right: 10px;
  padding-left: 10px;
}

.projects-list {
  flex: 1;
  height: 100%;
  background-color: #fff;
}

.seller-projects .projects-list {
  flex: 1 0 100%;
}

.seller-projects .projects-list__header {
  background-color: #f4f5f7;
}

.projects-list__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-list__header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.projects-list__headers-wrap {
  display: flex;
}

.projects-list__header-text {
  margin-right: 30px;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 25px;
}

.projects-list__title {
  margin-bottom: 15px;
  font-size: 18px;
}

.projects-list__tabs-search-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d3d5de;
}

.projects-list__pagination {
  margin: 10px auto;
}

.seller-projects .projects-list__content {
  background-color: #fff;
}

.projects-list__content-wrap {
  padding-top: 10px;
  padding-bottom: 20px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}

#app.salita-facelifted .projects-list__content-wrap {
  border-radius: 8px;
  box-shadow: var(--shadow-and-blur);
}

.seller-projects .projects-list__content-wrap {
  box-shadow: none;
}

.projects-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon {
  width: 15px;
  height: 15px;
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
}

.projects-filter__icon {
  margin: 10px 4px;
  background-image: var(--image-filter-icon);
}

.tabs {
  display: flex;
}

.tabs__btn {
  position: relative;
  display: inline-block;
  padding: 8px 4px 10px;
  color: #555;
  font-size: 14px;
}

.tabs__btn:not(:first-child) {
  margin-left: 9px;
}

.header-tab:focus,
.tabs__btn:focus {
  outline: 2px solid #000;
}

.header-tab--active,
.tabs__btn--active {
  border-bottom: 5px solid #ff5b24;
  border-bottom: 5px solid var(--color-secondary);
  color: #ff5b24;
  color: var(--color-secondary);
}

.navigation__project-btn {
  width: auto;
  padding: 0 11px;
}

@media (min-width: 480px) {
  .projects-list__header-text {
    margin-bottom: 0;
  }

  .tabs__btn:not(:first-child) {
    margin-left: 20px;
  }

  .tabs__btn {
    font-size: 16px;
  }

  .md-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 768px) {
  .seller-projects .projects-list {
    position: relative;
    margin-top: 60px;
    background-color: transparent;
  }

  .seller-projects .projects-list__header {
    position: absolute;
    top: -56px;
    background-color: transparent;
  }

  .seller-projects .projects-list__content {
    border-radius: 10px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  }

  .tabs__btn:not(:first-child) {
    margin-left: 25px;
  }
}

@media (min-width: 1024px) {
  .projects-list {
    padding-bottom: 0;
  }

  .seller-projects .projects-list {
    margin-top: 0;
  }

  .projects-list__header {
    position: relative;
    min-height: 200px;
    background-color: #f4f5f7;
  }

  .seller-projects .projects-list__header {
    position: static;
  }

  .projects-list__header-container {
    margin-bottom: 0;
    padding: 30px 0 16px 0;
  }

  .projects-list__content-wrap {
    position: relative;
    z-index: 2;
    margin-top: -120px;
    padding: 24px 30px;
    border-radius: 10px;
    background-color: #fff;
  }

  .seller-projects .projects-list__content-wrap {
    max-width: 100%;
  }

  .projects-list__content {
    padding-bottom: 40px;
    border-radius: 10px;
  }

  .seller-projects .projects-list__content {
    padding-bottom: 0;
  }
}
</style>
