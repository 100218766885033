(<template>
  <article class="select-language-modal sk-modal__wrapper">
    <section class="select-language-modal__section--search">
      <h2 class="select-language-modal__title">{{ selectLanguageText }}</h2>
      <sk-input ref="search"
                :placeholder="searchLanguageText"
                :preselected-value="preselectedValue"
                :input-icon="'search'"
                :autofocus="true"
                :reg-exp="/<\/?[^>]+(>|$)/g"
                class="select-language-modal__input"
                @fieldvaluechanged="setSearchLanguageQuery" />
    </section>
    <section v-if="showResultsSection"
             class="select-language-modal__section">
      <h3 class="select-language-modal__subtitle">{{ searchQueryResultsText }}</h3>
      <div class="select-language-modal__languages-list"
           :style="listColumns">
        <button v-for="language in filteredLanguageList"
                :key="language.id"
                class="select-language-modal__languages-list--item"
                @click="selectLanguage(language)">
          {{ language.name }}
        </button>
      </div>
    </section>
    <section v-if="showNoResultsSection"
             class="select-language-modal__section">
      <h3 class="select-language-modal__subtitle">{{ searchQueryNoResultsText }}</h3>
    </section>
    <section v-if="showLanguagesSection"
             class="select-language-modal__section">
      <h3 class="select-language-modal__subtitle">{{ $gettext('Top languages') }}</h3>
      <div class="select-language-modal__top-languages-list">
        <button v-for="topLanguage in topLanguagesFiltered"
                :key="topLanguage.id"
                class="select-language-modal__top-languages-list--item"
                :class="{'active': setActive(topLanguage.id)}"
                @click="selectLanguage(topLanguage)">
          {{ topLanguage.name }}
        </button>
      </div>
    </section>
    <section v-if="showLanguagesSection"
             class="select-language-modal__section">
      <h3 class="select-language-modal__subtitle">{{ $gettext('All languages') }}</h3>
      <div class="select-language-modal__languages-list"
           :style="listColumns">
        <button v-for="language in languages"
                :key="language.id"
                class="select-language-modal__languages-list--item"
                :class="{'active': setActive(language.id)}"
                @click="selectLanguage(language)">
          {{ language.name }}
        </button>
      </div>
    </section>
  </article>
</template>)

<script>
  import {mapState, mapActions} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        searchLanguageQuery: ''
      };
    },
    computed: {
      ...mapState('PostingStore/LanguagesStore', {
        langTo: (state) => state.languageTo,
        langFrom: (state) => state.languageFrom,
        targetLanguages: (state) => state.targetLanguages,
        topLanguages: (state) => state.top10languages
      }),
      ...mapState('PostingNotifierStore', {
        isOnTranslation: (state) => state.isOnTranslation
      }),
      topLanguagesFiltered() {
        if (this.topLanguages.length) {
          return this.isOnTranslation ? this.topLanguages.filter((lang) => !this.targetLanguages.includes(lang.id)) : this.topLanguages.filter((lang) => lang.id !== 3);
        }
        return this.top10Languages;
      },
      modalData() { return this.data; },
      modalMethod() { return this.modalData.method; },
      modalField() { return this.modalData.field; },
      modalCallback() { return this.modalData.context[this.modalMethod]; },
      languages() {
        const translationLangs = this.$store.state.selectsData.language.filter((lang) => !this.targetLanguages.includes(lang.id));
        return !this.isOnTranslation ? this.$store.state.selectsData.language.filter((lang) => lang.id !== 3) : translationLangs || [];
      },
      languageToNameById() {
        return this.languages.find((lang) => lang.id == this.langTo)?.name || '';
      },
      languageFromNameById() {
        return this.languages.find((lang) => lang.id == this.langFrom)?.name || '';
      },
      preselectedValue() {
        return this.modalField === 'langTo'
          ? this.languageToNameById
          : this.modalField === 'langFrom'
            ? this.languageFromNameById
            : '';
      },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; },
      selectLanguageText() { return this.$gettext('Select a language'); },
      searchLanguageText() { return this.$gettext('Search for a language'); },
      searchQueryResultsText() {
        const template = this.$gettext('\"%{query}\" is another name for these languages:');
        return this.$gettextInterpolate(template, {query: this.searchLanguageQuery});
      },
      searchQueryNoResultsText() {
        const template = this.$gettext('No results for \"%{query}\"');
        return this.$gettextInterpolate(template, {query: this.searchLanguageQuery});
      },
      showResultsSection() { return this.filteredLanguageList.length; },
      showNoResultsSection() { return !this.filteredLanguageList.length && this.searchLanguageQuery; },
      showLanguagesSection() { return !this.searchLanguageQuery; },
      // To replace with BE hits
      filteredLanguageList() {
        return this.searchLanguageQuery ? this.languages.filter((lang) => lang.name.toLowerCase().includes(this.searchLanguageQuery)) : [];
      },
      listColumns() {
        if (this.clientWidth >= 767) {
          return {
            columns: 4,
          };
        } else if (this.clientWidth >= 530) {
          return {
            columns: 3,
          };
        } else {
          return {
            columns: 2,
          };
        }
      },
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapActions(
        'PostingStore/LanguagesStore', [
          'getTopLanguages',
          'getTopTranslationLanguages'
        ]
      ),
      closeModal() {
        this.$emit('closemodal');
      },
      selectLanguage(language) {
        this.searchLanguageQuery = '';
        this.modalCallback(language.id);
        this.$store.dispatch('ModalStore/closeModal');
      },
      setSearchLanguageQuery(query) {
        this.searchLanguageQuery = query.trim().toLowerCase();
      },
      setActive(language) {
        return this.modalField === 'langTo'
          ? language == this.langTo
          : this.modalField === 'langFrom'
            ? language == this.langFrom
            : false;
      }
    },
    mounted() {
      if (this.isOnTranslation) {
        this.getTopTranslationLanguages();
      } else this.getTopLanguages();
      const input = document.querySelector('.sk-input__input');
      setTimeout(() => {
        input.focus();
      }, 1000);
    }
  };
</script>

<style scoped>
.select-language-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  margin-top: 20px;
  padding: 0 40px;
}

.select-language-modal__title {
  margin-bottom: 15px;
  color: #000;
  font-size: 20px;
}

.select-language-modal__subtitle {
  margin-top: 16px;
  margin-bottom: 16px;
  color: #000;
  font-size: 14px;
}

.select-language-modal__input {
  margin-bottom: 0;
}

.select-language-modal__section {
  position: relative;
  padding-bottom: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.select-language-modal__section--search {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
}

.select-language-modal__top-languages-list {
  display: inline-flex;
  flex-wrap: wrap;
}

.select-language-modal__top-languages-list--item {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 6px 8px 6px 8px;
  border-radius: 5px;
  background-color: rgba(0, 132, 137, 0.1);
  color: #008489;
  font-size: 14px;
  cursor: pointer;
}

.select-language-modal__top-languages-list--item:hover {
  text-decoration: underline;
  opacity: 0.7;
}

.select-language-modal__top-languages-list--item:focus {
  outline: auto;
}

.select-language-modal__top-languages-list--item.active {
  border: 2px solid #008489;
}

.select-language-modal__languages-list--item {
  display: block;
  width: 100%;
  padding: 6px 8px 6px 8px;
  border-radius: 5px;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-language-modal__languages-list--item:hover,
.select-language-modal__languages-list--item.active {
  background-color: rgba(0, 132, 137, 0.1);
  color: #008489;
}

.select-language-modal__languages-list--item:focus {
  outline: auto;
}

@media (max-width: 767px) {
  .select-language-modal {
    height: auto;
    padding: 0 20px;
  }
}
</style>
