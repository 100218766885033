(<template>
  <section class="add-client">
    <p class="add-client__description"
       v-html="description"></p>
    <div class="add-client__fields">
      <sk-input :description="text1"
                :placeholder="text1"
                :preselected-value="firstName"
                :margin-bottom="true"
                :validation-name="firstNameValidationName"
                :reg-exp="/<\/?[^>]+(>|$)/g"
                class="add-client__field"
                @fieldvaluechanged="setFirstName" />
      <sk-input :description="text2"
                :placeholder="text2"
                :preselected-value="lastName"
                :margin-bottom="true"
                :validation-name="lastNameValidationName"
                :reg-exp="/<\/?[^>]+(>|$)/g"
                class="add-client__field"
                @fieldvaluechanged="setLastName" />
      <sk-input :description="text3"
                :placeholder="text3"
                :preselected-value="orgName"
                :margin-bottom="true"
                :validation-name="orgNameValidationName"
                :reg-exp="/<\/?[^>]+(>|$)/g"
                class="add-client__field"
                @fieldvaluechanged="setOrgName" />
      <sk-input :description="text4"
                :placeholder="text5"
                :preselected-value="orgNumber"
                :margin-bottom="true"
                :validation-name="orgNumberValidationName"
                :reg-exp="/<\/?[^>]+(>|$)/g"
                class="add-client__field"
                @fieldvaluechanged="setOrgNumber" />
      <sk-input :description="text6"
                :placeholder="text6"
                :preselected-value="email"
                :margin-bottom="true"
                :validation-name="emailValidationName"
                :reg-exp="/<\/?[^>]+(>|$)/g"
                class="add-client__field"
                @fieldvaluechanged="setEmail" />
      <div class="add-client__field add-client__phone-wrapper">
        <sk-select :type="'countryCodes'"
                   :description="text7"
                   :default-value="text7"
                   :preselected-value="phoneCode"
                   :mob-numeric-keyboard="true"
                   class="add-client__phone-code"
                   @csvaluechanged="setPhoneCode" />
        <sk-input :description="text8"
                  :placeholder="text8"
                  :preselected-value="phoneNumber"
                  :margin-bottom="true"
                  :validation-name="phoneNumberValidationName"
                  :type="'tel'"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  class="add-client__phone"
                  @fieldvaluechanged="setPhoneNumber" />
      </div>
    </div>
    <div class="add-client__btn-cont">
      <button class="sk-btn sk-btn--white add-client__btn add-client__btn--cancel"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default add-client__btn"
              @click="saveClient">{{ submitBtnText }}</button>
    </div>
  </section>
</template>)

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      ...mapGetters('TTManageClientsStore', ['errorsState']),
      ...mapState('TTManageClientsStore', {
        clientId: (state) => state.currentClient.id,
        firstName: (state) => state.currentClient.firstName,
        lastName: (state) => state.currentClient.lastName,
        orgName: (state) => state.currentClient.orgName,
        orgNumber: (state) => state.currentClient.orgNumber,
        email: (state) => state.currentClient.email,
        phoneCode: (state) => state.currentClient.phoneCode,
        phoneNumber: (state) => state.currentClient.phoneNumber,
        // error names
        firstNameValidationName: (state) => state.errors.firstName.name,
        lastNameValidationName: (state) => state.errors.lastName.name,
        orgNameValidationName: (state) => state.errors.orgName.name,
        orgNumberValidationName: (state) => state.errors.orgNumber.name,
        emailValidationName: (state) => state.errors.email.name,
        phoneNumberValidationName: (state) => state.errors.phoneNumber.name,
        // errors
        firstNameErrors: (state) => state.errors.firstName.errors,
        lastNameErrors: (state) => state.errors.lastName.errors,
        orgNameErrors: (state) => state.errors.orgName.errors,
        orgNumberErrors: (state) => state.errors.orgNumber.errors,
        emailErrors: (state) => state.errors.email.errors,
        phoneNumberErrors: (state) => state.errors.phoneNumber.errors
      }),
      text1() { return this.$gettext('First name*'); },
      text2() { return this.$gettext('Last name*'); },
      text3() { return this.$gettext('Organisation name*'); },
      text4() { return this.$gettext('Org. number*'); },
      text5() { return this.$gettext('Organisation number*'); },
      text6() { return this.$gettext('E-mail*'); },
      text7() { return this.$gettext('Country code'); },
      text8() { return this.$gettext('Phone number*'); },
      submitBtnText() { return this.isEditClient ? this.$gettext('Edit contact') : this.$gettext('Create new contact'); },
      description() { return this.data.description || ''; },
      isEditClient() { return this.data && this.data.currentClient; }
    },
    methods: {
      ...mapActions('TTManageClientsStore', [
        'createForm',
        'addClient',
        'editClient'
      ]),
      ...mapMutations('TTManageClientsStore', [
        'setError',
        'removeErrors',
        'setCurrentClient',
        'removeCurrentClient',
        'setFirstName',
        'setLastName',
        'setOrgName',
        'setOrgNumber',
        'setEmail',
        'setPhoneCode',
        'setPhoneNumber'
      ]),
      closeModal() { this.$emit('closemodal'); },
      validateEmail(email) {
        return helpers.validation.validateEmail(email);
      },
      isAnyErrors() {
        this.removeErrors();

        if (!this.firstName.trim()) {
          this.setError({
            fieldName: 'firstName',
            errorText: this.$gettext('First name can\'t be blank')
          });
        }
        if (!this.lastName.trim()) {
          this.setError({
            fieldName: 'lastName',
            errorText: this.$gettext('Last name can\'t be blank')
          });
        }
        if (!this.orgName.trim()) {
          this.setError({
            fieldName: 'orgName',
            errorText: this.$gettext('Company name can\'t be blank')
          });
        }
        if (!this.orgNumber.trim()) {
          this.setError({
            fieldName: 'orgNumber',
            errorText: this.$gettext('Org. number can\'t be blank')
          });
        } else if (Number.isNaN(Number(this.orgNumber.trim()))) {
          this.setError({
            fieldName: 'orgNumber',
            errorText: this.$gettext('Org. number should contain only digits')
          });
        }
        if (!this.email.trim()) {
          this.setError({
            fieldName: 'email',
            errorText: this.$gettext('Email can\'t be blank')
          });
        } else if (!this.validateEmail(this.email.trim())) {
          this.setError({
            fieldName: 'email',
            errorText: this.$gettext('Email is not valid')
          });
        }
        if (!this.phoneNumber.trim()) {
          this.setError({
            fieldName: 'phoneNumber',
            errorText: this.$gettext('Phone number can\'t be blank')
          });
        }

        this.$store.commit('ErrorsStore/setError', {name: this.firstNameValidationName, errors: this.firstNameErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.lastNameValidationName, errors: this.lastNameErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.orgNameValidationName, errors: this.orgNameErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.orgNumberValidationName, errors: this.orgNumberErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.emailValidationName, errors: this.emailErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberValidationName, errors: this.phoneNumberErrors});

        return this.errorsState;
      },
      saveClient() {
        if (!this.isAnyErrors()) {
          this.$emit('startprogress');
          this.createForm().then((form) => {
            const actionName = this.clientId ? 'editClient' : 'addClient';
            const params = {uid: this.userUid, form};
            if (this.clientId) {
              params.clientId = this.clientId;
              params.clientIndex = this.data.context.clientIndex;
            }
            this[actionName](params).then(() => {
              this.$emit('stopprogress');
              this.closeModal();
            }).catch((error) => {
              this.$emit('stopprogress');
              if (error
                && error.errors
                && error.errors.org_number
                && error.errors.org_number.length
                && error.errors.org_number.indexOf('Organisation number is invalid') !== -1) {
                this.setError({
                  fieldName: 'orgNumber',
                  errorText: this.$gettext('Organisation number is not valid')
                });
                this.$store.commit('ErrorsStore/setError', {name: this.orgNumberValidationName, errors: this.orgNumberErrors});
              }
              if (error
                && error.errors
                && error.errors.phone_number
                && error.errors.phone_number.length
                && error.errors.phone_number.indexOf('Invalid phone') !== -1) {
                this.setError({
                  fieldName: 'phoneNumber',
                  errorText: this.$gettext('Please enter a valid phone number')
                });
                this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberValidationName, errors: this.phoneNumberErrors});
              }
            });
          });
        }
      }
    },
    beforeMount() {
      this.$store.commit('ErrorsStore/removeAllErrors');
      if (this.isEditClient) {
        this.setCurrentClient(this.data.currentClient);
      }
    },
    beforeDestroy() {
      this.removeErrors();
      this.removeCurrentClient();
    }
  };
</script>

<style>
  .add-client__phone-code .sk-select__list {
    max-height: 75px;
  }
</style>

<style scoped>
  .add-client {
    padding: 20px;
  }

  .add-client__description {
    margin-bottom: 20px;
  }

  .add-client__fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }

  .add-client__field {
    width: calc(50% - 10px);
  }

  .add-client__phone-wrapper {
    display: flex;
  }

  .add-client__phone-code {
    flex-shrink: 0;
    width: 90px;
    margin-right: 15px;
  }

  .add-client__phone {
    width: auto;
  }

  .add-client__btn-cont {
    display: flex;
    justify-content: flex-end;
  }

  .add-client__btn {
    width: auto;
    min-width: 110px;
    padding: 0 20px;
  }

  .add-client__btn--cancel {
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    .add-client__field {
      width: 100%;
    }

    .add-client__phone {
      width: 100%;
    }
  }
</style>
