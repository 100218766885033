(<template>
  <form-template class="choose-name__form-template"
                 :title="title"
                 :progress="progressActive">
    <div class="choose-name"
         @keydown.enter.stop.prevent="sendData">
      <div class="choose-name__inputs-cont">
        <sk-input :placeholder="firstNameText"
                  :autofocus="true"
                  :description="firstNameText"
                  :preselected-value="firstName"
                  :validation-name="firstNameErrorsName"
                  :reg-exp="/<\/?[^>]+(>|$)/g"
                  class="choose-name__input"
                  @fieldvaluechanged="setFirstName" />
        <sk-input :placeholder="lastNameText"
                  :description="lastNameText"
                  :preselected-value="lastName"
                  :validation-name="lastNameErrorsName"
                  :reg-exp="/<\/?[^>]+(>|$)/g"
                  class="choose-name__input"
                  @fieldvaluechanged="setLastName" />
      </div>

      <div v-if="userIsInterpreter"
           class="choose-name__avatar-requirements">
        <h3 class="choose-name__avatar-requirements--title">{{ avatarRequirementTitle }}</h3>
        <p class="choose-name__avatar-requirements--desc">{{ avatarRequirementDes }}</p>
      </div>
      <div class="choose-name__avatar-label-wrapper">
        <label class="choose-name__avatar-label">
          <tt-avatar :rotation="avatarRotation"
                     :avatar="avatarLink"
                     class="choose-name__avatar" />
          <button class="choose-name__avatar-label-text"
                  @keydown.enter.stop.prevent="$refs.avatarInput.click()"
                  @click="$refs.avatarInput.click()">{{ $gettext('Upload picture') }}</button>
          <input ref="avatarInput"
                 slot="fileInput"
                 class="is-hidden"
                 type="file"
                 @change="catchAvatar">
        </label>
        <div v-if="avatartErrors && avatartErrors.length"
             class="choose-name__avatar-errors">
          <p v-for="(error, index) in avatartErrors"
             :key="index">{{ error }}</p>
        </div>
        <button v-if="avatarLink"
                class="choose-name__rotate-btn"
                @keydown.enter.stop.prevent="rotatePicture"
                @click="rotatePicture"></button>
      </div>
    </div>
    <div
      v-if="userIsInterpreter"
      class="choose-name__good-pictures">
      <div v-for="(pic,index) in profilePicsList"
           :key="index"
           class="choose-name__good-pictures-wrapper">
        <p class="choose-name__picture-title">{{ pic.desc }}</p>
        <div class="choose-name__picture"
             :class="'choose-name__'+pic.name">
        </div>
      </div>
    </div>
    <button :disabled="disableBtn"
            class="sk-btn sk-btn--default choose-name__submit-btn"
            @click="sendData">{{ $gettext('Continue') }}</button>
  </form-template>
</template>)

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import WizardFormTemplate from '@/components/wizard_components/WizardFormTemplate';

  export default {
    components: {
      'form-template': WizardFormTemplate
    },
    data() {
      return {
        progressActive: false,
        windowWidth: '',
      };
    },
    computed: {
      ...mapGetters({
        userUid: 'UserInfoStore/userUid',
        userIsNotInterpreter: 'UserInfoStore/userIsNotInterpreter',
        userIsInterpreter: 'UserInfoStore/userIsInterpreter'
      }),
      ...mapState('TTWizardStore', {
        firstName: (state) => state.stepsData.name.firstName,
        firstNameErrors: (state) => state.errors.firstName.errors,
        firstNameErrorsName: (state) => state.errors.firstName.name,
        lastName: (state) => state.stepsData.name.lastName,
        lastNameErrors: (state) => state.errors.lastName.errors,
        lastNameErrorsName: (state) => state.errors.lastName.name,
        avatarLink: (state) => state.stepsData.name.avatarLink,
        avatarFile: (state) => state.stepsData.name.avatarFile,
        avatartErrors: (state) => state.errors.avatar.errors,
        avatartErrorsName: (state) => state.errors.avatar.name,
        avatarRotation: (state) => state.stepsData.name.avatarRotation
      }),
      title() { return this.$gettext('Who are you'); },
      firstNameText() { return this.$gettext('First name'); },
      lastNameText() { return this.$gettext('Last name'); },
      avatarRequirementTitle() {
        const template = this.$gettext('%{domain}s profile picture requirements');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      avatarRequirementDes() {
        return this.$gettext('We need a portrait picture of you that follows the guidelines below. Failing to upload a photo here or on your profile page will result in less or no assignments.');
      },
      disableBtn() {
        return !this.firstName.trim()
          || !this.lastName.trim()
          || !!this.avatartErrors.length;
      },
      profilePicsList() {
        if (this.windowWidth > 600) {
          return [
            {name: 'normal_pic', desc: this.$gettext('Normal')},
            {name: 'drop_shadow_pic', desc: this.$gettext('Drop Shadows')},
            {name: 'background_color_pic', desc: this.$gettext('Background Colors')},
            {name: 'underexposed_pic', desc: this.$gettext('Underexposed')},
            {name: 'overexposed_pic', desc: this.$gettext('Overexposed')},
            {name: 'normal_pic', desc: this.$gettext('Normal')},
            {name: 'toofar_pic', desc: this.$gettext('Too far away')},
            {name: 'tooclose_pic', desc: this.$gettext('Too close')},
            {name: 'unnatural_pic', desc: this.$gettext('Unnatural Post-Processing')},
            {name: 'monochrome_pic', desc: this.$gettext('Monochrome')},
            {name: 'normal_hijab_pic', desc: this.$gettext('Normal')},
            {name: 'shadow_onface_pic', desc: this.$gettext('Shadow across face')},
            {name: 'face_covered_pic', desc: this.$gettext('Face covered')},
            {name: 'with_accessories_pic', desc: this.$gettext('Unaccepted accessories')},
            {name: 'covering_hair_pic', desc: this.$gettext('Hair covering face')}
          ];
        } else {
          return [
            {name: 'normal_pic', desc: this.$gettext('Normal')},
            {name: 'normal_pic', desc: this.$gettext('Normal')},
            {name: 'normal_hijab_pic', desc: this.$gettext('Normal')},
            {name: 'drop_shadow_pic', desc: this.$gettext('Drop Shadows')},
            {name: 'toofar_pic', desc: this.$gettext('Too far away')},
            {name: 'shadow_onface_pic', desc: this.$gettext('Shadow across face')},
            {name: 'background_color_pic', desc: this.$gettext('Background Colors')},
            {name: 'tooclose_pic', desc: this.$gettext('Too close')},
            {name: 'face_covered_pic', desc: this.$gettext('Face covered')},
            {name: 'underexposed_pic', desc: this.$gettext('Underexposed')},
            {name: 'unnatural_pic', desc: this.$gettext('Unnatural Post-Processing')},
            {name: 'with_accessories_pic', desc: this.$gettext('Unaccepted accessories')},
            {name: 'overexposed_pic', desc: this.$gettext('Overexposed')},
            {name: 'monochrome_pic', desc: this.$gettext('Monochrome')},
            {name: 'covering_hair_pic', desc: this.$gettext('Hair covering face')}
          ];
        }
      }
    },
    methods: {
      ...mapMutations('TTWizardStore', [
        'setError',
        'removeOneError',
        'removeErrors',
        'setFirstName',
        'setLastName',
        'setAvatarFile',
        'setAvatarLink',
        'setAvatarRotation'
      ]),
      catchAvatar(eve) {
        const self = this;
        let converterResponse = '';
        this.removeOneError('avatar');
        this.setAvatarRotation(0);

        if (eve.target && eve.target.files[0]) {
          converterResponse = this.$avatarConverter.convertAvatar(this, eve.target.files[0]);
          if (!(converterResponse.then) && Array.isArray(converterResponse)) {
            for (const error of converterResponse) {
              this.setError({
                fieldName: 'avatar',
                errorText: error
              });
            }
          } else {
            converterResponse.then((response) => {
              self.setAvatarLink(response.target.result);
              self.setAvatarFile(eve.target.files[0]);
            });
          }
        }
      },
      rotatePicture() {
        this.setAvatarRotation((this.avatarRotation + 90) % 360);
      },
      isAnyError() {
        this.removeErrors();
        let indicator = false;
        if (!this.firstName.trim()) {
          this.setError({
            fieldName: 'firstName',
            errorText: this.$gettext('First name is required')
          });
          indicator = true;
        } else if (this.firstName.trim().length < 2) {
          this.setError({
            fieldName: 'firstName',
            errorText: this.$gettext('There should be at least 2 characters')
          });
          indicator = true;
        }
        if (!this.lastName.trim()) {
          this.setError({
            fieldName: 'lastName',
            errorText: this.$gettext('Last name is required')
          });
          indicator = true;
        } else if (this.lastName.trim().length < 2) {
          this.setError({
            fieldName: 'lastName',
            errorText: this.$gettext('There should be at least 2 characters')
          });
          indicator = true;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.firstNameErrorsName, errors: this.firstNameErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.lastNameErrorsName, errors: this.lastNameErrors});

        return indicator;
      },
      sendData() {
        if (!this.isAnyError()) {
          this.progressActive = true;

          const form = new FormData();

          form.append('person[uid]', this.userUid);
          form.append('person[first_name]', this.firstName);
          form.append('person[last_name]', this.lastName);
          if (this.avatarFile) {
            form.append('person[avatar]', this.avatarFile);
          }

          this.$store.dispatch('UserInfoStore/setNamesAndAvatar', {
            form,
            firstName: this.firstName,
            lastName: this.lastName,
            avatar: this.avatar
          }).then(() => {
            this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
              let nextPage = '';
              if (this.$cookieManager.getCookie('redirectAfterName')) {
                this.$router.push(this.$makeRoute({name: 'BuyerOneAssignment', params: {id: this.$cookieManager.getCookie('redirectAfterName')}}));
                this.$cookieManager.setCookie('redirectAfterName', '', {path: '/', expires: 2592000});
              } else {
                nextPage = this.userIsNotInterpreter ? 'BuyerWizardEnterPhone' : 'ServerWizardEnterPhone';
                this.$router.push(this.$makeRoute({name: nextPage}));
              }
            });
          });
        }
      }
    },
    mounted() {
      this.windowWidth = window.innerWidth;
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      });
    }
  };
</script>

<style scoped>
  .choose-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 410px;
    max-width: 100%;
    margin: 0 auto;
  }

  .choose-name__inputs-cont {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }

  .choose-name__input {
    width: calc(50% - 10px);
  }

  .choose-name__avatar-label-wrapper {
    position: relative;
    display: block;
    width: 150px;
    margin: 0 auto;
  }

  .choose-name__avatar-label {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  .choose-name__avatar {
    width: 100px;
    height: 100px;
  }

  .choose-name__avatar-label-text {
    display: block;
    width: 100%;
    color: #ff5b24;
    color: var(--color-secondary);
    text-align: center;
  }

  .choose-name__avatar-label-text:focus {
    outline: var(--default-focus-outline);
  }

  .choose-name__rotate-btn {
    position: absolute;
    top: 75px;
    right: 0;
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-image: url(~@assets/imgs/undefined_imgs/rotate_button_default.svg);
    background-image: var(--image-rotate-button);
    background-position: 50% 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .choose-name__rotate-btn:focus {
    box-shadow: var(--default-focus-shadow);
  }

  .choose-name__avatar-errors {
    display: block;
    width: 100%;
    margin-top: 10px;
    padding: 0 25px;
    color: #f04;
    color: var(--color-error);
    font-size: 12px;
    text-align: left;
  }

  .choose-name__submit-btn {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .choose-name__good-pictures {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
    align-items: flex-end;
    max-width: 1200px;
    max-height: 700px;
    margin: 15px 40px;
    padding: 10px;
    border-radius: 10px;
    background-color: #e5ebee;
    box-shadow: 0 0 4px 0 rgba(225, 225, 225, 0.5);
    column-gap: 20px;
  }

  .choose-name__picture {
    height: 80px;
    margin-left: 5px;
    background-position: center;
    background-size: 90% auto;
    background-repeat: no-repeat;
  }

  .choose-name__good-pictures-wrapper {
    justify-content: center;
  }

  .choose-name__picture-title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 11px;
    text-align: center;
  }

  .choose-name__avatar-requirements--desc {
    margin: 10px 0;
  }

  .choose-name__normal_pic {
    background-image: url(~@assets/imgs/wizard_icons/normal_pic.svg);
  }

  .choose-name__drop_shadow_pic {
    background-image: url(~@assets/imgs/wizard_icons/drop_shadow_pic.svg);
  }

  .choose-name__background_color_pic {
    background-image: url(~@assets/imgs/wizard_icons/background_color_pic.svg);
  }

  .choose-name__underexposed_pic {
    background-image: url(~@assets/imgs/wizard_icons/underexposed_pic.svg);
  }

  .choose-name__overexposed_pic {
    background-image: url(~@assets/imgs/wizard_icons/overexposed_pic.svg);
  }

  .choose-name__toofar_pic {
    background-image: url(~@assets/imgs/wizard_icons/toofar_pic.svg);
  }

  .choose-name__tooclose_pic {
    background-image: url(~@assets/imgs/wizard_icons/tooclose_pic.svg);
  }

  .choose-name__unnatural_pic {
    background-image: url(~@assets/imgs/wizard_icons/unnatural_pic.svg);
  }

  .choose-name__monochrome_pic {
    background-image: url(~@assets/imgs/wizard_icons/monochrome_pic.svg);
  }

  .choose-name__normal_hijab_pic {
    background-image: url(~@assets/imgs/wizard_icons/normal_hijab_pic.svg);
  }

  .choose-name__shadow_onface_pic {
    background-image: url(~@assets/imgs/wizard_icons/shadow_onface_pic.svg);
  }

  .choose-name__face_covered_pic {
    background-image: url(~@assets/imgs/wizard_icons/face_covered_pic.svg);
  }

  .choose-name__with_accessories_pic {
    background-image: url(~@assets/imgs/wizard_icons/with_accessories_pic.svg);
  }

  .choose-name__covering_hair_pic {
    background-image: url(~@assets/imgs/wizard_icons/covering_hair_pic.svg);
  }

  @media (min-width: 600px) {
    .choose-name__good-pictures {
      grid-template-columns: repeat(5, 1fr);
    }

    .choose-name__picture {
      height: 150px;
      background-size: 100%;
    }
  }

  @media (max-width: 767px) {
    .choose-name__inputs-cont {
      padding: 0 10px;
    }

    .choose-name__avatar-requirements--desc {
      margin: 10px;
    }
  }
</style>
