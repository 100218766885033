(<template>
  <div :class="{'in-progress': progressActive}"
       class="short-info is-progress-bar">
    <button class="sk-btn sk-btn--white short-info__btn"
            @click="loginAsInterpreter">{{ $gettext('Log in as') }}</button>
    <interpreter-blocked-status v-if="isBlockedByStatus"
                                :interpreter-data="interpreterBlockedStatusData"
                                :hide-button="true"
                                class="short-info__blocked-info" />
    <badges :params="badgesParams"
            class="short-info__badges" />
    <section class="short-info__notes">
      <h1 class="short-info__notes-title">{{ $gettext('Notes') }}</h1>
      <div class="short-info__notes-wrapper">
        <div v-for="(note, index) in notes"
             :key="index"
             class="short-info__note">
          <p class="short-info__note-line">
            <b>{{ $gettext('Author: ') }}</b>
            {{ note.author }}
          </p>
          <p class="short-info__note-line">
            <b>{{ $gettext('Note: ') }}</b>
            {{ note.note }}
          </p>
          <p class="short-info__note-line">
            <b>{{ $gettext('Source: ') }}</b>
            {{ note.source }}
          </p>
        </div>
        <div v-if="!notes.length"
             class="short-info__note-line">{{ $gettext('No notes yet') }}</div>
      </div>
    </section>
    <section class="short-info__comment">
      <sk-textarea :no-description="true"
                   :preselected-value="comment"
                   :placeholder="commentText"
                   :reg-exp="/<\/?[^>]+(>|$)/g"
                   class="short-info__comment-input"
                   @fieldvaluechanged="setComment" />
      <button :disabled="disabledCommentButton"
              class="sk-btn sk-btn--default short-info__comment-btn"
              @click="addInterpreterShortInfo">{{ $gettext('Add comment') }}</button>
    </section>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import helpers from '@/helpers';
  import Badges from '@/components/profile_components/show/Badges';
  import InterpreterBlockedStatus from '@/components/interpreters_components/InterpreterBlockedStatus';

  export default {
    components: {
      'interpreter-blocked-status': InterpreterBlockedStatus,
      badges: Badges
    },
    data() {
      return {
        comment: '',
        progressActive: false
      };
    },
    computed: {
      ...mapState('FAIListStore', {
        interpreter: ({interpreterShortInfo}) => interpreterShortInfo || {}
      }),
      interpreterBlockedStatusData() {
        return {
          ...this.interpreter,
          ...{
            id: this.interpreter.personId || ''
          }
        };
      },
      badges() {
        return this.interpreter.badges || [];
      },
      badgesParams() {
        return {
          badges: this.badges,
          hideTitle: true,
          canEdit: false
        };
      },
      notes() {
        return this.interpreter.notes || [];
      },
      isBlockedByStatus() {
        return this.$isGodmode()
          && helpers.interpreterInfo.іsBlockedInterpreter(this.interpreter);
      },
      commentText() {
        return this.$gettext('Write here ...');
      },
      disabledCommentButton() {
        return this.comment.length < 3;
      }
    },
    methods: {
      setComment(value) {
        this.comment = value;
      },
      addInterpreterShortInfo() {
        const params = {
          note: this.comment
        };

        this.progressActive = true;
        this.$store.dispatch('FAIListStore/addInterpreterShortInfo', {
          uid: this.interpreter.uid,
          params
        })
          .catch((error) => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          })
          .finally(() => {
            this.progressActive = false;
            this.setComment('');
          });
      },
      loginAsInterpreter() {
        const params = {
          uid: this.interpreter.uid,
          admin_uid: this.$store.state.UserInfoStore.adminInfo.uid,
          notifs: false
        };
        this.$store.dispatch('TTAuthStore/getGodModeToken', params)
          .then((data) => {
            const link = helpers.getGodmodeLoginLink({
              userId: this.interpreter.id,
              userUid: this.interpreter.uid,
              token: data.token,
              jobId: this.jobId
            });
            window.open(link, '_blank');
          })
          .catch((error) => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          });
      }
    }
  };
</script>

<style>
  .short-info .badges__one-badge-cont {
    width: 40px;
    height: 40px;
    margin-right: 2px;
    margin-bottom: 15px;
    margin-left: 2px;
  }

  .short-info .badges__badges-wrapper {
    margin: 0 -2px;
  }

  .short-info .badges__one-badge-text {
    display: none;
  }

  .short-info__comment-input .sk-input__textarea {
    min-height: 50px;
  }
</style>

<style scoped>
  .short-info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
  }

  .short-info__comment {
    margin-top: auto;
  }

  .short-info__comment-btn {
    width: auto;
    margin-left: auto;
    padding: 0 20px;
  }

  .short-info__btn {
    align-self: flex-start;
    width: auto;
    min-height: 35px;
    margin-bottom: 15px;
    padding: 0 15px;
  }

  .short-info__blocked-info {
    padding-bottom: 15px;
  }

  .short-info__badges {
    padding: 0;
    border-bottom: 1px solid #e5e5e5;
  }

  .short-info__notes-title {
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
  }

  .short-info__notes {
    margin-bottom: 20px;
  }

  .short-info__note-line {
    margin-top: 10px;
  }

  .short-info__note {
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
  }

  .short-info__note:last-child {
    border-bottom: none;
  }
</style>
