(<template>
  <div class="subtask-info-actions"
       :class="{'in-progress': progressActive}">
    <h4 v-if="uploadAction.visible && isAssignedUser"
        class="subtask-info-actions__upload-title">{{ uploadText }}</h4>
    <div v-if="showAllActions"
         class="subtask-info-actions__btns-wrap">
      <a v-if="showDownload"
         class="subtask-info-actions__btn--download-all"
         :href="downloadLink">{{ downloadAllFilesText }}</a>
      <sk-upload-file v-if="uploadAction.visible"
                      :id="subtask.publicId"
                      :button-text="selectFilesText"
                      :accept="'*'"
                      :class="{'subtask-info-actions__btn--disabled': uploadAction.disabled }"
                      class="subtask-info-actions__btn--upload"
                      @changefileinput="catchAttachment" />
      <div v-if="showConfirmButtons"
           class="subtask-info__confirm-btns">
        <button v-if="showDecline.visible"
                :disabled="showDecline.disabled"
                class="sk-btn--white subtask-info-actions__btn"
                @click="openRejectionModal">{{ declineText }}</button>
        <button v-if="showClaim.visible"
                :disabled="showClaim.disabled"
                class="sk-btn--default subtask-info-actions__btn"
                @click="changeStatusHandler">{{ claimJobText }}</button>
      </div>
    </div>
    <sk-note v-if="isPendingDeadline"
             class="extended-deadline">
      <span v-html="extendedDeadlineText"></span>
      <button class="extended-deadline__edit-btn"
              @click="openEditDeadlineModal">{{ $gettext('Edit') }}</button>
    </sk-note>
    <sk-note v-if="isRejectedDeadline"
             :text="rejectedDeadlineText" />
    <div v-if="showErrors"
         class="sk-input__errors-cont">
      <span v-for="(error, index) in attachmentsErrors"
            :key="index"
            v-html="(attachmentsErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
    </div>
    <div v-for="(attachment, index) in attachments"
         :key="index">
      <div class="subtask-action-attachment__files">
        <p class="subtask-action-attachment__file-name">{{ attachment.name }}</p>
        <button type="button"
                class="subtask-action-attachment__remove-btn"
                @click="removeSelectedAttachment({index, id: attachment.id})"></button>
      </div>
      <p v-if="attachment.error"
         class="subtask-action-attachment__file-error">{{ attachment.error }}</p>
    </div>
    <div v-if="attachments.length"
         class="subtask-info-actions__send-btn">
      <button class="sk-btn--default subtask-info-actions__btn"
              @click="sendAttachments">{{ sendInText }}</button>
    </div>
    <template v-if="subtask.attachments && subtask.attachments.length">
      <template v-if="showAttachedDocuments">
        <h4 class="subtask-info-actions__sent--title">{{ sentInFilesTitle }}</h4>
        <documents-list class="subtask-action__uploaded-files"
                        mode="with-icon"
                        :has-delete="hasDeleteOption"
                        :documents="subtask.attachments"
                        :from-subtask="true"
                        @delete-attachment="deleteConfirmation" />
        <sk-note v-if="showNote"
                 :text="contactPmText"
                 class="subtask-info__note" />
      </template>
      <template v-if="commentSection.visible">
        <h3 class="subtask-info-actions__comment-title">{{ addCommentText }}</h3>
        <p class="subtask-info-actions__comment-label">{{ commentLabel }}</p>
        <sk-textarea
          :preselected-value="comment"
          :placeholder="commentHintText"
          :disabled="commentSection.disabled"
          :reg-exp="/<\/?[^>]+(>|$)/g"
          class="subtask-comment__textarea"
          @fieldvaluechanged="catchComment" />
      </template>
      <div class="subtask-info-actions__review-btn">
        <div v-if="wordCountSection"
             class="subtask-info-actions__word-count-field">
          <p class="subtask-info-actions__word-count-label">{{ wordCountText }}</p>
          <sk-input class="subtask-word-count__input"
                    :placeholder="'0'"
                    :preselected-value="wordCount"
                    :reg-exp="/[^0-9]/g"
                    :mob-numeric-keyboard="true"
                    :validation-name="wordCountErrorsName"
                    @fieldvaluechanged="catchWordCount" />
        </div>
        <button v-if="requestReview.visible"
                :disabled="requestReview.disabled"
                class="sk-btn--default subtask-info-actions__btn subtask-info-actions__btn--request"
                :class="{'subtask-info-actions__btn--disabled': requestReview.disabled }"
                @click="changeStatus('review_requested')">{{ requestReviewText }}</button>
      </div>
      <p v-if="wordCountSection"
         class="subtask-info-actions__word-count-info">{{ wordCountHelpText }}</p>
    </template>
  </div>
</template>)

<script>
  import DocumentsList from '@/components/assignment_components/one_assignment/DocumentsList';
  import {mapGetters} from 'vuex';
  import constants from '@/modules/constants';

  export default {
    components: {
      'documents-list': DocumentsList,
    },
    props: {
      targetLanguage: {
        type: String,
        default: '-',
      },
      subtask: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        comment: '',
        wordCount: '',
        rejectionReason: '',
        attachments: [],
        fileInputReset: false,
        attachmentsErrors: [],
        progressActive: false,
        wordCountErrors: [],
        wordCountErrorsName: 'wordCountErrorsName'
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      ...mapGetters('OneProjectStore', ['project', 'isFinishedProject']),
      showErrors() { return this.attachmentsErrors.length; },
      sentInFilesTitle() { return this.$pgettext('translation', 'Sent in files'); },
      addCommentText() { return this.$pgettext('translation', 'Submit for review and finalize'); },
      uploadText() {
        const template = this.$pgettext('translation', 'Upload file for language: %{ lang }');
        return this.$gettextInterpolate(template, {lang: this.targetLanguage});
      },
      downloadAllFilesText() { return this.$pgettext('translation', 'Download all files'); },
      selectFilesText() { return this.$pgettext('translation', 'Select file(s)'); },
      requestReviewText() { return this.$pgettext('translation', 'Click here to complete'); },
      declineText() { return this.$pgettext('translation', 'Decline'); },
      claimJobText() { return this.$pgettext('translation', 'Claim Assignment'); },
      wordCountText() { return this.$pgettext('translation', 'How many words will you invoice for?'); },
      wordCountHelpText() { return this.$pgettext('translation', 'Please provide a correct word count. If the word count is lower than 300 words, a flat rate will be used.'); },
      showDownload() {
        const projectFiles = this.project.attachments && this.project.attachments.length;
        if (projectFiles) {
          if (this.subtask.status == 'accepted' || this.subtask.status == 'in-progress' || this.subtask.status == 'review_requested' || this.subtask.status == 'changes_required') {
            return true;
          }
        }
        return false;
      },
      commentHintText() { return this.$pgettext('translation', 'Write here...'); },
      commentLabel() { return this.$pgettext('translation', 'Comment for project manager (optional)'); },
      sendInText() { return this.$pgettext('translation', 'Send In'); },
      contactPmText() { return this.$pgettext('translation', 'If you noticed a mistake please contact your project manager'); },
      requestedInternalDeadline() {
        const dateObject = this.$moment(this.subtask.deadlineChangeRequest.requestedInternalDeadline);
        return dateObject.format('D MMM');
      },
      extendedDeadlineText() {
        const template = this.$gettext('You proposed an extended deadline <b>%{deadline}</b> and your proposal is under review.');
        return this.$gettextInterpolate(template, {deadline: this.requestedInternalDeadline});
      },
      rejectedDeadlineText() {
        const template = this.$gettext('Your proposed deadline <b>%{deadline}</b> was rejected by customer service.');
        return this.$gettextInterpolate(template, {deadline: this.requestedInternalDeadline});
      },
      isAssignedUser() { return this.subtask.seller.uid === this.userUid; },
      isPendingDeadline() { return this.subtask.deadlineChangeRequest?.status === 'pending'; },
      isRejectedDeadline() { return this.subtask.deadlineChangeRequest?.status === 'rejected'; },
      downloadLink() {
        return `${constants.API_PATH}/v1/subtasks/${this.subtask.id}/attachments_zip?zip_file_name=${this.subtask.publicId}-MySalita&authorization_token=${this.$fetcher.userToken}`;
      },
      isSellerAssigned() {
        return this.subtask.seller !== null || Object.keys(this.subtask.seller).length !== 0;
      },
      isSuitableSeller() { return this.subtask.isSuitable; },
      status() { return this.subtask.status; },
      isPublished() { return this.subtask.status === 'published'; },
      isProactiveAccessible() { return this.subtask.status === 'proactive_access'; },
      showConfirmButtons() {
        const showButtons = this.showDecline.visible || this.showClaim.visible;
        return showButtons && !this.isPendingDeadline && !this.isRejectedDeadline;
      },
      showClaim() {
        const visibleOn = ['invited', 'proactive_access'];
        // subtask publish but seller is assigned means this is direct subtask
        if (this.isPublished
          && (this.subtask.proactiveAccessibility || this.isSellerAssigned)) visibleOn.push('published');
        return {
          visible: visibleOn.includes(this.status) && this.isSuitableSeller,
          disabled: false
        };
      },
      showDecline() {
        const visibleOn = ['invited', 'rejected'];
        const disabledOn = ['rejected'];
        if (this.isPublished
          && !this.subtask.proactiveAccessibility
          && this.isSellerAssigned) {
          visibleOn.push('published');
        }
        return {
          visible: visibleOn.includes(this.status),
          disabled: disabledOn.includes(this.status)
        };
      },
      showAllActions() {
        const notVisibleOn = ['finished', 'cancelled'];
        return !notVisibleOn.includes(this.status) && (this.isAssignedUser || this.isPublished || this.isProactiveAccessible);
      },
      uploadAction() {
        const notVisibleOn = ['finished', 'cancelled', 'review_requested', 'rejected', 'proactive_access', 'invited', 'applied', 'published'];
        const disabledOn = ['invited', 'cancelled', 'applied', 'published'];
        return {
          visible: !notVisibleOn.includes(this.status),
          disabled: disabledOn.includes(this.status)
        };
      },
      requestReview() {
        const notVisibleOn = ['invited', 'applied', 'rejected', 'review_requested', 'finished'];
        return {
          visible: !notVisibleOn.includes(this.status),
          disabled: false
        };
      },
      commentSection() {
        const notVisibleOn = ['review_requested', 'rejected', 'finished'];
        return {
          visible: !notVisibleOn.includes(this.status) && this.isAssignedUser
        };
      },
      showNote() {
        return this.status === 'review_requested' && this.isAssignedUser;
      },
      showAttachedDocuments() {
        return (this.status === 'finished' || this.isAssignedUser) && !this.isFinishedProject;
      },
      hasDeleteOption() {
        const notVisibleOn = ['finished', 'review_requested'];
        return !notVisibleOn.includes(this.status) && this.isAssignedUser;
      },
      wordCountSection() {
        return this.attachments.length || (this.showAttachedDocuments && this.requestReview.visible);
      }
    },
    methods: {
      downloadFiles() {
        this.$fetcher.downloadAllFiles(this.subtask.id, `${this.subtask.publicId}-MySalita.zip`);
      },
      openEditDeadlineModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'edit-deadline-modal',
          width: 540,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Edit proposal'),
            context: this,
          }
        });
      },
      openRejectionModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'subtask-rejection-modal',
          width: 540,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Reject invitation'),
            deadline: this.subtask.internalDeadline,
            context: this,
          }
        });
      },
      setRejectionReason(value) {
        this.rejectionReason = value;
        this.changeStatus('rejected');
      },
      requestNewDeadline(newDeadline) {
        const params = {
          subtask_id: this.subtask.id,
          original_internal_deadline: this.subtask.internalDeadline,
          requested_internal_deadline: newDeadline
        };

        this.$store.dispatch('OneProjectStore/requestNewDeadline', params).then(() => {
          this.progressActive = false;
          window.location.reload();
        }).catch((error) => {
          this.progressActive = false;
          if (error.data?.errors?.includes('Deadline cannot be changed in cancelled status')) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              title: this.$gettext('Order cancelled'),
              text: this.$pgettext('translation', 'Sorry, order you are trying to apply to is no longer available or suitable for you. Please visit your dashboard to claim other available assignments.')
            });
          } else {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal'
            });
          }
        });
      },
      changeStatusHandler() {
        if (this.status === 'proactive_access') {
          this.showProactiveConfirmationModal();
        } else {
          this.changeStatus('accepted');
        }
      },
      showProactiveConfirmationModal() {
        const deadlineTime = this.subtask.internalDeadline
          ? this.$moment(this.subtask.internalDeadline).format('DD.MM.YY')
          : null;

        const template = this.$gettext('You accept the deadline: %{deadline}');
        const modalContent = `<p>
          ${this.$gettext('By continuing you confirm that:')}
          <ul class="confirmation-list">
            <li>${this.$gettext('You have reviewed the information provided in this assignment')}</li>
            <li>${this.$gettext('You are able to complete this assignment')}</li>
            ${deadlineTime ? `<li>${this.$gettextInterpolate(template, {deadline: deadlineTime})}</li>` : ''}
            <li>${this.$gettext('Salita reserves the right to withdraw the assignment if the translator does not comply with the points above')}</li>
          </ul>
        </p>`;

        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Claim assignment'),
            text: modalContent,
            context: this,
            modalCallback: this.changeStatus,
            callbackParams: 'accepted',
            btnTexts: {
              actionBtnText: this.$gettext('Confirm and claim'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      changeStatus(status) {
        let params = {};

        if (this.wordCountSection && !this.wordCount) {
          this.wordCountErrors = [];
          this.wordCountErrors.push(this.$gettext('Word count is required for invoices'));
          this.$store.commit('ErrorsStore/setError', {name: this.wordCountErrorsName, errors: this.wordCountErrors});
          return;
        }

        if (status === 'review_requested') {
          params = {
            id: this.subtask.id,
            subtask: {
              status: status,
              review_comment_from_seller: this.comment,
              count_of_words: this.wordCount
            }
          };
        } else if (status === 'rejected') {
          params = {
            id: this.subtask.id,
            subtask: {
              status: status,
              review_comment_from_seller: this.comment,
              rejection_reason: this.rejectionReason
            }
          };
        } else {
          params = {
            id: this.subtask.id,
            subtask: {
              status: status,
              review_comment_from_seller: this.comment
            }
          };
        }
        this.progressActive = true;
        this.$store.dispatch('OneProjectStore/changeSubtaskStatus', params).then(() => {
          this.progressActive = false;
        }).catch((error) => {
          this.progressActive = false;
          const subtaskErrors = error.data?.errors?.subtask;
          if (error.data.errors === 'Cannot request review' || subtaskErrors?.includes('Cannot request review')) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              title: this.$gettext('Wrong deadline'),
              text: this.$pgettext('translation', 'This order has been finished by your manager. If you still need to submit something please contact manager or our support team.')
            });
          } else if (subtaskErrors?.includes('Cannot accept') || subtaskErrors?.includes('Cannot be rejected')) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              title: this.$gettext('Order cancelled'),
              text: this.$pgettext('translation', 'Sorry, order you are trying to apply to is no longer available or suitable for you. Please visit your dashboard to claim other available assignments.')
            });
          } else {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal'
            });
          }
        });
      },
      sendAttachments() {
        const params = {
          id: this.subtask.id,
          attachments: this.attachments
        };
        this.progressActive = true;
        this.$store.dispatch('OneProjectStore/sendAttachments', params).then(() => {
          this.attachments.splice(0, this.attachments.length);
          this.progressActive = false;
        }).catch((error) => {
          this.progressActive = false;
          if (error?.data?.errors.includes('The filename is not unique within attachments group')) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('This file has already been uploaded')
            });
          } else {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }
        });
      },
      deleteAttachment(file) {
        const params = {
          id: file.id,
          subtask_id: this.subtask.id
        };
        this.progressActive = true;
        this.$store.dispatch('OneProjectStore/deleteAttachment', params).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
          this.$store.commit('ModalStore/setModal', {
            component: 'error-modal'
          });
        });
      },
      deleteConfirmation(file) {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          data: {
            title: this.$pgettext('translation', 'Delete attached file(s)?'),
            context: this,
            modalCallback: this.deleteAttachment,
            callbackParams: file,
            text: this.$pgettext('translation', 'Are you sure you want to delete this attached file?'),
            btnTexts: {
              actionBtnText: this.$pgettext('translation', 'Delete')
            }
          }
        });
      },
      catchComment(value) {
        this.comment = value;
      },
      catchWordCount(value) {
        this.wordCount = 1 * value;
      },
      catchAttachment(target) {
        this.removeErrors();
        if (target.files && target.files.length) {
          this.setAttachments(target);
        }
      },
      setAttachments({files}) {
        if (files && files.length) {
          for (let i = 0; i < files.length; i += 1) {
            // block duplicate file name attachment selection
            const isDuplicateFile = !!this.attachments?.find((file) => file.name == files[i]?.name);
            if (!isDuplicateFile) {
              this.attachments.push({
                file: files[i],
                name: files[i].name,
                error: false
              });
            }
          }
          this.fileInputReset = !this.fileInputReset;
        }
      },
      removeSelectedAttachment({index, id}) {
        if (id) {
          this.attachments = this.attachments.map((attachment) => {
            return attachment.id == id
              ? {
                ...attachment,
                deleted: true
              }
              : attachment;
          });
        } else {
          this.attachments.splice(index, 1);
        }
      },
      validateAttachment() {
        let isValidForm = true;
        if (!this.attachments.length) {
          isValidForm = false;
          this.attachmentsErrors.push('Please upload at least one file.');
        }
        return isValidForm;
      },
      removeErrors() {
        this.attachmentsErrors = [];
      }
    },
    created() {
      this.comment = this.subtask?.reviewCommentFromSeller;
    }
  };
</script>

<style>
.subtask-info-actions__btn--upload label {
  min-width: 190px;
  padding-left: 10px;
  background-color: #ff5b24 !important;
  color: #fff !important;
}

.subtask-info-actions__btn--upload label::before {
  width: 0;
  height: 0;
}

.subtask-info-actions__btn--disabled,
.subtask-info-actions__btn--disabled label {
  border-color: transparent !important;
  background-color: #b2b2b2 !important;
  color: #585858 !important;
  pointer-events: none;
}

.subtask-action__uploaded-files .icon {
  top: 10px;
  left: 8px;
}

.subtask-action__uploaded-files .assignment-documents__item--icon {
  margin: 10px 0;
  padding: 10px 0 10px 30px;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
}

.subtask-action__uploaded-files .assignment-documents__link {
  font-size: 14px;
}

.confirmation-list {
  margin: 20px 0 0 32px;
}
</style>

<style scoped>
.subtask-info-actions__btn--download-all {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  margin: 0 10px 10px 0;
  padding: 0 20px 0 20px;
  border-radius: 3px;
  background-color: #ff5b24;
  color: #fff;
}

.subtask-info-actions__send-btn,
.subtask-info-actions__review-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 7px;
}

.subtask-comment__textarea {
  height: 80px;
  margin-bottom: 20px;
}

.subtask-action-attachment__files {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 25px;
  background-image: var(--image-clipboard-icon);
  background-position: 0 50%;
  background-size: 14px auto;
  background-repeat: no-repeat;
}

.extended-deadline__edit-btn {
  text-decoration: underline;
}

.subtask-action-attachment__remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 15px;
  background-image: var(--image-trash-icon);
  background-position: 50% 50%;
  background-size: 12px auto;
  background-repeat: no-repeat;
}

.subtask-action-attachment__remove-btn:active {
  background-color: #e1d7eb;
}

.subtask-action-attachment__file-error {
  width: 100%;
  margin-top: 2px;
  padding-bottom: 10px;
  color: #f04;
  font-size: 12px;
}

.subtask-info-actions__upload-title {
  margin: 8px 0;
}

.subtask-info-actions__comment-title {
  margin: 13px 0 15px;
}

.subtask-info-actions__word-count-field {
  display: flex;
  flex: 1;
}

.subtask-info-actions__word-count-label {
  line-height: 16px;
}

.subtask-word-count__input {
  margin-right: 10px;
  margin-bottom: 0;
}

.subtask-info-actions__word-count-info {
  margin-top: 7px;
  font-size: 12px;
}

.subtask-info-actions__btns-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.subtask-info-actions__btn {
  height: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  border: none;
  border-radius: 3px;
  background-clip: border-box;
  outline: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.subtask-info-actions__btn--download {
  margin-right: 1rem;
  padding-right: 20px;
  padding-left: 20px;
  border: none;
  border-radius: 3px;
  background-clip: border-box;
  outline: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.subtask-info-actions__btn + .subtask-info-actions__btn {
  margin-left: 16px;
}

.subtask-info-actions__btn--request {
  margin-left: auto;
}

.subtask-info__confirm-btns {
  display: flex;
  flex: 0 1 auto;
  align-content: center;
  margin-left: auto;
}

.subtask-info__note {
  margin: 5px 0;
}
</style>
