(<template>
  <section class="prof-courses-modal__content-wrapper">
    <sk-input v-for="(course, index, key) in copiedCourses"
              :id="index"
              :key="key"
              :description="text1"
              :autofocus="key == 0"
              :preselected-value="course"
              :margin-bottom="true"
              :placeholder="text1"
              :validation-name="courseValidationName"
              :reg-exp="/<\/?[^>]+(>|$)/g"
              @fieldvaluechanged="catchCourse" />
    <button class="sk-btn sk-btn--white prof-courses-modal__add-course-btn"
            @click="increaseNewCoursesCount">{{ $gettext("Add more courses") }}</button>
    <submit-btns :submit-handler="submitHandler"
                 :cancel-handler="closeModal" />
  </section>
</template>)

<script>
  import SubmitBtns from '@/components/profile_components/edit/ModalSubmitBtns';

  export default {
    components: {
      'submit-btns': SubmitBtns
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        newCoursesCount: this.data.data.courses.length || 1,
        copiedCourses: {},
        courseValidationName: 'courseTitle',
        courseTitleErrors: []
      };
    },
    computed: {
      text1() { return this.$gettext('Course title'); }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      catchCourse(value, index) {
        this.copiedCourses[index] = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      submitHandler() {
        const finalArr = [];
        this.courseTitleErrors = [];
        const form = new FormData();

        for (const course of Object.keys(this.copiedCourses)) {
          if (this.copiedCourses[course].trim()) {
            finalArr.push(this.copiedCourses[course]);
          }
        }

        if (!finalArr.length) {
          this.courseTitleErrors.push(this.$gettext('Must be filled'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.courseValidationName, errors: this.courseTitleErrors});

        if (!this.courseTitleErrors.length) {
          form.append('interpreter[courses]', finalArr);
          this.$emit('startprogress');
          this.data.context.updateCourses(form);
          this.data.context.$on('updatefailed', this.stopProgress);
          this.data.context.$on('updatesuccess', this.closeModal);
        }
      },
      // makeUniqueKey() {
      //   return `${+new Date()}_!_${Math.random()}`;
      // },
      increaseNewCoursesCount() {
        this.newCoursesCount += 1;
        this.$set(this.copiedCourses, this.newCoursesCount, '');
      }
    },
    beforeMount() {
      if (this.data.data.courses.length) {
        for (let i = 0, lim = this.data.data.courses.length; i < lim; i += 1) {
          this.$set(this.copiedCourses, i, this.data.data.courses[i]);
        }
      } else {
        this.$set(this.copiedCourses, 0, '');
      }
    }
  };
</script>

<style scoped>
  .prof-courses-modal__content-wrapper {
    display: block;
    width: 100%;
    padding: 35px;
    padding-top: 10px;
  }

  .prof-courses-modal__add-course-btn {
    display: inline-block;
    width: auto;
    margin-top: 10px;
    padding: 0 10px;
  }
</style>
