<template>
  <div class="fai-filters">
    <p class="fai-filters__title">{{ $gettext('Filter by:') }}</p>
    <sk-select :items-list="suitableList"
               :disabled="isDisabledFields"
               :no-description="true"
               :default-value="'--:--'"
               :preselected-value="qualification"
               :class="{'fai-filters__select--disabled': isDisabledFields}"
               class="fai-filters__select fai-filters__select--qualification"
               @csvaluechanged="setQualification" />
    <sk-select :items-list="distancesList"
               :disabled="isDisabledFields"
               :no-description="true"
               :default-value="'--:--'"
               :preselected-value="distance"
               :class="{'fai-filters__select--disabled': isDisabledFields}"
               class="fai-filters__select fai-filters__select--distance"
               @csvaluechanged="setDistance" />
    <sk-select :items-list="suitableList"
               :disabled="isDisabledFields"
               :no-description="true"
               :default-value="'--:--'"
               :preselected-value="gender"
               :class="{'fai-filters__select--disabled': isDisabledFields}"
               class="fai-filters__select fai-filters__select--sex"
               @csvaluechanged="setGender" />
    <div class="fai-filters__checkboxes-wrapper">
      <div class="fai-filters__column">
        <sk-checkbox :item-text="showAllDescription"
                     :disabled="isDisabledFields"
                     :preselected-value="showAll"
                     class="fai-filters__checkbox"
                     @checkboxvaluechanged="setShowAll" />
        <sk-checkbox :item-text="showInvitationQueueDescription"
                     :disabled="showSuitableNotInvited"
                     :preselected-value="showInvitationQueue"
                     class="fai-filters__checkbox"
                     @checkboxvaluechanged="setShowInvitationQueue" />
      </div>
      <div class="fai-filters__column">
        <sk-checkbox :item-text="showSuitableNotInvitedDescription"
                     :disabled="showInvitationQueue"
                     :preselected-value="showSuitableNotInvited"
                     class="fai-filters__checkbox"
                     @checkboxvaluechanged="setShowSuitableNotInvited" />
      </div>
    </div>
    <div class="fai-filters__search--name"
         @keydown.enter="searchInterpreters">
      <sk-input :placeholder="searchPlaceholder"
                :preselected-value="name"
                :input-icon="'search'"
                :reg-exp="/<\/?[^>]+(>|$)/g"
                @fieldvaluechanged="setName" />
    </div>
    <div v-if="nextInvitationTime"
         class="fai-filters__note">{{ nextInvitationText }}</div>
  </div>
</template>

<script>
  import {mapMutations, mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('OneAssignmentStore', {
        nextInvitationTime: ({storeJobObj}) => storeJobObj?.processRequirement?.nextInvitationTime || ''
      }),
      ...mapState('FAIFiltersStore', {
        qualification: ({qualification}) => qualification,
        gender: ({gender}) => gender,
        name: ({name}) => name,
        distance: ({distance}) => distance,
        showAll: ({showAll}) => showAll,
        showInvitationQueue: ({showInvitationQueue}) => showInvitationQueue,
        showSuitableNotInvited: ({showSuitableNotInvited}) => showSuitableNotInvited
      }),
      isDisabledFields() {
        return this.showInvitationQueue || this.showSuitableNotInvited;
      },
      suitableList() {
        return [
          {id: 'suitable', name: this.$gettext('Suitable')},
          {id: 'all', name: this.$gettext('All')}
        ];
      },
      distancesList() {
        return [
          {id: 30, name: this.$gettext('30 km ABL, FHOS')},
          {id: 70, name: this.$gettext('70 km Helseanbud')},
          {id: 100, name: this.$gettext('100 km Privat kunder uten avtale')},
          {id: 150, name: this.$gettext('150 km')},
          {id: 200, name: this.$gettext('200 km HERSL')},
          {id: 300, name: this.$gettext('300 km Politi')},
          {id: 'all', name: this.$gettext('All')}
        ];
      },
      nextInvitationText() {
        const template = this.$gettext('Next invitation will occur at %{time}');
        const time = this.$moment(this.nextInvitationTime).format('HH:mm');

        return this.$gettextInterpolate(template, {time});
      },
      nextInvitationTimeIsPassed() {
        return this.nextInvitationTime ? this.$moment().isAfter(this.$moment(this.nextInvitationTime)) : false;
      },
      showAllDescription() {
        return this.$gettext('Show everyone');
      },
      showInvitationQueueDescription() {
        return this.$gettext('Show invitation queue');
      },
      showSuitableNotInvitedDescription() {
        return this.$gettext('Show suitable not invited');
      },
      searchPlaceholder() {
        return this.$gettext('Search for the interpreter\'s name');
      }
    },
    watch: {
      nextInvitationTimeIsPassed(newVal) {
        if (newVal) this.$emit('refetchassignment');
      }
    },
    methods: {
      ...mapMutations('FAIFiltersStore', [
        'setQualificationFilter',
        'setGenderFilter',
        'setNameFilter',
        'setDistanceFilter',
        'setShowAllFilter',
        'setShowInvitationQueueFilter',
        'setShowSuitableNotInvitedFilter'
      ]),
      setGender(value) {
        if (this.gender !== value) {
          this.setGenderFilter(value);
          this.filterInterpreters();
        }
      },
      setName(value) {
        if (this.name !== value) {
          this.setNameFilter(value);
        }
      },
      setDistance(value) {
        if (this.distance !== value) {
          this.setDistanceFilter(value);
          this.filterInterpreters();
        }
      },
      setQualification(value) {
        if (this.qualification !== value) {
          this.setQualificationFilter(value);
          this.filterInterpreters();
        }
      },
      setShowAll(value) {
        this.setShowAllFilter(value);
        this.filterInterpreters();
      },
      setShowInvitationQueue(value) {
        this.setShowInvitationQueueFilter(value);
        this.filterInterpreters();
      },
      setShowSuitableNotInvited(value) {
        this.setShowSuitableNotInvitedFilter(value);
        this.filterInterpreters();
      },
      filterInterpreters() {
        this.$emit('filterinterpreters');
      },
      searchInterpreters(showInvitationQueue) {
        this.name ? this.$emit('searchinterpreters', showInvitationQueue) : this.filterInterpreters();
      }
    }
  };
</script>

<style>
  .fai-filters__select .sk-select {
    width: 35px;
    border: none;
  }

  .fai-filters__select .sk-select__arrow {
    display: none;
  }

  .fai-filters__select .sk-select__value {
    position: absolute;
    top: 100%;
    left: 50%;
    width: auto;
    max-width: 45px;
    height: auto;
    padding: 0;
    font-size: 10px;
    line-height: initial;
    transform: translateX(-50%);
  }

  .fai-filters__select .sk-select__list {
    width: max-content;
    min-width: 75px;
    padding: 0;
  }

  .fai-filters__checkbox.is-disabled {
    opacity: 0.3;
  }

  .fai-filters__select--disabled .sk-select.is-disabled {
    color: #000;
  }
</style>

<style scoped>
  .fai-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 10px 15px;
    background-color: white;
  }

  .fai-filters__title {
    margin-top: 10px;
  }

  .fai-filters__select {
    margin: 0 12px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .fai-filters__select--name {
    min-width: 50px;
  }

  .fai-filters__search--name {
    align-self: flex-end;
    max-width: 300px;
    margin: auto 0 auto auto;
    padding-top: 20px;
  }

  .fai-filters__select--sex {
    background-image: url(~@assets/imgs/fai_filters_icons/gender.svg);
    background-size: 15px;
  }

  .fai-filters__select--distance {
    background-image: url(~@assets/imgs/fai_filters_icons/distance.svg);
    background-size: 35px;
  }

  .fai-filters__select--qualification {
    background-image: url(~@assets/imgs/fai_filters_icons/qualification.svg);
    background-size: 30px;
  }

  .fai-filters__select--status {
    background-image: url(~@assets/imgs/fai_filters_icons/status.svg);
    background-size: 18px;
  }

  .fai-filters__select--disabled {
    opacity: 0.3;
  }

  .fai-filters__note {
    align-self: flex-end;
    margin-left: auto;
    padding-top: 10px;
    padding-left: 10px;
    font-weight: bold;
    font-size: 12px;
    text-align: right;
  }

  .fai-filters__checkboxes-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .fai-filters__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
  }

  .fai-filters__checkbox {
    width: auto;
    margin-top: 5px;
    font-size: 12px;
  }
</style>
