(<template>
  <article class="message">
    <div class="message__author-info-wrapper">
      <tt-avatar :avatar="adminInfo.avatar"
                 class="message__avatar" />
      <p class="message__name">{{ adminInfo.name }}</p>
    </div>
    <sk-textarea :no-description="true"
                 :preselected-value="message"
                 :placeholder="messagePlaceholder"
                 :reg-exp="/<\/?[^>]+(>|$)/g"
                 class="message__textarea"
                 @fieldvaluechanged="setMessage" />
    <button :disabled="!message"
            class="sk-btn sk-btn--default message__btn"
            @click="saveMessage">{{ $gettext('Add comment') }}</button>
  </article>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('AdminChatStore', {
        adminInfo: ({adminInfo}) => adminInfo || {},
        message: ({message}) => message
      }),
      messagePlaceholder() { return this.$gettext('Write here...'); }
    },
    methods: {
      ...mapMutations('AdminChatStore', ['setMessage']),
      saveMessage() {
        this.$emit('sendmessage', this.message);
        this.setMessage('');
      }
    }
  };
</script>

<style scoped>
  .message {
    display: block;
    width: 100%;
    padding: 0 15px 20px;
  }

  .message__author-info-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .message__avatar {
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .message__name {
    font-weight: bold;
    font-size: 15px;
  }

  .message__textarea {
    height: 100px;
    margin-bottom: 5px;
  }

  .message__btn {
    width: auto;
    margin-left: auto;
    padding: 0 15px;
  }
</style>
