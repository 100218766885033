<template>
  <section class="file-selector">
    <base-popover
      v-if="isOpen"
      v-click-outside="closePopover"
      class="file-selector-popup"
      :popover-options="popoverOptions"
      @closePopover="closePopover">
      <div class="file-selector-popup__header">
        <h2 class="file-selector-popup__title">{{ $gettext('Attach document') }}</h2>
      </div>
      <div class="file-selector-popup__tabs">
        <button v-for="tab in allTabs"
                :key="tab.tab"
                :class="{'is-active': tab.id === currentTab}"
                class="tab"
                type="button"
                @click="tab.onClick ? tab.onClick(tab.id) : ''">{{ tab.title }}</button>
      </div>
      <div class="is-progress-bar"
           :class="{'in-progress': progressActive}">
        <!----  Existing Files List ---->
        <div v-if="isDocumentsTab"
             class="file-selector-popup__search-wrapper">
          <sk-input :placeholder="searchPlaceholder"
                    :preselected-value="searchKey"
                    :input-icon="'search'"
                    :reg-exp="/<\/?[^>]+(>|$)/g"
                    class="file-selector-popup__search"
                    @fieldvaluechanged="searchDocuments"
                    @enterpressed="searchDocuments" />
          <div class="file-selector-popup__files-wrapper">
            <attached-files-list :files-list="allDocuments"
                                 :selected-files="selectedFiles"
                                 :is-single="isSingleSelect"
                                 @selected="catchSelection"
                                 @unselected="catchUnSelection" />
          </div>
        </div>
        <!----  Upload New Files ---->
        <template v-if="isUploadTab">
          <drag-drop-attachment id="fileSelectorModal"
                                class="file-selector-popup__selector"
                                :attachments="attachments"
                                :attachments-errors="attachmentsErrors"
                                :subtitle="subtitleText"
                                :is-single="isSingleSelect"
                                @attachmentchanged="catchAttachment"
                                @attachmentdeleted="catchDelete" />
        </template>
      </div>
      <div class="file-selector-popup__btns-wrapper">
        <button class="sk-btn sk-btn--white sk-btn--borderless file-selector-popup__btn"
                @click.prevent.stop="closePopover">{{ $gettext('Cancel') }}</button>
        <button class="sk-btn sk-btn--default file-selector-popup__btn"
                @click.prevent.stop="attachFiles">{{ $gettext('Attach') }}</button>
      </div>
    </base-popover>
    <slot name="trigger">
    </slot>
  </section>
</template>

<script>
  import DragDropAttachment from '@/components/shared_components/DragDropAttachment';
  import AttachedFilesList from '@/components/documents/file_selector/AttachedFilesList';
  import BasePopover from '@/components/shared_components/BasePopover';
  import constants from '@/modules/constants';
  import {mapGetters, mapMutations, mapState} from 'vuex';

  export default {
    components: {
      'drag-drop-attachment': DragDropAttachment,
      'attached-files-list': AttachedFilesList,
      'base-popover': BasePopover
    },
    props: {
      isSingleSelect: {
        type: Boolean,
        default: false
      },
      isOpen: {
        type: Boolean,
        default: false
      },
      popoverOptions: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        searchKey: '',
        currentTab: 'documents',
        isPopoverVisible: false,
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('DocumentCenterStore', [
        'attachments'
      ]),
      ...mapState('DocumentCenterStore', {
        selectedFiles: (state) => state.selectedFiles || [],
        allDocuments: (state) => state.allDocuments.documents || [],
        attachmentsErrors: (state) => state.errors.attachments.errors || []
      }),
      isDocumentsTab() { return this.currentTab === 'documents'; },
      isUploadTab() { return this.currentTab === 'upload'; },
      searchPlaceholder() { return this.$gettext('Search...'); },
      subtitleText() { return this.$gettext('All .docx, .xlsx, .jpg, .png and .pdf files up to 50 MB are supported.'); },
      allTabs() {
        return [
          {
            id: 'documents',
            title: this.$pgettext('translation', 'Documents'),
            onClick: this.setCurrentTab,
            condition: true
          },
          {
            id: 'upload',
            title: this.$pgettext('translation', 'Upload'),
            onClick: this.setCurrentTab,
            condition: true
          }
        ];
      },
      isDocumentsEmpty() { return this.allDocuments && !this.allDocuments.length; }
    },
    watch: {
      isOpen(newValue) {
        if (newValue === true && this.isDocumentsEmpty) this.fetchDocuments();
        if (newValue === true) this.clearSelectedFiles();
      },
    },
    methods: {
      ...mapMutations('DocumentCenterStore', [
        'setAttachments',
        'deleteAttachment',
        'setError',
        'removeErrors',
        'catchSelection',
        'catchUnSelection',
        'clearStore',
        'clearSelectedFiles',
      ]),
      catchAttachment(target) {
        // block multiple selection for single select
        if (this.isSingleSelect && this.attachments && this.attachments.length) return '';
        const dt = new DataTransfer();
        let fileList = target;
        let targetFiles = '';
        if (this.isSingleSelect) {
          dt.items.add(target.files[0]);
          targetFiles = dt.files;
          fileList = dt;
        } else targetFiles = target.files;
        this.removeErrors();
        if (this.validateFiles(fileList) && targetFiles && targetFiles.length) {
          this.setAttachments(fileList);
        }
      },
      validateFiles(dataTransfer) {
        const files = dataTransfer.files;
        let isValidForm = true;
        if (files && files.length) {
          const fileType = files[0].type;
          const fileSize = files[0].size;
          const allowedFileTypes = constants.DOCUMENT_CENTER_ALLOWED_FILE_TYPES;
          if (!allowedFileTypes.includes(fileType)) {
            isValidForm = false;
            this.setError({
              fieldName: 'attachments',
              errorText: this.$gettext('Invalid file type')
            });
          }
          if (fileSize > constants.DOCUMENT_CENTER_MAX_FILE_SIZE) {
            isValidForm = false;
            this.setError({
              fieldName: 'attachments',
              errorText: this.$gettext('File size can\'t be more than 20 megabytes.')
            });
          }
        }

        return isValidForm;
      },
      attachFiles() {
        if (this.isDocumentsTab) {
          this.$emit('attachexistingfiles', this.selectedFiles);
          this.closePopover();
        } else {
          const form = new FormData();
          for (const file of this.attachments) {
            form.append('document[file]', file.file);
          }
          this.progressActive = true;
          this.$store.dispatch('DocumentCenterStore/addDocument', form).then((newDocument) => {
            this.progressActive = false;
            this.$emit('attachnewfiles', newDocument);
            this.closePopover();
          }).catch(() => {
            this.progressActive = false;
          });
        }
      },
      catchDelete({index, id}) {
        this.deleteAttachment({index, id});
        this.removeErrors();
      },
      setCurrentTab(tab) {
        this.currentTab = tab;
      },
      closePopover() {
        this.clearStore();
        this.searchKey = '';
        this.$emit('close');
      },
      searchDocuments(value) {
        clearInterval(this.requestTimer);
        this.searchKey = value;
        this.requestTimer = setTimeout(() => {
          this.fetchDocuments();
        }, 500);
      },
      fetchDocuments() {
        const params = {
          items: '25'
        };
        if (this.searchKey) params['s[filename_search_value_cont]'] = this.searchKey;
        this.progressActive = true;
        this.$store.dispatch('DocumentCenterStore/getDocuments', params).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      }
    },
    mounted() {
      if (this.isDocumentsEmpty) this.fetchDocuments();
    },
  };
</script>

<style>
.file-selector-popup__search .sk-input__field-icon {
  left: 10px;
}

.file-selector-popup__search .sk-input__input--with-icon {
  padding-right: 10px;
  padding-left: 40px;
  border-radius: 4px !important;
}
</style>
<style scoped>
.file-selector-popup {
  box-sizing: border-box;
  width: 700px;
  border: 1px solid #fff;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 10%);
}

.file-selector-popup__header {
  padding: 24px 24px 0 32px;
}

.file-selector-popup__tabs {
  display: flex;
  margin-bottom: 24px;
  padding: 24px 24px 0 32px;
  border-bottom: 1px solid #d3d5de;
}

.file-selector-popup__search-wrapper {
  padding: 0 32px;
}

.file-selector-popup__files-wrapper {
  overflow: scroll;
  min-height: 250px;
  max-height: 320px;
}

.file-selector-popup__selector {
  padding: 0 24px 24px 32px;
}

.file-selector-popup__btns-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding: 24px 32px 32px 32px;
  border-top: 1px solid var(--cool-gray-200);
}

.file-selector-popup__btn {
  width: auto;
  margin: 0 5px 0;
  padding: 0 20px;
}
</style>
