(<template>
  <div class="interpreters">
    <div class="interpreters__filters-header">
      <my-lang :lang-from="langFrom"
               :lang-to="langTo"
               @langchosen="catchLangFrom" />
      <basic-filters :lang-from="langFrom"
                     :lang-to="langTo"
                     @langchosen="catchLangTo"
                     @openfilters="openFullFilters"
                     @submitfilters="submitFilters(true)"
                     @clearfilters="clearFilters" />
    </div>
    <section class="interpreters__tiles-cont">
      <int-tile v-for="inter in interList"
                :key="inter.uid"
                :person="inter" />
    </section>
    <section v-if="pageTotal"
             class="interpreters__pagination-cont">
      <items-amount class="interpreters__items-per-page"
                    @amountchanged="refetchData" />
      <sk-pagination v-if="pageTotal && pageTotal > 1"
                     :current="+currentPage"
                     :total="+pageTotal"
                     @prev="toPrevPage"
                     @next="toNextPage"
                     @newpage="toNewPage" />
    </section>
    <p v-else
       v-html="emptyText"></p>
  </div>
</template>)

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import MyLang from '@/components/interpreters/MyLang';
  import BasicFilters from '@/components/interpreters/BasicFilters';
  import InterpreterTile from '@/components/interpreters/InterpreterTile';
  import ItemsAmount from '@/components/shared_components/ItemsAmount';

  export default {
    asyncData({store, route}) {
      let promisesArr = '';

      if (route.query.page && route.query.availability_date && route.query.langFrom) {
        if (!store.state.InterpretersStore.interpreters.interpreters) {
          promisesArr = [...promisesArr, store.dispatch('InterpretersStore/fetchInterpreters', route.query)];
        }
      }
      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'items-amount': ItemsAmount,
      'my-lang': MyLang,
      'basic-filters': BasicFilters,
      'int-tile': InterpreterTile,
    },
    data() {
      return {
        langFrom: this.$route.query.langFrom || '3',
        langTo: this.$route.query.langTo || '',
        nameSearch: this.$route.query.nameSearch || '',
        qualifications: this.$route.query.qualification
          ? this.$route.query.qualification.map
            ? this.$route.query.qualification.map((item) => +item)
            : [+this.$route.query.qualification]
          : [],
        gender: this.$route.query.gender || '2',
        favor: this.$route.query.relation || false,
        currentPage: this.$route.query.page || 1,
        availabilityDate: this.$route.query.availability_date || this.$moment().format('YYYY-MM-DD')
      };
    },
    computed: {
      ...mapGetters('InterpretersStore', {
        interList: 'allInterpreters',
        pageTotal: 'totalPages'
      }),
      ...mapState('InterpretersStore', {
        interpreters: ({interpreters}) => interpreters.interpreters || ''
      }),
      emptyText() { return this.$gettext('There are currently no interpreters matching these criteria.'); },
    },
    methods: {
      ...mapMutations('InterpretersStore', ['removeInterpreters']),
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      refetchData() {
        this.removeInterpreters();
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      },
      catchLangFrom(value) {
        this.langFrom = value;
        this.submitFilters();
      },
      catchLangTo(value) {
        this.langTo = value;
        this.submitFilters();
      },
      openFullFilters() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'advanced-filters-modal',
          classes: ['advanced-filters__modal'],
          data: {
            nameSearch: this.nameSearch,
            qualifications: this.qualifications,
            gender: this.gender,
            favor: this.favor,
            catchNameSearch: this.catchNameSearch,
            catchQualifications: this.catchQualifications,
            catchGender: this.catchGender,
            catchFavor: this.catchFavor,
            submitFilters: this.submitFilters,
            clearFilters: this.clearFilters,
          }
        });
      },
      catchNameSearch(value) {
        this.nameSearch = value.trim();
      },
      catchQualifications(value) {
        this.qualifications = value;
      },
      catchGender(value) {
        this.gender = value;
      },
      catchFavor(value) {
        this.favor = value;
      },
      toPrevPage() {
        this.currentPage -= 1;
        this.submitFilters();
      },
      toNewPage(page) {
        this.currentPage = +page;
        this.submitFilters();
      },
      toNextPage() {
        this.currentPage += 1;
        this.submitFilters();
      },
      clearFilters() {
        this.langTo = '';
        this.nameSearch = '';
        this.qualifications = [];
        this.gender = '2';
        this.favor = false;
        this.currentPage = 1;
        this.closeModal();
        this.submitFilters();
      },
      submitFilters(clearPage) {
        const params = {};
        if (this.langFrom) {
          params.langFrom = this.langFrom;
        }
        if (this.langTo && this.langTo != 'all') {
          params.langTo = this.langTo;
        }
        if (this.nameSearch) {
          params.nameSearch = this.nameSearch;
        }
        if (this.qualifications && this.qualifications.length) {
          params.qualification = this.qualifications;
        }
        if ((this.gender || this.gender === 0) && this.gender != 2) {
          params.gender = this.gender;
        }
        if (this.favor) {
          params.relation = this.favor;
        }
        if (this.availabilityDate) {
          params.availability_date = this.availabilityDate;
        }
        if (clearPage) this.currentPage = 1;
        params.page = this.currentPage;
        this.closeModal();
        this.removeInterpreters();
        this.$router.push(this.$makeRoute({name: 'BuyerInterpreters', query: params}));
      }
    },
    beforeMount() {
      if (!this.$route.query.page || !this.$route.query.availability_date || !this.$route.query.langFrom) {
        this.$router.replace(this.$makeRoute({
          name: 'BuyerInterpreters',
          query: {
            page: this.currentPage,
            availability_date: this.availabilityDate,
            langFrom: this.langFrom
          }
        }));
      }
    },
    beforeDestroy() {
      this.removeInterpreters();
    }
  };
</script>

<style>
  @media (max-width: 767px) {
    .advanced-filters__wrapper.advanced-filters__wrapper {
      top: 45px;
      bottom: 50px;
      width: 100%;
    }
  }
</style>

<style scoped>
  .interpreters {
    display: block;
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
    padding: 0 15px 100px;
  }

  .interpreters__filters-header {
    position: sticky;
    top: 60px;
    left: 50%;
    z-index: 1;
    width: 100%;
    max-width: 660px;
    background-color: #f4f5f7;
  }

  .interpreters__pagination-cont {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }

  .interpreters__items-per-page {
    margin-right: auto;
  }

  @media (max-width: 767px) {
    .interpreters {
      padding: 0 0 40px;
    }

    .interpreters__filters-header {
      top: 45px;
      left: 0;
      max-width: initial;
      margin-left: 0;
      background-color: #fff;
    }

    .b-footer {
      display: none;
    }

    .advanced-filters {
      top: 0;
      bottom: 0;
    }

    .interpreters__pagination-cont {
      flex-direction: column;
    }

    .interpreters__items-per-page {
      margin: 0 auto 10px;
    }
  }
</style>
