(<template>
  <div :class="{'create-ent--full-width': fullForm}"
       class="create-ent">
    <div class="create-ent__avatar-label-wrapper">
      <label class="create-ent__avatar-label">
        <company-avatar :rotation="logoRotation"
                        :avatar="logoLink"
                        class="create-ent__avatar" />
        <span class="create-ent__avatar-label-text">{{ $gettext('Upload your company logo') }}</span>
        <input slot="fileInput"
               class="is-hidden"
               type="file"
               @change="catchAvatar">
      </label>
      <div v-if="logoErrors && logoErrors.length"
           class="create-ent__avatar-errors">
        <p v-for="(error, index) in logoErrors"
           :key="index">{{ error }}</p>
      </div>
      <button v-if="logoLink"
              class="create-ent__rotate-btn"
              @click="rotatePicture"></button>
    </div>
    <div class="create-ent__inputs-cont">
      <sk-select :type="'countries'"
                 :description="countryText"
                 :default-value="countryText"
                 :preselected-value="country"
                 :validation-name="countryErrorsName"
                 class="create-ent__input create-ent__country"
                 @csvaluechanged="setAddressCountry" />
      <org-number-field :preselected-value="orgNumber"
                        :autofocus="true"
                        :description="orgNumberText"
                        :placeholder="orgNumberText"
                        :validation-name="orgNumberErrorsName"
                        :country="country"
                        class="create-ent__input create-ent__org-number"
                        @fieldvaluechanged="setOrgNumber" />
      <sk-input v-if="isNotNorwayCompany"
                :preselected-value="orgName"
                :description="orgNameText"
                :placeholder="orgNameText"
                :validation-name="orgNameErrorsName"
                :reg-exp="/<\/?[^>]+(>|$)/g"
                class="create-ent__input create-ent__org-name"
                @fieldvaluechanged="setOrgName" />
    </div>
    <div v-if="isNotNorwayCompany"
         class="create-ent__org-note"
         v-html="notNorwayCompanyNote"></div>
    <terms-check :preselected-value="terms"
                 :validation-name="termsErrorsName"
                 class="create-ent__terms-checkbox"
                 @checkboxvaluechanged="setTerms" />
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import OrgNumberField from '@/components/enterprise/form_parts/OrgNumberField';

  export default {
    components: {
      'org-number-field': OrgNumberField
    },
    props: {
      fullForm: Boolean
    },
    computed: {
      ...mapState('TTWizardStore', {
        logoLink: (state) => state.stepsData.enterprise.logoLink,
        logoFile: (state) => state.stepsData.enterprise.logoFile,
        logoRotation: (state) => state.stepsData.enterprise.logoRotation,
        logoErrors: (state) => state.errors.avatar.errors,
        country: (state) => state.stepsData.address.country,
        countryErrorsName: (state) => state.errors.country.name,
        orgNumber: (state) => state.stepsData.enterprise.orgNumber,
        orgNumberErrorsName: (state) => state.errors.orgNumber.name,
        orgName: (state) => state.stepsData.enterprise.orgName,
        orgNameErrorsName: (state) => state.errors.orgName.name,
        terms: (state) => state.stepsData.enterprise.terms,
        termsErrorsName: (state) => state.errors.terms.name
      }),
      countryText() { return this.$gettext('Country'); },
      orgNumberText() { return this.$gettext('Organisation number'); },
      orgNameText() { return this.$gettext('Company name'); },
      isNorway() { return this.country === 'Norway'; },
      isNotNorwayCompany() { return this.country && !this.isNorway; },
      notNorwayCompanyNote() {
        const template = this.$gettext('Please contact us by email at <a class="sk-link sk-link--default" href="mailto:%{support}">%{support}</a> so we can set up your account with an international payment method.');
        return this.$gettextInterpolate(template, {support: this.$getString('domainData', 'supportEmail')});
      }
    },
    methods: {
      ...mapMutations('TTWizardStore', [
        'setError',
        'removeOneError',
        'setTerms',
        'setAddressCountry',
        'setOrgNumber',
        'setOrgName',
        'setLogoFile',
        'setLogoLink',
        'setLogoRotation'
      ]),
      catchAvatar(eve) {
        let converterResponse = '';
        this.removeOneError('avatar');
        this.setLogoRotation(0);

        if (eve.target && eve.target.files[0]) {
          converterResponse = this.$avatarConverter.convertAvatar(this, eve.target.files[0]);
          if (!(converterResponse.then) && Array.isArray(converterResponse)) {
            for (const error of converterResponse) {
              this.setError({
                fieldName: 'avatar',
                errorText: error
              });
            }
          } else {
            converterResponse.then((response) => {
              this.setLogoLink(response.target.result);
              this.setLogoFile(eve.target.files[0]);
            });
          }
        }
      },
      rotatePicture() {
        this.setLogoRotation((this.logoRotation + 90) % 360);
      }
    }
  };
</script>

<style>
  .create-ent__country .sk-select__list {
    max-height: 165px;
  }
</style>

<style scoped>
  .create-ent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .create-ent--full-width .create-ent__inputs-cont {
    margin-bottom: 20px;
    padding: 0 117px;
  }

  .create-ent--full-width .create-ent__org-note {
    padding: 0 117px;
  }

  .create-ent--full-width .create-ent__input {
    width: calc(50% - 10px);
  }

  .create-ent__avatar-label-wrapper {
    position: relative;
    display: block;
    width: 200px;
    margin: 0 auto 20px;
  }

  .create-ent__avatar-label {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  .create-ent__avatar {
    width: 60px;
    height: 60px;
  }

  .create-ent__avatar-label-text {
    display: block;
    width: 100%;
    color: #ff5b24;
    color: var(--color-secondary);
    text-align: center;
  }

  .create-ent__rotate-btn {
    position: absolute;
    top: 35px;
    right: 35px;
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-image: var(--image-rotate-button);
    background-position: 50% 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .create-ent__avatar-errors {
    display: block;
    width: 100%;
    margin-top: 10px;
    padding: 0 25px;
    color: #f04;
    color: var(--color-error);
    font-size: 12px;
    text-align: left;
  }

  .create-ent__inputs-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .create-ent__input {
    width: 100%;
    margin-bottom: 15px;
  }

  .create-ent__country {
    text-align: left;
  }

  .create-ent__terms-checkbox {
    width: auto;
    margin-top: 30px;
  }

  @media (max-width: 650px) {
    .create-ent__input {
      width: 100%;
    }

    .create-ent--full-width .create-ent__inputs-cont,
    .create-ent--full-width .create-ent__org-note {
      padding: 0 15px;
    }
  }
</style>
